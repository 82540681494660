<template>
  <div class="py-6" v-if="offers">
    <Superselect
      title="Offers"
      v-model="offers"
      track-by="id"
      label="label"
      class="mb-2"
      :options="[]"
      :hideSelected="true"
      :query="OFFERS_QUERY"
      :query-variables="{
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: { matchType: 'contains' }
          },
          traffic: {
            campaignId: this.campaignId
          }
        }
      }"
      :query-default-input-text="''"
    >
      <template slot="option" slot-scope="props">
        <div class="flex items-center">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="12"
            :height="12"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div
              class="text-xxs"
              v-for="advertiser in props.option.advertisers"
              :key="advertiser.advertiserId"
            >
              {{ advertiser.primaryContact.name }}
            </div>
          </div>
        </div>
      </template>
    </Superselect>
    <YAlert :allowClose="false" v-if="hasChanges" color="blue" class="mb-2">
      <div class="flex items-center justify-between">
        <div>
          You have unsaved changes
        </div>
        <div>
          <YButton
            color="link"
            class="hover:bg-blue-300 mr-1"
            @click="reset"
            :disabled="isSaving"
            >Reset</YButton
          >
          <YButton color="blue" @click="save" :is-loading="isSaving"
            >Save</YButton
          >
        </div>
      </div>
    </YAlert>
    <Draggable v-bind="dragOptions" draggable=".x-offer" v-model="offers">
      <div v-for="offer in offers" :key="offer.id" class="x-offer group">
        <div
          class="flex justify-between items-center  hover:bg-blue-100 rounded"
        >
          <div class="flex items-center p-2 ">
            <YImage
              v-tooltip="`Assign to Campaign`"
              :src="offer.imageThumbnailUrl"
              :width="16"
              :height="16"
              class="rounded mr-4"
              :class="[
                offer.imageThumbnailUrl ? 'hover:shadow-md cursor-pointer' : ''
              ]"
              @click="openAddMediaFromUrlModal(offer.imageUrl)"
            />
            <div>
              <div class="mb-1">
                <div>
                  {{ offer.label }}
                  <Tag v-if="offer.isCapped" class="ml-1" color="darkRed"
                    >CAPPED</Tag
                  >
                </div>
                <div
                  class="text-xxs text-gray-600"
                  v-for="advertiser in offer.advertisers"
                  :key="advertiser.advertiserId"
                >
                  {{ advertiser.primaryContact.name }}
                </div>
              </div>
              <div>
                <div class="text-xxs text-gray-600">{{ offer.encodedId }}</div>
              </div>
            </div>
          </div>
          <div class="flex-shrink flex items-center">
            <button
              class="x-offer-remove text-blue-600 hover:text-blue-700 hover:bg-blue-200 p-2 mr-2 rounded  group-hover:opacity-100 opacity-0  outline-none-important block"
              @click="removeOffer(offer.id)"
            >
              <Icon name="close" class="block" />
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  </div>
</template>

<script>
import CAMPAIGN_QUERY from '@/graphql/Campaign/CampaignQuery.gql'
import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
import SYNC_OFFERS_TO_CAMPAIGN_MUTATION from '@/graphql/Campaign/SyncOffersToCampaignMutation.gql'
import AddImageFromUrlWidget from '@/views/Admin/Media/AddImageFromUrlWidget'

import Draggable from 'vuedraggable'
export default {
  components: {
    Draggable
  },
  apollo: {
    campaign: {
      query: CAMPAIGN_QUERY,
      variables() {
        return {
          id: this.campaignId
        }
      },
      result({ data }) {
        this.savedOffers = data.campaign.offers
        if (!this.offers) {
          this.offers = data.campaign.offers
        }
      }
    }
  },
  props: {
    campaignId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      OFFERS_QUERY,
      offers: null,
      savedOffers: null,
      campaign: null,
      isSaving: false
    }
  },
  computed: {
    hasChanges() {
      if (this.offers && this.savedOffers) {
        return (
          JSON.stringify(this.offers.map(offer => offer.id)) !==
          JSON.stringify(this.savedOffers.map(offer => offer.id))
        )
      } else {
        return false
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'opacity-25'
      }
    }
  },
  methods: {
    reset() {
      this.offers = this.savedOffers
    },
    removeOffer(id) {
      this.offers = this.offers.filter(offer => offer.id != id)
    },
    save() {
      this.isSaving = true
      this.$apollo
        .mutate({
          mutation: SYNC_OFFERS_TO_CAMPAIGN_MUTATION,
          variables: {
            input: {
              campaignId: this.campaignId,
              sync: this.offers.map((offer, index) => ({
                id: offer.id,
                order: index
              }))
            }
          }
        })
        .then(({ data }) => {
          this.savedOffers = data.syncOffersToCampaign.offers
          this.offers = data.syncOffersToCampaign.offers
          this.isSaving = false
          this.$toastr.s('', 'Campaign Offers Updated!')
        })
        .catch(error => {
          this.isSaving = false
          console.error(error)
        })
    },
    openAddMediaFromUrlModal(url) {
      this.$modal.show(
        AddImageFromUrlWidget,
        {
          modelClass: 'Campaign',
          modelId: this.campaignId,
          url: url,
          deleteExisting: true
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true,
          classes: ['overflow-visible-important']
        }
      )
    }
  }
}
</script>

<style scoped>
.x-offer-remove > .icon {
  transform: scale(0.1);
}
.group:hover .x-offer-remove {
  transition: opacity 1s ease, transform 0.5s ease, background 0.25s ease;
  transform: scale(1);
}

.group:hover .x-offer-remove > .icon {
  transition: transform 0.5s ease;
  transform: scale(1);
}
</style>
