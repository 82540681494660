var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-sans antialiased flex",
    staticStyle: {
      "height": "100dvh"
    }
  }, [_c('AuthManagement'), !_vm.sidebarIsCollapsed ? _c('div', {
    staticClass: "x-overlay fixed block md:hidden w-full h-full cursor-pointer top-0 bottom-0 right-0 left-0",
    on: {
      "click": function ($event) {
        return _vm.toggleSidebar();
      }
    }
  }) : _vm._e(), _c('SideBar', {
    attrs: {
      "isCollapsed": _vm.sidebarIsCollapsed
    },
    on: {
      "toggleSidebar": function ($event) {
        return _vm.toggleSidebar();
      }
    }
  }), _c('div', {
    staticClass: "flex-1 flex flex-col bg-white overflow-hidden"
  }, [_c('TopBar', {
    attrs: {
      "hasBurger": _vm.sidebarIsCollapsed
    },
    on: {
      "toggleSidebar": function ($event) {
        return _vm.toggleSidebar();
      }
    }
  }), _vm.$route.meta.scope == 'admin' ? _c('portal-target', {
    staticStyle: {
      "position": "relative",
      "z-index": "2"
    },
    attrs: {
      "name": "admin-alerts"
    }
  }) : _vm._e(), _vm.$route.meta.scope == 'publisher' ? _c('portal-target', {
    staticStyle: {
      "position": "relative",
      "z-index": "2"
    },
    attrs: {
      "name": "publisher-alerts"
    }
  }) : _vm._e(), _c('portal-target', {
    staticStyle: {
      "position": "relative",
      "z-index": "2"
    },
    attrs: {
      "name": "alerts"
    }
  }), _c('portal-target', {
    attrs: {
      "name": "top-bar-secondary"
    }
  }, [_c('TermsAlert'), _c('PaymentAlert')], 1), _c('UserNotifications'), _c('SubscriptionManager'), _c('div', {
    staticClass: "md:flex flex-row flex-1 overflow-y-scroll md:overflow-hidden"
  }, [_c('div', {
    staticClass: "flex flex-col md:flex-1 px-6 py-4 md:overflow-y-auto md:h-full overflow-x-hidden",
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('div', {
    staticClass: "-mx-6 -mt-4 mb-4"
  }), _c('portal-target', {
    attrs: {
      "name": "main-content-start"
    }
  }), _vm._t("default", function () {
    return [_c('router-view')];
  }), _c('portal-target', {
    attrs: {
      "name": "main-content-end"
    }
  })], 2), _c('SideBarRight')], 1), _c('portal-target', {
    attrs: {
      "name": "sticky-footer"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }