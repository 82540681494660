var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.singleOfferView ? _c('div', {
    staticClass: "sticky flex justify-between items-center bg-gray-200 px-4 py-4 shadow-md my-2",
    staticStyle: {
      "top": "-20px",
      "z-index": "10"
    }
  }, [_c('div', {
    staticClass: "text-muted"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected "), _vm.rowsAreSelected ? _c('small', {
    staticClass: "pl-3"
  }, [_c('a', {
    staticClass: "link",
    staticStyle: {
      "border-radius": "2rem",
      "padding": "0.1rem 0.6rem"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearAllSelected.apply(null, arguments);
      }
    }
  }, [_vm._v("CLEAR")])]) : _vm._e()])]), _c('div', {
    staticClass: "flex space-x-4"
  }, [_c('YButton', {
    attrs: {
      "color": "red",
      "disabled": !_vm.rowsAreSelected
    },
    on: {
      "click": _vm.deleteOffers
    }
  }, [_vm._v("Bulk Delete")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !_vm.rowsAreSelected || !_vm.selectedHaveSingleAdvertiser || _vm.selectedHasTemplate
    },
    on: {
      "click": function ($event) {
        return _vm.loadBulkPayouts(_vm.selectedOffers);
      }
    }
  }, [_vm._v(" Payouts ")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !_vm.rowsAreSelected
    },
    on: {
      "click": function ($event) {
        _vm.bulkDropdownOpen = !_vm.bulkDropdownOpen;
      }
    }
  }, [_vm._v(" Bulk Traffic ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.bulkDropdownOpen,
      expression: "bulkDropdownOpen"
    }],
    staticClass: "fixed inset-0 h-full w-full z-10",
    on: {
      "click": function ($event) {
        _vm.bulkDropdownOpen = false;
      }
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.bulkDropdownOpen,
      expression: "bulkDropdownOpen"
    }],
    staticClass: "absolute py-2 bg-white rounded-md shadow-xl z-20 w-32 text-center font-bold",
    staticStyle: {
      "top": "65px",
      "right": "99px",
      "margin": "0"
    }
  }, [_c('span', {
    staticClass: "block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200",
    on: {
      "click": _vm.pauseOffers
    }
  }, [_vm._v(" Pause ")]), _c('span', {
    staticClass: "block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200",
    on: {
      "click": _vm.deactivateOffers
    }
  }, [_vm._v(" De-activate ")]), _c('span', {
    staticClass: "block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200",
    on: {
      "click": _vm.trafficOffers
    }
  }, [_vm._v(" Traffic ")])]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Offers Edit',
        params: {
          selectedOffers: _vm.selectedOffers
        }
      }
    }
  }, [_c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !_vm.rowsAreSelected
    }
  }, [_vm._v(" Edit ")])], 1)], 1)]) : _vm._e(), _c('YTable', {
    attrs: {
      "data": _vm.offers,
      "bottom-loading": _vm.bottomLoading,
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "flex items-center"
        }, [_c('span', [_c('OfferTrafficStatus', {
          staticClass: "mr-2 -ml-2",
          attrs: {
            "offer": props.rowData,
            "hide-label": true
          }
        })], 1), _c('EditableImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "imageUrl": props.rowData.imageUrl,
            "thumbnailUrl": props.rowData.imageThumbnailUrl,
            "height": 12,
            "width": 12,
            "blankHeight": 12,
            "blankWidth": 12,
            "editable-only-when-empty": true,
            "modelClass": "Offer",
            "modelId": props.rowData.id,
            "deleteExisting": true
          }
        }), _c('div', {
          staticClass: "flex justify-between items-center flex-1",
          staticStyle: {
            "max-width": "35rem"
          }
        }, [_c('div', {
          staticClass: "mx-4"
        }, [_c('router-link', {
          staticClass: "font-bold break-words without-truncate inline-block",
          class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700' : 'link',
          attrs: {
            "to": {
              name: 'Offer',
              params: {
                id: props.rowData.id,
                action: 'edit'
              }
            }
          }
        }, [props.rowData.id ? [_vm._v(_vm._s(props.rowData.id) + " - ")] : _vm._e(), _vm._v(" " + _vm._s(props.rowData.name) + " ")], 2), _c('sup', {
          staticClass: "text-gray-500 font-semibold"
        }, [_vm._v(" " + _vm._s(props.rowData.encodedId) + " ")])], 1), _c('div', {
          staticClass: "flex items-center"
        }, [_c('div', [_c('button', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.keyedOfferLinkedToCampaign(props.rowData) ? 'Keyed Offer linked to Campaign' : _vm.offerLinkedToCampaign(props.rowData) ? 'Offer linked to Campaign' : '',
            expression: "\n                    keyedOfferLinkedToCampaign(props.rowData)\n                      ? 'Keyed Offer linked to Campaign'\n                      : offerLinkedToCampaign(props.rowData)\n                      ? 'Offer linked to Campaign'\n                      : ''\n                  "
          }],
          class: ['py-3 px-1 outline-none-important', _vm.offerLinkedToCampaign(props.rowData) ? 'text-blue-500 hover:text-blue-600' : 'text-gray-300 cursor-default'],
          on: {
            "click": function ($event) {
              return _vm.openOfferCampaignsModal(props.rowData);
            }
          }
        }, [_vm.keyedOfferLinkedToCampaign(props.rowData) ? _c('Icon', {
          attrs: {
            "name": "linkPlus",
            "size": "5"
          }
        }) : _c('Icon', {
          attrs: {
            "name": "link",
            "size": "5"
          }
        })], 1)]), _c('v-popover', {
          class: ['sidebar-user-popover'],
          attrs: {
            "offset": "2",
            "placement": "right",
            "autoHide": true,
            "popoverClass": "",
            "popoverBaseClass": "popover-menu sidebar-popover-menu",
            "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
          }
        }, [_c('YButton', {
          staticClass: "btn btn-transparent btn-rounded tooltip-target"
        }, [_c('Icon', {
          attrs: {
            "name": "dotsVertical",
            "size": 4
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "list-group flex flex-col"
        }, [[_c('YButton', {
          attrs: {
            "color": "link "
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'Offer',
              params: {
                action: 'edit'
              }
            }
          }
        }, [_vm._v("Edit")])], 1)], [_c('YButton', {
          attrs: {
            "color": "link "
          },
          on: {
            "click": function ($event) {
              return _vm.openOfferCloneModal(props.rowData.id, props.rowData);
            }
          }
        }, [_vm._v(" Clone")])], [_c('ClickAndReplace', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.offerLinkedToCampaign(props.rowData) ? 'Remove Offer from Campaign to delete' : '',
            expression: "\n                          offerLinkedToCampaign(props.rowData)\n                            ? 'Remove Offer from Campaign to delete'\n                            : ''\n                        "
          }],
          attrs: {
            "disabled": !!props.rowData.campaigns && props.rowData.campaigns.length > 0,
            "duration": 5000
          },
          scopedSlots: _vm._u([{
            key: "replacement",
            fn: function () {
              return [_c('YButton', {
                staticClass: "bg-red-500 text-white hover:bg-red-600",
                attrs: {
                  "is-loading": _vm.isDeleting
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteOffer(props.rowData.id);
                  }
                }
              }, [_vm._v("Confirm Delete")])];
            },
            proxy: true
          }], null, true)
        }, [_c('YButton', {
          staticClass: "text-red-500 hover:bg-red-100 w-full",
          attrs: {
            "disabled": !!_vm.offerLinkedToCampaign(props.rowData)
          }
        }, [_vm._v("Delete")])], 1)]], 2)])], 2)], 1)])], 1)]), _c('td', [props.rowData.destinationType == 'template' && props.rowData.destination ? _c('router-link', {
          staticClass: "truncate",
          class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700' : 'text-blue-600 hover:text-blue-800',
          attrs: {
            "to": {
              name: 'Template',
              params: {
                path: props.rowData.destination
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.destination))]) : props.rowData.destinationType == 'template' ? [_c('span', {
          staticClass: "italic text-gray-500"
        }, [_vm._v("(Template)")])] : _c('span', {
          staticClass: "italic text-gray-500"
        }, [_vm._v("(Direct)")])], 2), _c('td', [props.rowData.offers && props.rowData.offers.length > 0 ? _c('ul', _vm._l(props.rowData.offers, function (offer) {
          return _c('li', {
            key: offer.id
          }, [_c('router-link', {
            staticClass: "block",
            class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700' : 'link-wrap',
            attrs: {
              "to": {
                name: 'Offer',
                params: {
                  id: offer.id,
                  action: 'edit'
                }
              }
            }
          }, [_vm._v(_vm._s(offer.label))])], 1);
        }), 0) : _vm._e()]), _c('td', [props.rowData.advertisers && props.rowData.advertisers.length > 0 ? _c('ul', _vm._l(props.rowData.advertisers, function (advertiser) {
          return _c('li', {
            key: advertiser.id
          }, [_c('router-link', {
            staticClass: "truncate",
            class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700' : 'link-wrap',
            attrs: {
              "to": {
                name: 'User',
                params: {
                  id: advertiser.id
                }
              }
            }
          }, [_vm._v(_vm._s(advertiser.name))])], 1);
        }), 0) : _vm._e()]), _vm.singleOfferView ? _c('td', {
          staticClass: "text-gray-600 text-xs",
          staticStyle: {
            "max-width": "60px"
          }
        }, [_vm._v(" " + _vm._s(props.rowData.revenues && props.rowData.revenues.length > 0 ? _vm.topLevelPayouts(props.rowData.revenues) : '') + " ")]) : _vm._e(), _vm._l(_vm.tagTypes, function (type) {
          return _c('td', {
            key: type,
            staticStyle: {
              "max-width": "140px"
            },
            style: _vm.offers.length == 1 ? 'display: none' : ''
          }, [_c('div', [_vm._l(_vm.truncateOfferTagsByType(_vm.offerTagsByType[`${props.rowData.id}-${type}`]), function (tag) {
            return _c('Tag', {
              key: tag.name,
              staticStyle: {
                "margin": "1px"
              },
              attrs: {
                "color": tag.isNot ? 'red' : tag.color
              }
            }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
          }), _vm.truncateTags(_vm.offerTagsByType[`${props.rowData.id}-${type}`]) ? _c('span', [_c('router-link', {
            staticClass: "block font-semibold text-blue-600 mt-1 ml-2 text-xs hover:text-blue-800",
            attrs: {
              "to": {
                name: 'Offer',
                params: {
                  id: props.rowData.id,
                  action: 'edit'
                }
              }
            }
          }, [_vm._v("More... ")])], 1) : _vm._e()], 2)]);
        })];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_c('OrderByItem', {
    attrs: {
      "column": "id",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Offer")])], 1), _c('th', [_vm._v("Destination")]), _c('th', [_vm._v("Offer Links")]), _c('th', [_vm._v("Advertiser")]), _vm.singleOfferView ? _c('th', [_vm._v("Payouts")]) : _vm._e(), _vm._l(_vm.tagTypes, function (type) {
    return _c('th', {
      key: type,
      style: _vm.offers.length == 1 ? 'display: none' : ''
    }, [_vm._v(" " + _vm._s(_vm.tagTypeNameMap[type]) + " ")]);
  })], 2)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }