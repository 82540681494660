<template>
  <div id="top">
    <div class="bg-gray-200 p-1 border-b mb-2">
      <template>
        <div class="w-full">
          <div class="grid grid-cols-1 md:grid-cols-6  gap-1">
            <Superselect
              title="Level"
              v-model="filters.level.value"
              track-by="id"
              label="label"
              :multiple="false"
              placeholder="Select"
              :query="queries.INTROSPECTION_TYPE_QUERY"
              :query-variables="{ name: 'YleaderHistoryLevelEnum' }"
              :query-result-function="
                data => {
                  return data.__type.enumValues.map(value => ({
                    id: value.name,
                    label: value.name
                  }))
                }
              "
            ></Superselect>
            <Superselect
              title="Country"
              v-model="filters.country.value"
              :multiple="false"
              label="label"
              track-by="code"
              :options="countries"
              :loading="$apollo.queries.countries.loading"
            ></Superselect>
            <Superselect
              title="Offer"
              v-model="filters.offer.value"
              :options="options.offer"
              placeholder="Select Offer"
              :options-limit="100"
              track-by="id"
              label="label"
              :query="queries.OFFER_OPTIONS_QUERY"
              :query-variables="{
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                },
                first: 100
              }"
            ></Superselect>
            <Superselect
              title="Redirect"
              v-model="filters.redirect.value"
              :options="options.redirect"
              placeholder="Select Redirect"
              track-by="id"
              label="label"
              :query="queries.REDIRECT_OPTIONS_QUERY"
              :query-variables="{
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                },
                first: 25
              }"
              :queryResultMap="{
                id: 'id',
                label: 'label'
              }"
            ></Superselect>
            <YInput v-model="filters.source.value" label="Source"></YInput>
            <Superselect
              title="Advertiser"
              v-model="filters.advertiser.value"
              :modifiers="filters.advertiser.modifiers"
              :options="options.advertiser"
              placeholder="Select"
              track-by="id"
              label="label"
              :multiple="false"
              :close-on-select="false"
              :query="queries.ADVERTISER_OPTIONS_QUERY"
              :query-variables="{
                first: 20,
                filters: {
                  advertiserLabel: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                }
              }"
            ></Superselect>
            <Superselect
              title="Campaign"
              v-model="filters.campaign.value"
              track-by="id"
              label="label"
              :modifiers="filters.campaign.modifiers"
              :multiple="false"
              :options="options.campaign"
              :query="queries.CAMPAIGN_OPTIONS_QUERY"
              :query-variables="{
                first: 20,
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                }
              }"
            ></Superselect>
            <Superselect
              title="Publisher"
              v-model="filters.user.value"
              :modifiers="filters.user.modifiers"
              :options="options.user"
              placeholder="Select"
              track-by="id"
              label="label"
              :multiple="false"
              :close-on-select="false"
              :query="queries.PUBLISHER_OPTIONS_QUERY"
              :query-variables="{
                first: 20,
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                }
              }"
              edit-placeholder="Paste Publishers"
            ></Superselect>
            <Superselect
              title="Smart link"
              placeholder="Select"
              v-model="filters.smartlink.value"
              :multiple="false"
              :close-on-select="false"
              :options="options.smartlink"
              track-by="id"
              label="label"
            ></Superselect>
            <y-radios
              v-model="filterDisplayMode"
              :radios="[
                { label: 'All', value: 'null' },
                { label: 'Alert', value: 1 }
              ]"
              class="flex"
              radio-class="m-3"
              color="info"
            ></y-radios>
            <YButton
              :disabled="selected.length > 0 ? false : true"
              color="blue"
              @click="clearYleaderAlerts(selected)"
            >
              <span class="text-center">Clear Alerts</span>
            </YButton>
          </div>
        </div>
      </template>
    </div>
    <div class="w-100">
      <YTable
        v-if="YleaderHistorys"
        :selectable="true"
        selectBy="id"
        :selected.sync="selected"
        :data="YleaderHistorys.data || []"
        :loading="$apolloData.queries.YleaderHistorys.loading"
      >
        <template slot="header-row">
          <th></th>
          <th>
            <OrderByItem column="offer_id" order="DESC" v-model="orderBy"
              >Offer</OrderByItem
            >
          </th>
          <th>
            <OrderByItem column="country_code" order="DESC" v-model="orderBy"
              >Geo</OrderByItem
            >
          </th>
          <th>
            <OrderByItem column="redirect_id" order="DESC" v-model="orderBy"
              >Redirect</OrderByItem
            >
          </th>
          <th>Advertiser</th>
          <th>Campaign</th>
          <th>Publisher</th>
          <th>
            <OrderByItem column="source_id" order="DESC" v-model="orderBy"
              >Source</OrderByItem
            >
          </th>
          <th>
            <OrderByItem column="yCPL" order="DESC" v-model="orderBy"
              >yCPL</OrderByItem
            >
          </th>
          <th>
            <OrderByItem column="yCPA" order="DESC" v-model="orderBy"
              >yCPA</OrderByItem
            >
          </th>
          <th>
            <OrderByItem column="rpa" order="DESC" v-model="orderBy"
              >rpa</OrderByItem
            >
          </th>
          <!--<th>Suggested<br />Payout</th>-->
          <!--<th>
            <OrderByItem column="variance" order="DESC" v-model="orderBy"
              >Variance</OrderByItem
            >
          </th>-->
          <th>
            <OrderByItem column="conversions" order="DESC" v-model="orderBy"
              >Lead Conversions</OrderByItem
            >
          </th>
          <th>
            <OrderByItem
              column="yleader_conversions"
              order="DESC"
              v-model="orderBy"
              >YConversions</OrderByItem
            >
          </th>
          <th>
            <OrderByItem column="revenue" order="DESC" v-model="orderBy"
              >Revenue</OrderByItem
            >
          </th>
          <!--<th>
            <OrderByItem column="yleader_revenue" order="DESC" v-model="orderBy"
              >YRevenue</OrderByItem
            >
          </th>-->
          <th>
            <OrderByItem column="updated_at" order="DESC" v-model="orderBy"
              >Last Updated</OrderByItem
            >
          </th>
        </template>
        <template slot="row" slot-scope="props">
          <td>
            <template v-if="props.rowData.notify == 1">
              <a href="#" class="text-red-600"><Icon name="alert"/></a>
            </template>
            <template v-if="props.rowData.acknowledged != null">
              <a href="#" class="text-green-600"><Icon name="flag"/></a>
            </template>
          </td>
          <td>
            <template v-if="props.rowData.offer">
              <div class="flex" style="width:18rem;">
                <router-link
                  :to="{
                    name: 'Offer',
                    params: { id: props.rowData.offer.id, action: 'edit' }
                  }"
                  :class="
                    props.rowData.isCapped
                      ? 'text-red-600 hover:text-red-700 font-medium'
                      : 'link-wrap'
                  "
                >
                  {{ props.rowData.offer.label }}
                </router-link>
              </div>
            </template>
          </td>
          <td>{{ props.rowData.countryCode }}</td>
          <td>
            <template v-if="props.rowData.redirect">
              <router-link
                :to="{
                  name: 'Redirect',
                  params: { id: props.rowData.redirect.id }
                }"
                class="link"
              >
                {{ props.rowData.redirect.label }}
              </router-link>
            </template>
          </td>
          <td>
            <template
              v-if="
                props.rowData.advertiserAccount &&
                  props.rowData.advertiserAccount.advertiser
              "
            >
              <router-link
                :to="{
                  name: 'Advertiser',
                  params: { id: props.rowData.advertiserAccount.advertiser.id }
                }"
                class="link"
              >
                {{ props.rowData.advertiserAccount.advertiser.name }}
              </router-link>
            </template>
          </td>
          <td>
            <div style="width:15rem;">
              <template
                v-if="props.rowData.redirect && props.rowData.redirect.campaign"
              >
                <router-link
                  :to="{
                    name: 'Campaign',
                    params: { id: props.rowData.redirect.campaign.id }
                  }"
                  class="link-wrap"
                >
                  {{ props.rowData.redirect.campaign.label }}
                </router-link>
              </template>
            </div>
          </td>
          <td>
            <template v-if="props.rowData.redirect">
              <router-link
                :to="{
                  name: 'User',
                  params: { id: props.rowData.redirect.user.id }
                }"
                class="link-wrap"
              >
                {{ props.rowData.redirect.user.label }}
              </router-link>
            </template>
          </td>
          <td>{{ props.rowData.sourceId }}</td>
          <td>${{ props.rowData.yCPL }}</td>
          <td class="text-green-600">${{ props.rowData.yCPA }}</td>
          <td>{{ props.rowData.rpa }}</td>
          <!--<td>${{ (parseFloat(props.rowData.yCPA) * 0.8).toFixed(2) }}</td>-->

          <!--<td>
            <span class="text-green-400" v-if="props.rowData.variance >= 0">
              ${{ props.rowData.variance }}
            </span>
            <span class="text-red-400" v-else>
              ${{ props.rowData.variance }}
            </span>
          </td>-->
          <td>{{ (parseInt(props.rowData.conversions) - parseInt(props.rowData.YleaderConversions)) }}</td>
          <td>{{ props.rowData.YleaderConversions }}</td>
          <td>${{ props.rowData.revenueValue }}</td>
          <!--<td>${{ props.rowData.YleaderRevenue }}</td>-->
          <td>{{ props.rowData.updatedAt }}</td>
        </template>
        <template v-slot:after>
          <YPagination
            v-if="YleaderHistorys && YleaderHistorys.data"
            :data="YleaderHistorys.paginatorInfo"
            @load-page="loadPage"
            @next-page="nextPage"
            @previous-page="previousPage"
            :key="totalPages"
            @per-page="perPageSelection"
          >
          </YPagination>
        </template>
      </YTable>
    </div>
  </div>
</template>

<script>
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import YLEADER_HISTORY_QUERY from '@/graphql/Yleader/YleaderHistoryQuery.gql'
import INTROSPECTION_TYPE_QUERY from '@/graphql/Introspection/IntrospectionTypeQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import ADVERTISER_OPTIONS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsQuery.gql'
import YLEADER_CLEAR_ALERTS_MUTATION from '@/graphql/Yleader/YleaderClearAlertsMutation.gql'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    YPagination
  },
  apollo: {
    YleaderHistorys: {
      query: YLEADER_HISTORY_QUERY,
      variables() {
        return {
          filters: {
            level: this.cleanObjectFilter(this.filters.level, 'id'),
            countryCode: this.cleanObjectFilter(this.filters.country, 'code'),
            redirect: this.cleanObjectFilter(this.filters.redirect, 'id'),
            offer: this.cleanObjectFilter(this.filters.offer, 'id'),
            user: this.cleanObjectFilter(this.filters.user, 'id'),
            campaign: this.cleanObjectFilter(this.filters.campaign, 'id'),
            advertiser: this.cleanObjectFilter(this.filters.advertiser, 'id'),
            source: this.filters.source.value
              ? {
                  value: this.filters.source.value,
                  modifiers: {
                    matchType: 'contains'
                  }
                }
              : undefined,
            notify:
              this.filterDisplayMode == 1 ? { value: [1] } : { value: [0, 1] },
            smartLink: this.cleanObjectFilter(this.filters.smartlink, 'id')
          },
          first: this.perPage,
          page: this.currentPage,
          orderBy: this.orderBy
        }
      },
      result({ data }, key) {
        this.totalPages = data[key].paginatorInfo.lastPage
        if (
          this.YleaderHistorys.data.length <= 0 &&
          this.filterDisplayMode != 0
        ) {
          this.filterDisplayMode = 0
          this.$events.emit('refreshYleaderHistory', true)
        }
      },
      fetchPolicy: 'network-only',
      deep: true
    },
    countries: {
      query: COUNTRIES_QUERY
    }
  },
  props: {},
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      filterDisplayMode: 1,
      selected: [],
      orderBy: [{ column: 'id', order: 'DESC' }],
      filters: {
        smartlink: {
          value: null,
          title: 'Smart Link',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        level: {
          value: null,
          title: 'Level',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        country: {
          value: null,
          title: 'Country',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'code'
        },
        redirect: {
          value: null,
          title: 'Redirect',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        source: {
          value: null,
          title: 'Source',
          type: 'input'
        },
        offer: {
          value: null,
          title: 'Offer',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        campaign: {
          title: 'Campaign',
          type: 'select',
          multiple: true,
          optionLabelKey: 'id',
          trackByKey: 'id',
          value: null
        },
        user: {
          title: 'User',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id',
          value: null
        },
        advertiser: {
          title: 'Advertiser',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id',
          value: null
        }
      },
      options: {
        offer: [],
        type: ['Top', 'Geo', 'Redirect', 'Source'],
        smartlink: [
          { id: 1, label: 'True' },
          { id: 0, label: 'False' }
        ],
        notify: ['True', 'False']
      },
      queries: {
        INTROSPECTION_TYPE_QUERY,
        OFFER_OPTIONS_QUERY,
        REDIRECT_OPTIONS_QUERY,
        PUBLISHER_OPTIONS_QUERY,
        CAMPAIGN_OPTIONS_QUERY,
        ADVERTISER_OPTIONS_QUERY
      }
    }
  },
  mounted() {
    this.$events.listen('refreshYleaderHistory', () => {
      this.$apollo.queries.YleaderHistorys.refetch()
    })
  },
  computed: {},
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    clearYleaderAlerts(selected) {
      this.$apollo
        .mutate({
          mutation: YLEADER_CLEAR_ALERTS_MUTATION,
          variables: {
            id: selected
          }
        })
        .then(() => {
          this.$toastr.s('Alert(s) cleared', 'Success!')
          this.$events.emit('refreshYleaderHistory', true)
          this.$events.emit('refreshSideBar', true)
          this.clearSelected()
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.$toastr.e('Alert(s) not cleared', 'Opps!')
        })
    },
    clearSelected() {
      this.selected = []
    },
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    cleanObjectFilterValue(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return this.cleanFilter(filter).value.map(value => value[pluck])[0]
    }
  }
}
</script>

<style>
.ydns-filter-radios {
  margin-bottom: -1rem;
}
</style>
