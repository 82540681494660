<template>
  <div v-if="me">
    <portal to="top-bar-left-header"> </portal>
    <div class="flex flex-row align-items-center mb-2">
      <div></div>
    </div>
    <div class="flex flex-row"></div>
    <div class="row">
      <div class="col">
        <Tabs show-type="show" :route="tabRoute">
          <Tab name="Account Details" :active="true" style="max-width:640px;">
            <PrimaryContactDetails :user="me"> </PrimaryContactDetails>
          </Tab>

          <Tab
            name="Redirect Params"
            v-if="termsAgreedTo"
            style="max-width:640px;"
          >
            <RedirectParameters :user="me"> </RedirectParameters>
          </Tab>
          <Tab name="Referrals" v-if="termsAgreedTo">
            <Referrals :me="me"> </Referrals>
          </Tab>
          <Tab name="Terms"><Terms> </Terms></Tab>
          <Tab name="Settings"
            ><PublisherSettings :me="me"> </PublisherSettings
          ></Tab>
        </Tabs>
      </div>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import PrimaryContactDetails from '@/views/Publisher/Account/PrimaryContactDetails.vue'
import RedirectParameters from '@/views/Publisher/Account/RedirectParameters'
import Terms from '@/views/Publisher/Account/Terms'
import PublisherSettings from '@/views/Publisher/Account/PublisherSettings.vue'
import Referrals from '@/views/Publisher/Account/Referrals'
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],

  components: {
    Terms,
    Referrals,
    PrimaryContactDetails,
    RedirectParameters,
    PublisherSettings
  },
  props: {
    tab: {
      type: String,
      default: 'account-details'
    }
  },
  apollo: {
    me: {
      query: ME_QUERY,
      result(data) {
        this.termsAgreedTo = data.data.me.userDetails.agreedToTerms
      }
    }
  },
  data() {
    return {
      activeTab: this.tab,
      isVisible: true,
      termsAgreedTo: false
    }
  },
  computed: {
    tabRoute() {
      return { name: 'Publisher Account', params: { tab: this.tab } }
    }
  },
  mounted() {},
  updated() {},
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    }
  }
}
</script>
