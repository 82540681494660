var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex flex-row gap-6 justify-between my-4"
  }, [_c('div', {
    staticClass: "w-7/12"
  }, [_c('Superselect', {
    attrs: {
      "select-class": "border-0",
      "options": _vm.showColumnsFilterOptions || [],
      "title": "Show Columns",
      "placeholder": "Select Columns",
      "track-by": "id",
      "label": "label",
      "selectedLabel": "Selected",
      "selectLabel": "Add",
      "deselectLabel": "Remove",
      "closeOnSelect": false,
      "multiple": true,
      "allow-empty": false
    },
    model: {
      value: _vm.showColumnsFilter,
      callback: function ($$v) {
        _vm.showColumnsFilter = $$v;
      },
      expression: "showColumnsFilter"
    }
  })], 1), _c('div', {
    staticClass: "w-5/12"
  }, [_c('domains-actions', {
    ref: "domainActions",
    attrs: {
      "selections": _vm.selectedDomainRecords
    }
  })], 1)]), _c('div', {
    staticClass: "w-100"
  }, [_c('YTable', {
    ref: "domainTable",
    attrs: {
      "data": _vm.data,
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selected
    },
    on: {
      "update:selected": function ($event) {
        _vm.selected = $event;
      },
      "select": _vm.newDomainsSelected,
      "deselect": _vm.domainsDeselected
    },
    scopedSlots: _vm._u([{
      key: "header-row",
      fn: function (props) {
        return [_vm.viewableColumns.includes('id') ? _c('th', [_c('div', {
          staticClass: "flex"
        }, [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "id",
            "order": "ASC"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Domain")]), _c('a', {
          staticClass: "inline-block",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              _vm.copyToClipboard(props.data.map(function (row) {
                return row.id;
              }).join('\r\n'));
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.top",
            value: `Copy Values`,
            expression: "`Copy Values`",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "ml-4 inline-block text-blue-600",
          attrs: {
            "name": "contentCopy",
            "size": 4
          }
        })], 1)], 1)]) : _vm._e(), _vm.viewableColumns.includes('registrar') ? _c('th', [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "registrar",
            "order": "ASC"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Registrar")])], 1) : _vm._e(), _c('th'), _vm.viewableColumns.includes('type') ? _c('th', [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "type",
            "order": "ASC"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Type")])], 1) : _vm._e(), _vm.viewableColumns.includes('expiry') ? _c('th', [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "expiry",
            "order": "DESC"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Expiry")])], 1) : _vm._e(), _vm.viewableColumns.includes('status') ? _c('th', [_vm._v("Status")]) : _vm._e(), _vm.viewableColumns.includes('publisherStatus') ? _c('th', [_vm._v("Pub Status")]) : _vm._e(), _vm.viewableColumns.includes('publishers') ? _c('th', [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "active_user_count",
            "order": "DESC"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Publishers")])], 1) : _vm._e(), _vm.viewableColumns.includes('publisherHistory') ? _c('th', [_vm._v(" Historical Publishers ")]) : _vm._e(), _vm.viewableColumns.includes('tags') ? _c('th', [_vm._v("Tags")]) : _vm._e(), _vm.viewableColumns.includes('dns') ? _c('th', [_vm._v("DNS")]) : _vm._e(), _vm.viewableColumns.includes('createdAt') ? _c('th', [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "createdAt"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Created")])], 1) : _vm._e(), _vm.viewableColumns.includes('updatedAt') ? _c('th', [_c('y-sort-column', {
          staticClass: "ml-1",
          attrs: {
            "sort-order": _vm.$store.getters['ydns/domainsTableSortOrder'],
            "column": "updatedAt"
          },
          on: {
            "sort": _vm.updateSortOrder
          }
        }, [_vm._v("Updated")])], 1) : _vm._e(), _vm.viewableColumns.includes('records') ? _c('th', [_vm._v("Records")]) : _vm._e(), _c('th', {
          staticStyle: {
            "text-align": "right",
            "padding": "0 !important"
          }
        })];
      }
    }, {
      key: "row",
      fn: function (props) {
        return [_vm.viewableColumns.includes('id') ? _c('td', [_vm._v(" " + _vm._s(props.rowData.id) + " ")]) : _vm._e(), _vm.viewableColumns.includes('registrar') ? _c('td', {
          staticStyle: {
            "min-width": "250px"
          }
        }, [_c('div', {
          staticClass: "flex"
        }, [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('div', [_vm._v(" " + _vm._s(props.rowData.registrar) + " ")]), props.rowData.registrarAccount ? _c('div', {
          staticClass: "text-xs italic -mt-1"
        }, [_vm._v(" " + _vm._s(props.rowData.registrarAccount) + " ")]) : _vm._e()]), _c('button', {
          staticClass: "text-blue-600 hover:text-blue-800 px-2",
          on: {
            "click": function ($event) {
              return _vm.hydrateWhoIsData([props.rowData.id]);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "refresh",
            "size": 4
          }
        })], 1)])]) : _vm._e(), _c('td', {
          staticStyle: {
            "padding": "0"
          }
        }, [_c('v-popover', {
          class: ['sidebar-user-popover'],
          attrs: {
            "offset": "10",
            "placement": "left-center",
            "autoHide": true,
            "popoverClass": "",
            "popoverBaseClass": "popover-menu sidebar-popover-menu",
            "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
          }
        }, [_c('YButton', {
          staticClass: "btn btn-transparent btn-rounded tooltip-target"
        }, [_c('Icon', {
          attrs: {
            "name": "dotsVertical",
            "size": 4
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "list-group"
        }, [props.rowData.deletedAt ? [_c('button', {
          staticClass: "list-group-item list-group-item-action",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.restore([props.rowData]);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          attrs: {
            "name": "refresh",
            "size": 4
          }
        }), _vm._v("Restore ")], 1)])] : [_c('SpamhausCheckPopover', {
          attrs: {
            "model": "Domain",
            "rowData": props.rowData
          }
        }), _c('button', {
          staticClass: "list-group-item list-group-item-action",
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.openEditModal([props.rowData]);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "fileEditOutline",
            "size": 4
          }
        }), _vm._v("Edit Records ")], 1)]), _c('button', {
          staticClass: "list-group-item list-group-item-action",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.openEditTagsModal(props.rowData);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "tagMultipleOutline",
            "size": 4
          }
        }), _vm._v("Edit Tags ")], 1)]), _c('button', {
          staticClass: "list-group-item list-group-item-action",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.openEditPublishersModal({
                modelIds: [props.rowData.id],
                modelClass: 'domain',
                ability: 'use',
                all: _vm.permissionsByDomainId[props.rowData.id] ? _vm.permissionsByDomainId[props.rowData.id].all : false,
                only: _vm.permissionsByDomainId[props.rowData.id] ? _vm.permissionsByDomainId[props.rowData.id].only : undefined,
                except: _vm.permissionsByDomainId[props.rowData.id] ? _vm.permissionsByDomainId[props.rowData.id].except : undefined
              });
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "accountMultipleOutline",
            "size": 4
          }
        }), _vm._v("Edit Publishers ")], 1)]), _c('button', {
          staticClass: "list-group-item list-group-item-action",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.openEditDomainStatusModal(props.rowData);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "circleOutline",
            "size": 4
          }
        }), _vm._v(" Edit Status ")], 1)]), props.rowData.type !== 'nameserver' ? _c('button', {
          staticClass: "list-group-item list-group-item-action",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.openEditNameserverModal(props.rowData);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "pencil",
            "size": 4
          }
        }), _vm._v(" Edit Nameserver ")], 1)]) : _vm._e(), _c('ClickAndReplace', {
          attrs: {
            "duration": 5000
          },
          scopedSlots: _vm._u([{
            key: "replacement",
            fn: function () {
              return [_c('button', {
                staticClass: "bg-red-500 text-white hover:bg-red-600 list-group-item list-group-item-action",
                on: {
                  "click": function ($event) {
                    return _vm.deleteDomains([props.rowData]);
                  }
                }
              }, [_vm._v(" Confirm Delete ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('button', {
          staticClass: "text-red-500 hover:bg-red-100 list-group-item list-group-item-action"
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "trashCanOutline",
            "size": 4
          }
        }), _vm._v("Delete ")], 1)])])]], 2)])], 2)], 1), _vm.viewableColumns.includes('type') ? _c('td', [_vm._v(" " + _vm._s(props.rowData.type && props.rowData.type.capitalize()) + " ")]) : _vm._e(), _vm.viewableColumns.includes('expiry') ? _c('td', {
          staticClass: "truncate"
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [props.rowData.tags.filter(function (tag) {
          return tag.tagConfig.tag == 'Let Expire';
        }).length > 0 ? _c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Let Expire`,
            expression: "`Let Expire`"
          }],
          staticClass: "text-indigo-600 mr-1",
          attrs: {
            "name": "checkCircle",
            "size": 4
          }
        }) : _vm._e(), _c('span', {
          class: _vm.getExpiryClass(props.rowData.expiry)
        }, [_vm._v(" " + _vm._s(_vm.transformExpiry(props.rowData.expiry)) + " ")]), _c('button', {
          staticClass: "text-blue-600 hover:text-blue-800 px-2",
          on: {
            "click": function ($event) {
              return _vm.hydrateWhoIsData([props.rowData.id]);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "refresh",
            "size": 4
          }
        })], 1)], 1)]) : _vm._e(), _vm.viewableColumns.includes('status') ? _c('td', [[_c('a', {
          staticClass: "cursor-pointer flex",
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.openEditDomainStatusModal(props.rowData);
            }
          }
        }, [props.rowData.status == 'active' ? _c('span', {
          staticClass: "text-green-500 hover:text-green-600"
        }, [_vm._v(_vm._s(props.rowData.status))]) : props.rowData.status == 'ready' || props.rowData.status == null ? _c('span', {
          staticClass: "text-blue-500 hover:text-blue-600"
        }, [_vm._v("ready")]) : props.rowData.status == 'flagged' ? _c('span', {
          staticClass: "text-red-500"
        }, [_vm._v(_vm._s(props.rowData.status))]) : _c('span', {
          staticClass: "text-red-500 hover:text-red-600"
        }, [_vm._v(" " + _vm._s(props.rowData.status) + " ")]), props.rowData.spamhausCheck && props.rowData.spamhausCheck.lastRunOutput ? _c('div', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `<div class='text-center'>Listed <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`,
            expression: "\n                  `<div class='text-center'>Listed <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`\n                "
          }],
          staticClass: "ml-1 mt-1 w-3",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "h-3",
          attrs: {
            "src": require("@/assets/images/logo/spamhaus-logo-red.png")
          }
        })]) : props.rowData.spamhausCheck ? _c('div', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `<div class='text-center'>Unlisted <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`,
            expression: "\n                  `<div class='text-center'>Unlisted <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`\n                "
          }],
          staticClass: "ml-1 mt-1 w-3",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "h-3",
          attrs: {
            "src": require("@/assets/images/logo/spamhaus-logo.png")
          }
        })]) : _vm._e(), props.rowData.spamhausCheck && props.rowData.spamhausCheck.enabled ? _c('span', [_vm._v(" * ")]) : _vm._e()])]], 2) : _vm._e(), _vm.viewableColumns.includes('publishers') ? _c('td', [_vm.permissionsByDomainId[props.rowData.id] && props.rowData.status != 'flagged' ? [_vm.permissionsByDomainId[props.rowData.id].all ? _c('Tag', {
          staticClass: "my-2",
          attrs: {
            "color": "blue"
          }
        }, [_vm._v("All Publishers")]) : _vm._e(), _vm.permissionsByDomainId[props.rowData.id].only ? _c('ul', _vm._l(_vm.permissionsByDomainId[props.rowData.id].only, function (publisher) {
          return _c('li', {
            key: publisher.id
          }, [_c('router-link', {
            directives: [{
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                placement: 'left',
                content: publisher.id
              },
              expression: "{\n                    placement: 'left',\n                    content: publisher.id\n                  }"
            }],
            staticClass: "link truncate block",
            staticStyle: {
              "max-width": "13rem"
            },
            attrs: {
              "to": {
                name: 'User',
                params: {
                  id: publisher.id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(publisher.status == 'active' ? publisher.email : null) + " ")])], 1);
        }), 0) : _vm._e(), _vm.permissionsByDomainId[props.rowData.id].except ? _c('ul', _vm._l(_vm.permissionsByDomainId[props.rowData.id].except, function (publisher) {
          return _c('li', {
            key: publisher.id
          }, [_c('router-link', {
            directives: [{
              name: "tooltip",
              rawName: "v-tooltip.left",
              value: publisher.id,
              expression: "publisher.id",
              modifiers: {
                "left": true
              }
            }],
            staticClass: "text-red-600 hover:text-red-800 truncate block",
            staticStyle: {
              "max-width": "13rem"
            },
            attrs: {
              "to": {
                name: 'User',
                params: {
                  id: publisher.id
                }
              }
            }
          }, [_vm._v(_vm._s(publisher.email))])], 1);
        }), 0) : _vm._e()] : _vm._e()], 2) : _vm._e(), _vm.viewableColumns.includes('publisherHistory') ? _c('td', [props.rowData.userDomainHistory.length > 0 ? _c('ul', _vm._l(props.rowData.userDomainHistory, function (history) {
          return _c('li', {
            key: history.id
          }, [history.user ? _c('router-link', {
            directives: [{
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                placement: 'left',
                content: history.user.status == 'fraud' ? 'FRAUD ' + history.user.id : history.user.id
              },
              expression: "{\n                  placement: 'left',\n                  content:\n                    history.user.status == 'fraud'\n                      ? 'FRAUD ' + history.user.id\n                      : history.user.id\n                }"
            }],
            staticClass: "truncate block",
            class: [history.user.status == 'fraud' ? 'text-red-600' : 'link'],
            staticStyle: {
              "max-width": "13rem"
            },
            attrs: {
              "to": {
                name: 'User',
                params: {
                  id: history.user.id
                }
              }
            }
          }, [_vm._v(_vm._s(history.user.email))]) : _vm._e()], 1);
        }), 0) : _vm._e()]) : _vm._e(), _vm.viewableColumns.includes('tags') ? _c('td', [props.rowData.tags && props.rowData.tags.length > 0 ? [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$refs.domainActions.openEditTagsModal(props.rowData);
            }
          }
        }, _vm._l(props.rowData.tags, function (tag) {
          return _c('Tag', {
            key: tag.tagConfig.tag,
            staticClass: "my-1 mr-1",
            attrs: {
              "color": tag.tagConfig.color
            }
          }, [_vm._v(_vm._s(tag.tagConfig.tag))]);
        }), 1)] : [_c('a', {
          staticClass: "text-blue-600 hover:text-blue-800",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$refs.domainActions.openEditTagsModal(props.rowData);
            }
          }
        }, [_vm._v("Add Tags")])]], 2) : _vm._e(), _c('td', [props.rowData.nameServerDomain ? _c('YTable', {
          staticClass: "w-100 w-min-100 table-hover size-xs",
          attrs: {
            "data": props.rowData.nameServerDomain.nameServers,
            "select-by": "id",
            "header-class": "text-xs"
          },
          scopedSlots: _vm._u([{
            key: "row",
            fn: function (dnsProps) {
              return [_c('td', [_vm._v(" " + _vm._s(dnsProps.rowData.server.hostname) + " ")]), _c('td', [_vm._v(" " + _vm._s(dnsProps.rowData.server.serverIp) + " ")])];
            }
          }], null, true)
        }, [_c('template', {
          slot: "header-row"
        }, [_c('th', [_vm._v("Prefix")]), _c('th', [_vm._v("Content")])])], 2) : _vm._e()], 1), _vm.viewableColumns.includes('createdAt') ? _c('td', [_vm._v(" " + _vm._s(props.rowData.createdAt) + " ")]) : _vm._e(), _vm.viewableColumns.includes('updateYTadAt') ? _c('td', [_vm._v(" " + _vm._s(props.rowData.updatedAt) + " ")]) : _vm._e(), _vm.viewableColumns.includes('records') ? _c('td', {
          staticStyle: {
            "padding": "0px"
          }
        }, [props.rowData.records && props.rowData.records.length > 0 ? _c('YTable', {
          staticClass: "w-100 w-min-100 table-hover size-xs",
          attrs: {
            "scroll-x": false,
            "header-class": "text-xs",
            "data": props.rowData.records,
            "selectable": true,
            "select-all-disabled": true,
            "select-by": "id",
            "selected": _vm.selectedRecordIds
          },
          on: {
            "update:selected": function ($event) {
              _vm.selectedRecordIds = $event;
            },
            "select": _vm.newRecordsSelected,
            "deselect": _vm.recordsDeselected
          },
          scopedSlots: _vm._u([{
            key: "row",
            fn: function (recordProps) {
              return [_vm.viewableColumns.includes('records:type') ? _c('td', {
                staticStyle: {
                  "width": "20px"
                }
              }, [_vm._v(" " + _vm._s(recordProps.rowData.type) + " ")]) : _vm._e(), _c('td', {
                staticStyle: {
                  "width": "75px"
                }
              }, [_vm._v(" " + _vm._s(recordProps.rowData.subdomain) + " ")]), _vm.viewableColumns.includes('records:provider') ? _c('td', {
                staticStyle: {
                  "width": "150px"
                }
              }, [recordProps.rowData.ip && recordProps.rowData.ip.server && recordProps.rowData.ip.server.provider ? [_vm._v(" " + _vm._s(recordProps.rowData.ip.server.provider.name) + " (" + _vm._s(recordProps.rowData.ip.server.id) + ") ")] : _vm._e()], 2) : _vm._e(), _c('td', {
                staticStyle: {
                  "width": "250px"
                }
              }, [_vm._v(_vm._s(recordProps.rowData.content))]), _vm.viewableColumns.includes('records:ttl') ? _c('td', {
                staticStyle: {
                  "width": "20px"
                }
              }, [_vm._v(" " + _vm._s(recordProps.rowData.ttl) + " ")]) : _vm._e(), _vm.viewableColumns.includes('records:priority') ? _c('td', {
                staticStyle: {
                  "width": "20px"
                }
              }, [_vm._v(" " + _vm._s(recordProps.rowData.priority) + " ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('template', {
          slot: "header-row"
        }, [_vm.viewableColumns.includes('records:type') ? _c('th', [_vm._v("Type")]) : _vm._e(), _c('th', [_vm._v("Subdomain")]), _vm.viewableColumns.includes('records:provider') ? _c('th', [_vm._v(" Provider ")]) : _vm._e(), _c('th', [_vm._v("Content")]), _vm.viewableColumns.includes('records:ttl') ? _c('th', [_vm._v("TTL")]) : _vm._e(), _vm.viewableColumns.includes('records:priority') ? _c('th', [_vm._v(" Priority ")]) : _vm._e()])], 2) : _c('div', [_c('button', {
          staticClass: "text-blue-600 hover:text-blue-800",
          on: {
            "click": function ($event) {
              return _vm.$refs.domainActions.openEditModal([props.rowData]);
            }
          }
        }, [_vm._v(" Add Records ")])])], 1) : _vm._e()];
      }
    }])
  })], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }