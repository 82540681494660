var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-0 pt-2"
  }, [_c('div', [_c('div', {
    staticClass: "flex w-full mt-2"
  }, [_c('QuarantineLevelCard', {
    staticClass: "w-full mr-6",
    attrs: {
      "level": _vm.quarantine.level,
      "payout": _vm.currentPayout
    }
  }), _c('div', {
    staticClass: "h-full flex-grow rounded-md p-3 text-gray-900 border"
  }, [_c('div', {
    staticClass: "font-semibold uppercase mb-3"
  }, [_vm._v(" Subscription Limit ")]), _c('div', {
    staticClass: "text-left text-xs italic text-gray-500"
  }, [_vm._v(" You can only subscribe to a maxium of 3 campaigns per month ")]), _c('div', {
    staticClass: "text-gray-800 font-bold",
    staticStyle: {
      "font-size": "1.5rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.quarantine.campaignSubscribeCount) + " / " + _vm._s(_vm.quarantineConfig.campaignSubscribeLimit) + " ")])])], 1), _c('div', [_c('div', {
    staticClass: "text-center"
  }, [_c('YButton', {
    staticClass: "mt-16",
    attrs: {
      "color": "red",
      "is-loading": _vm.isDeleting
    },
    on: {
      "click": function ($event) {
        return _vm.deleteQuarantine(_vm.quarantine.id);
      }
    }
  }, [_vm._v("Remove Quarantine")])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }