<template>
  <Tabs show-type="show">
    <Tab :name="this.tab1" :active="true">
      <template v-slot:aside>
        <Tag color="purple" v-if="algorithm1Weight !== null">
          <EditableValue
            v-if="algorithm1Weight !== null"
            type="contentEditable"
            :mutation="EDIT_ALGORITHM_USAGE_WEIGHT"
            :variables="{ input: { id: 2, weight: '{value}' } }"
            :value="algorithm1Weight"
            :transformValueForQuery="value => parseInt(value)"
          />%
        </Tag></template
      >
      <AlgorithmTwo />
    </Tab>
    <Tab :name="this.tab9">
      <template v-slot:aside
        ><Tag color="purple" v-if="algorithm9Weight !== null"
          ><EditableValue
            v-if="algorithm9Weight !== null"
            type="contentEditable"
            :mutation="EDIT_ALGORITHM_USAGE_WEIGHT"
            :variables="{ input: { id: 9, weight: '{value}' } }"
            :value="algorithm9Weight"
            :transformValueForQuery="value => parseInt(value)"
          />
          %</Tag
        ></template
      >
      <AlgorithmNine />
    </Tab>
  
  </Tabs>
</template>

<script>
import AlgorithmTwo from '@/views/Admin/Algorithms/AlgorithmTwo/Algorithm'
import AlgorithmNine from '@/views/Admin/Algorithms/AlgorithmNine/Algorithm'
import gql from 'graphql-tag'
import EDIT_ALGORITHM_USAGE_WEIGHT from '@/graphql/Algorithm/EditAlgorithmUsageWeight.gql'

export default {
  components: {
    AlgorithmTwo,
    AlgorithmNine,
  },
  apollo: {
    algorithms: {
      query: gql`
        {
          algorithms {
            id
            metaData
          }
        }
      `
    }
  },
  data() {
    return {
      EDIT_ALGORITHM_USAGE_WEIGHT,
      tableData: [],
      tab1Weight: null,
      tab2Weight: null,
      tab1: '2 - yPanel (2D/4D/8D)',
      tab9: '9 - Balanced General/Redirect Opt(30D)'
    }
  },
  props: {},
  computed: {
    algorithm1Weight() {
      if (
        !this.algorithms ||
        !this.algorithms[1] ||
        !this.algorithms[1].metaData
      ) {
        return null
      }
      return JSON.parse(this.algorithms[1].metaData).weight
    },
    algorithm9Weight() {
      if (
        !this.algorithms ||
        !this.algorithms[8] ||
        !this.algorithms[8].metaData
      ) {
        return null
      }
      return JSON.parse(this.algorithms[8].metaData).weight
    },
  }
}
</script>

<style></style>
