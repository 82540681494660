<template>
  <div style="max-width: 45rem; margin: auto;">
    <QuarantineCampaignAlert v-if="me" :publisher="me" />
    <div v-if="!publisherCampaign">
      <div class="p-8 text-center">
        <template v-if="$apollo.queries.publisherCampaign.loading">
          <Spinner color="green" :size="10" />
        </template>
        <template v-else>
          <span class="text-gray-500">Campaign Not Found</span>
        </template>
      </div>
    </div>
    <div v-if="publisherCampaign">
      <portal to="top-bar-left-header">
        <div class="flex items-center">
          <YImage
            :src="publisherCampaign.imageThumbnailUrl"
            :width="12"
            :height="12"
            class="rounded block mr-2"
            v-img="{
              title: publisherCampaign.name,
              src: publisherCampaign.imageUrl
            }"
          />
          <div>
            {{ publisherCampaign.name }}
          </div>
        </div>
      </portal>
      <portal to="top-bar-secondary">
        <YAlert
          class="mb-0 mt-0 px-6"
          v-if="
            publisherCampaign.status == 'apply to run' &&
              me &&
              me.userDetails &&
              me.userDetails.agreedToTerms == true
          "
          :allowClose="false"
        >
          <div class="flex items-center">
            <Icon
              name="alertCircleOutline"
              :size="6"
              class="mr-2 flex-shrink opacity-50"
            />
            <div class="flex-grow">
              This campaign requires manual approval.
            </div>
            <div
              class="flex-shrink"
              v-tooltip="
                `You have reached your campaign subscribe limit this month.`
              "
            >
              <YButton
                color="blue"
                @click="openRequestApprovalModal"
                :disabled="campaignSubscribeLimitReached"
                >Request approval</YButton
              >
            </div>
          </div>
        </YAlert>
        <YAlert
          v-if="publisherCampaign.status == 'pending approval'"
          class="mb-0 mt-0 px-6"
          :allow-close="false"
        >
          <div class="flex items-center">
            <Icon
              name="progressCheck"
              :size="6"
              class="mr-2 flex-shrink opacity-50"
            />
            This campaign is currently pending approval.
          </div>
        </YAlert>
      </portal>
      <div v-if="publisherCampaign.imageUrl" class="mb-5 block sm:hidden">
        <div
          :style="
            `width: 100%; height: 200px; background-image: url('${publisherCampaign.imageUrl}'); background-size: cover; background-position: center;`
          "
          v-img="{
            title: publisherCampaign.name,
            src: publisherCampaign.imageUrl
          }"
          class="rounded"
        ></div>
      </div>

      <div class="flex items-start">
        <div
          v-if="publisherCampaign.imageUrl"
          class="mr-5 mb-0 hidden sm:block"
        >
          <div
            :style="
              ` background-image: url('${publisherCampaign.imageUrl}'); background-size: cover; background-position: center;`
            "
            class="rounded-md w-64 h-48"
            v-img="{
              title: publisherCampaign.name,
              src: `${publisherCampaign.imageUrl}`
            }"
          ></div>
        </div>
        <div>
          <h4 class="mb-1">
            <span class="pr-2 text-base font-bold">{{
              publisherCampaign.name
            }}</span>
            <sup class="text-gray-600 text-sm">{{ publisherCampaign.id }}</sup>
          </h4>
          <div class="pb-2">
            <div
              class="mr-1 mb-1 inline-flex"
              v-for="tag in publisherCampaign.verticalTags"
              :key="tag.id"
            >
              <Tag :color="tag.color">{{ tag.name }}</Tag>
            </div>
            <div
              class="mr-1 mb-1 inline-flex"
              v-for="tag in publisherCampaign.campaignTags"
              :key="tag.id"
            >
              <Tag :color="tag.color">{{ tag.name }}</Tag>
            </div>
            <Tag
              v-if="publisherCampaign.isCapped"
              color="darkRed"
              class="mr-1 mb-1"
              >CAPPED</Tag
            >
          </div>
          <div
            class="inline-flex pb-2 items-center"
            v-if="
              (campaignTopGeos && campaignTopGeos.length > 0) ||
                $apollo.queries.campaignTopGeos.loading
            "
          >
            <template v-if="campaignTopGeos && campaignTopGeos.length > 0">
              <div
                class="text-xl leading-3 font-semibold text-gray-600 pr-3"
                v-tooltip="`Top Geos`"
              >
                🏆
              </div>
              <div class="inline-flex">
                <Flag
                  v-for="country in campaignTopGeos"
                  :key="country.code"
                  :country="country.code"
                  v-tooltip="`${country.label}`"
                  class="m-px rounded"
                />
              </div>
            </template>
            <template v-else-if="$apollo.queries.campaignTopGeos.loading">
              <Spinner :size="4" color="gray" />
            </template>
          </div>
          <div class="mt-1 mb-1">
            <template
              v-if="
                publisherCampaign.redirect &&
                  publisherCampaign.redirect.status == 'subscribed'
              "
            >
              <template v-if="publisherCampaign.caps">
                <div
                  v-for="cap in publisherCampaign.caps"
                  :key="cap.id"
                  class="mb-2"
                >
                  <span class="font-semibold">
                    {{ cap.frequency.capitalize() }} Cap:
                  </span>
                  {{ capProgress(cap) }}
                  <ProgressBar
                    :bar-color="cap.isCapped ? '#f56565' : '#48bb78'"
                    :val="capProgressBarValue(cap)"
                    size="small"
                    :bar-border-radius="5"
                  ></ProgressBar>
                </div>
              </template>
            </template>
            <template v-else-if="publisherCampaign.capDefaults">
              <div
                v-for="capDefault in publisherCampaign.capDefaults"
                :key="capDefault.id"
                class="font-semibold mb-2"
              >
                {{ formatCapToString(capDefault) }}
              </div>
            </template>
          </div>
          <div
            v-html="publisherCampaign.description"
            class="mt-4 mb-3 campaign-description"
          ></div>
        </div>
      </div>

      <div class="mt-8 py-2" v-show="isExpanded == true">
        <template>
          <div class="mb-4">
            <YTable
              :data="visiblePayouts || []"
              class="table table-sm w-full"
              :scroll-x="false"
            >
              <template slot="header-row">
                <th>Geo</th>
                <th>Rate</th>
              </template>
              <template slot="row" slot-scope="props">
                <td>
                  <template v-if="props.rowData.country?.code == null">
                    All Countries
                  </template>
                  <template v-else>
                    {{ props.rowData.country.code }} -
                    {{ props.rowData.country.name }}
                  </template>
                </td>
                <td>
                  {{ props.rowData.rate ? props.rowData.rate + ' / ' : '' }}
                  <!-- <template v-if="props.rowData.prettyType == 'Variable CPA'">
                      {{ props.rowData.prettyType }}
                    </template> -->
                  <template v-if="props.rowData.prettyType == 'CPA'">
                    {{ publisherCampaign.payoutType }}
                  </template>
                  <template v-else>
                    {{ props.rowData.prettyType }}
                  </template>
                </td>
              </template>
            </YTable>

            <div
              class="py-1"
              v-if="publisherCampaign.payouts.length > showPayoutsLength"
            >
              <div class="flex justify-between items-center">
                <template v-if="payoutsAreExpanded">
                  <a class="link" @click.prevent="togglePayoutsAreExpanded"
                    >Collapse</a
                  >
                </template>
                <template v-else>
                  <div class="flex items-center">
                    <span class="text-gray-600 mr-4"
                      >+ {{ invisiblePayoutsLength }} more geos</span
                    >
                    <a
                      class="link block"
                      @click.prevent="togglePayoutsAreExpanded"
                      >View All</a
                    >
                  </div>
                </template>
                <div>
                  <a class="link" @click="exportPayouts">Export Payouts</a>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-row gap-4">
            <Widget class="mb-4 overflow-hidden flex-1">
              <WidgetHeader class="bg-gray-100 border-b-2 border-gray-200 py-3"
                ><span class="text-xs font-semibold text-gray-600"
                  >ALLOWED</span
                ></WidgetHeader
              >
              <WidgetBody class="p-0">
                <div class="grid grid-col-5 grid-flow-col ">
                  <div
                    class="row-span-4 col-span-3 border-r-2 border-gray-200 p-4"
                  >
                    <span class="block font-bold text-gray-700 pb-2"
                      >Traffic Type
                    </span>
                    <Tag
                      v-for="tag of publisherCampaign.allowedRestrictedTags.filter(
                        tag => !tag.isNot
                      )"
                      :key="tag.name"
                      :color="'green'"
                      class="mr-1 mb-2"
                      >{{ tag.name }}</Tag
                    >
                  </div>
                  <div class="col-span-1 border-b-2 border-gray-200 p-4">
                    <span class="block font-bold text-gray-700 pb-2"
                      >Browser Type
                    </span>
                    <Tag
                      v-for="tag of publisherCampaign.campaignBrowser"
                      :key="tag.name"
                      :color="'orange'"
                      class="mr-1 mb-2"
                      >{{ tag.name }}</Tag
                    >
                  </div>
                  <div class="row-span-2 p-4">
                    <span class="block font-bold text-gray-700 pb-2"
                      >OS Type
                    </span>
                    <Tag
                      v-for="tag of publisherCampaign.campaignOS"
                      :key="tag.name"
                      :color="'orange'"
                      class="mr-1 mb-2"
                      >{{ tag.name }}</Tag
                    >
                  </div>
                </div>
              </WidgetBody>
            </Widget>

            <Widget class="mb-4 overflow-hidden w-1/3">
              <WidgetHeader class="bg-gray-100 border-b-2 border-gray-200 py-3"
                ><span class="text-xs font-semibold text-gray-600"
                  >RESTRICTED</span
                ></WidgetHeader
              >
              <WidgetBody>
                <Tag
                  v-for="tag of publisherCampaign.allowedRestrictedTags.filter(
                    tag => tag.isNot
                  )"
                  :key="tag.name"
                  :color="'red'"
                  class="mr-1 mb-2"
                  >{{ tag.name }}</Tag
                >
              </WidgetBody>
            </Widget>
          </div>

          <Widget
            :disabled="
              publisherCampaign?.redirect?.status != 'subscribed' &&
                campaignSubscribeLimitReached
            "
            class="mb-4 overflow-hidden"
            v-if="this.me.userDetails.agreedToTerms"
          >
            <template v-slot:disabledOverlay>
              <div class="text-center pt-6">
                <strong class="text-orange-600"
                  >Your campaign subscription limit of 3 has been reached for
                  this month.</strong
                >
              </div>
            </template>
            <WidgetHeader class="bg-gray-100 border-b-2 border-gray-200 py-3">
              <template v-slot:rawContent>
                <div>
                  <div class="mb-2">
                    <span class="text-xs font-semibold text-gray-600"
                      >TRACKING LINKS & PARAMETERS</span
                    >
                  </div>
                  <RedirectParameters
                    :user="me"
                    layout="inline"
                    @value="updateRedirectParameters"
                    :disable-saving="true"
                  />
                </div>
              </template>
            </WidgetHeader>
            <WidgetBody
              v-if="
                publisherCampaign.redirect &&
                  publisherCampaign.redirect.status == 'unsubscribed'
              "
            >
              <div>
                <p>
                  Your account has been
                  <span class="font-bold text-pink-700">unsubscribed</span>
                  from this campaign. Please contact your account manager for
                  more information.
                </p>
              </div>
            </WidgetBody>
            <WidgetBody v-else>
              <div
                v-if="
                  publisherCampaign.offers &&
                    publisherCampaign.offers.length > 0
                "
              >
                <div
                  class="flex items-center mb-2"
                  v-for="offer in publisherCampaign.offers"
                  :key="offer.id"
                >
                  <div class="mr-4 w-20 h-20">
                    <YImage
                      :src="offer.imageThumbnailUrl"
                      :width="20"
                      :height="20"
                      class="rounded w-20 h-20 block"
                      v-img="{
                        title: publisherCampaign.name,
                        src: offer.imageUrl
                      }"
                    />
                  </div>

                  <div>
                    <p class="mb-1 fw-500">
                      <template
                        v-if="
                          offer.destinationType == 'direct' ||
                            (offer.template &&
                              offer.template.path == 'redirect')
                        "
                      >
                        Direct
                      </template>
                      <template v-else-if="offer.template">
                        {{
                          offer.template.name
                            ? offer.template.name
                            : 'Missing Name'
                        }}
                      </template>
                    </p>
                    <div
                      v-if="
                        publisherCampaign.redirect &&
                          publisherCampaign.redirect.status == 'subscribed'
                      "
                    >
                      <a
                        :href="
                          `${publisherCampaign.redirect.url}/o/${offer.encodedId}${redirectParametersString}`
                        "
                        class="link without-truncate break-all"
                        target="_blank"
                        >{{ publisherCampaign.redirect.url }}/o/{{
                          offer.encodedId
                        }}{{ redirectParametersString }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else-if="
                  publisherCampaign.redirect &&
                    publisherCampaign.redirect.status == 'subscribed'
                "
                class="flex items-center mb-2"
              >
                <div>
                  <div class="font-semibold text-gray-600">Your Link</div>
                  <a
                    class="link without-truncate break-all"
                    :href="
                      `${publisherCampaign.redirect.url}/${redirectParametersString}`
                    "
                    target="_blank"
                    >{{ publisherCampaign.redirect.url }}/{{
                      redirectParametersString
                    }}</a
                  >
                </div>
              </div>
              <div
                v-if="
                  publisherCampaign.status == 'available' &&
                    (!publisherCampaign.redirect ||
                      (publisherCampaign.redirect &&
                        !publisherCampaign.redirect.status == 'approved'))
                "
              >
                <p class="mb-2">
                  <span class="font-semibold"
                    >Agree to the terms below to generate your tracking
                    links:</span
                  >
                </p>
                <YAlert :allow-close="false">
                  <div class="flex items-center justify-between">
                    <label>
                      <YCheckbox v-model="agreedToAllowed"></YCheckbox>
                      I acknowledge and agree that I will only send the
                      "Allowed" traffic listed above.
                    </label>
                    <YButton
                      color="blue"
                      :disabled="
                        !agreedToAllowed || campaignSubscribeLimitReached
                      "
                      @click="agreeToAllowedTraffic"
                      :is-loading="isAgreeing"
                      >I Agree</YButton
                    >
                  </div>
                </YAlert>
              </div>
            </WidgetBody>
          </Widget>

          <Widget
            class="mb-4 overflow-hidden"
            v-if="publisherCampaign.redirect"
          >
            <WidgetHeader class="bg-gray-100 border-b-2 border-gray-200 py-3"
              ><span class="text-xs font-semibold text-gray-600"
                >YOUR POSTBACKS</span
              ></WidgetHeader
            >
            <WidgetBody>
              <YAlert class="mt-0 mb-6">
                This campaign specific postback will override an
                <router-link
                  :to="{ name: 'Publisher Postbacks' }"
                  class="link font-semibold"
                  >account level postback</router-link
                >.
              </YAlert>

              <div v-if="publisherCampaign.redirect.postbacks" class="my-4 ">
                <YTable :data="publisherCampaign.redirect.postbacks">
                  <template slot="header-row">
                    <th>url</th>
                    <th>
                      Conversion Type
                    </th>
                    <th></th>
                  </template>
                  <template slot="row" slot-scope="props">
                    <td>{{ props.rowData.url }}</td>
                    <td>
                      <div class="cursor-pointer ">
                        <v-popover offset="10" placement="top" trigger="hover">
                          <div class="flex align-center">
                            <Tag
                              color="blue"
                              v-if="props.rowData.conversionType"
                              >{{ props.rowData.conversionType.name }}</Tag
                            >
                            <Tag v-else color="orange">Default</Tag>

                            <Icon
                              name="information"
                              size="4"
                              class="ml-1 text-gray-500 self-center"
                            />
                          </div>

                          <template slot="popover">
                            <div class="bg-gray-100 rounded-md p-4">
                              <span
                                v-html="
                                  conversionTypeDescription(
                                    props.rowData.conversionType?.name ??
                                      'Default'
                                  )
                                "
                              ></span>
                            </div>
                          </template>
                        </v-popover>
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-end">
                        <a
                          href="#"
                          class="link p-1"
                          @click.prevent="openPostbackEditModal(props.rowData)"
                        >
                          <Icon name="pencil" :size="5" />
                        </a>
                        <a
                          href="#"
                          class="text-red-500 hover:text-red-600 p-1"
                          @click.prevent="
                            openPostbackDeleteModal(props.rowData)
                          "
                        >
                          <Icon name="trashCan" :size="5" />
                        </a>
                      </div></td
                  ></template>
                </YTable>
              </div>

              <div class="py-1 flex justify-between">
                <YButton
                  v-show="!hasDefaultPostback"
                  class="btn-link w-full"
                  @click="openCreatePostbackModal()"
                  :disabled="hasDefaultPostback"
                  >Add Campaign Postback</YButton
                >
              </div>
            </WidgetBody>
          </Widget>
        </template>
      </div>
      <!-- <div
        @click="toggleExpand"
        class="py-2 bg-gray-100 widget-x-edge px-4 text-center link uppercase font-semibold"
      >
        {{ this.isExpanded ? 'COLLAPSE' : 'EXPAND' }}
      </div> -->
    </div>
    <!-- <Widget>
      <WidgetHeader>Postback</WidgetHeader>
      <WidgetBody class="pt-0"> </WidgetBody>
    </Widget> -->
    <!-- <div>
      <widget
        background="primary"
        v-if="me && me.userDetails.agreedToTerms == false"
      >
        <widget-header icon="zmdi zmdi-info-outline">
          <h6 class="mb-0 font-weight-semibold">
            You must accept Terms & Conditions before running any YTZ Campaigns
          </h6>
          <button
            slot="right"
            class="btn btn-sm btn-primary font-weight-semibold"
            @click="popTerms"
          >
            View Terms & Conditions
          </button>
        </widget-header>
      </widget>
      <widget v-else>
        <widget-header>
          <div class="d-flex mb-2 align-items-center">
            <h4 class="mb-0  pb-0 mr-3">{{ campaign.name }}</h4>
          </div>
          <p class="subtitle h5 text-secondary mb-1">
            <span>Campaign ID:</span> {{ campaign.id }}
          </p>
          <div class="ts-2">
            <tag
              v-for="vertical in campaign.verticalTags"
              :key="vertical.name"
              class="mr-1"
              :color="vertical.color"
              >{{ vertical.name }}</tag
            >
            <tag
              v-for="tag in campaign.campaignTags"
              :key="tag.name"
              class="mr-1"
              :color="tag.color"
              >{{ tag.name }}</tag
            >
            <div v-if="campaign.topGeos && campaign.topGeos.length > 0">
              <span class="pr-2">Top Geos:</span>
              <div v-for="topGeo in campaign.topGeos" :key="topGeo.code">
                <div v-if="topGeo.code == '?'" class="badge badge-success mr-1">
                  All Geos
                </div>
                <img
                  v-else
                  class="mr-1"
                  v-tooltip="topGeo.code"
                  :src="getFlag(topGeo.code)"
                  width="16"
                />
              </div>
            </div>
          </div>
        </widget-header>
        <div v-if="campaign.redirect">
          Redirect subscribed
        </div>
        <div v-else>
          Redirect Not subscribed
        </div>
      </widget>
    </div> -->
  </div>
</template>
<script>
import PUBLISHER_CAMPAIGN_QUERY from '@/graphql/PublisherCampaign/PublisherCampaignQuery.gql'
import AGREE_TO_ALLOWED_TRAFFIC_MUTATION from '@/graphql/PublisherCampaign/AgreeToAllowedTrafficMutation.gql'
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import RequestCampaignApprovalWidget from '@/views/Publisher/Campaign/RequestCampaignApprovalWidget'
import RedirectParameters from '@/views/Publisher/Account/RedirectParameters'
import PostbackCreate from '@/views/Publisher/Postbacks/PostbackCreate'
import PostbackDelete from '@/views/Publisher/Postbacks/PostbackDelete'
import PostbackEdit from '@/views/Publisher/Postbacks/PostbackEdit'
import CAMPAIGN_TOP_GEOS_QUERY from '@/graphql/Report/CampaignTopGeosQuery.gql'
import ConversionTypeDescription from '@/mixins/ConversionTypeDescriptionMixing.js'
import { saveAs } from 'file-saver'
import QuarantineCampaignAlert from '@/views/Publisher/Campaigns/QuarantineCampaignAlert.vue'

export default {
  mixins: [ConversionTypeDescription],
  components: {
    QuarantineCampaignAlert,
    RedirectParameters
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  apollo: {
    me: {
      query: ME_QUERY
    },
    publisherCampaign: {
      query: PUBLISHER_CAMPAIGN_QUERY,
      variables() {
        return {
          publisherId: this.$store.getters.authId,
          id: this.id
        }
      },
      result: function(result) {
        if (result.data) {
          this.publisherCampaign = result.data.publisherCampaign
          this.campaignRedirects = result.data.publisherCampaign.redirect
            ? result.data.publisherCampaign.redirect
            : false
        }
      },
      fetchPolicy: 'cache-and-network'
    },
    campaignTopGeos: {
      query: CAMPAIGN_TOP_GEOS_QUERY,
      variables() {
        return {
          campaignId: this.id
        }
      }
    }
  },
  data() {
    return {
      saveAs,
      campaign: {},
      isAgreeing: false,
      campaignTopGeos: null,
      publisherCampaign: null,
      isExpanded: true,
      payoutsAreExpanded: false,
      showPayoutsLength: 3,
      redirectParameters: null,
      agreedToAllowed: false,
      me: null,
      page: 1,
      perPage: 1,
      campaignRedirects: false
    }
  },
  computed: {
    campaignSubscribeLimitReached() {
      return this.me.quarantine?.campaignSubscribeCount >= 3
    },
    hasDefaultPostback() {
      if (this.publisherCampaign?.redirect?.hasDefaultPostback == true) {
        return true
      }

      return false
    },
    redirectParametersString() {
      if (!this.redirectParameters) {
        return ''
      }

      let params = Object.entries(this.redirectParameters)
        .filter(param => param[1])
        .map(param => `${param[0]}=${param[1]}`)
        .join('&')

      return params ? '?' + params : ''
    },
    payoutsObjectForCSV() {
      if (!this.publisherCampaign) {
        return
      }
      return this.publisherCampaign.payouts.map(payout => ({
        countryCode: payout.country.code,
        countryName: payout.country.name,
        rate: payout.rate,
        type: payout.prettyType
      }))
    },
    visiblePayouts() {
      if (!this.publisherCampaign) {
        return []
      }
      let output = []
      let allPayouts = this._.cloneDeep(this.publisherCampaign.payouts)
      let condensedPayouts = allPayouts.filter((payout, index) => {
        return index < this.showPayoutsLength
      })
      if (this.payoutsAreExpanded) {
        output = allPayouts
      } else {
        output = condensedPayouts
      }
      return output
    },
    invisiblePayoutsLength() {
      return this.publisherCampaign.payouts.length - this.visiblePayouts.length
    }
  },
  updated() {},
  methods: {
    capProgressBarValue(cap) {
      return (cap.currentValue / cap.triggerValue) * 100
    },
    capProgress(cap) {
      if (cap.type == 'payout') {
        return `$${cap.currentValue}/${cap.triggerValue}`
      } else if (cap.type == 'publisherActions') {
        return `${cap.currentValue}/${cap.triggerValue} Actions`
      } else {
        return ''
      }
    },
    formatCapToString(cap) {
      if (cap.type == 'payout') {
        return `$ ${cap.triggerValue} ${cap.frequency.capitalize()} Cap`
      } else if (cap.type == 'publisherActions') {
        return `${cap.triggerValue} ${cap.frequency.capitalize()} Actions Cap`
      } else {
        return ''
      }
    },
    agreeToAllowedTraffic() {
      this.isAgreeing = true
      this.$apollo
        .mutate({
          mutation: AGREE_TO_ALLOWED_TRAFFIC_MUTATION,
          variables: {
            campaignId: this.publisherCampaign.id
          }
        })
        .then(() => {
          this.isAgreeing = false
          this.$events.fire('refetchMe')
          this.$toastr.s('Agreed to Allowed Traffic', 'Success')
        })
        .catch(error => {
          this.isAgreeing = false
          console.error(error)
        })
    },
    updateRedirectParameters(params) {
      this.redirectParameters = params
    },
    openCreatePostbackModal() {
      let addDefault = !(
        this.publisherCampaign?.redirect?.postbacks?.length > 0
      )
        ? true
        : false

      console.log(addDefault)

      let showConversionTypes = true
      let conversionTypes = showConversionTypes
        ? [
            ...new Set(
              this.publisherCampaign.conversionTypes.map(type => type.name)
            )
          ]
        : []

      this.$modal.show(
        PostbackCreate,
        {
          addDefault: addDefault,
          userId: this.$store.getters.authId,
          lockRedirectId: Number(this.publisherCampaign.redirect.id),
          conversionTypes: conversionTypes
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    openPostbackDeleteModal(postback) {
      this.$modal.show(
        PostbackDelete,
        { postback: postback },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openPostbackEditModal(postback) {
      this.$modal.show(
        PostbackEdit,
        { postback: postback },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openRequestApprovalModal() {
      this.$modal.show(
        RequestCampaignApprovalWidget,
        {
          campaignId: parseInt(this.publisherCampaign.id)
        },
        {
          scrollable: true,
          width: '80%',
          height: 'auto',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded
      this.$emit('toggleExpand', this.isExpanded)
    },
    togglePayoutsAreExpanded() {
      this.payoutsAreExpanded = !this.payoutsAreExpanded
    },
    getFlag(country) {
      return require('@/assets/images/flags/svg/4x3/' +
        country.toLowerCase() +
        '.svg')
    },
    exportPayouts() {
      const items = this.payoutsObjectForCSV
      const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
      const header = Object.keys(items[0])
      let csv = items.map(row =>
        header
          .map(fieldName => JSON.stringify(row[fieldName], replacer))
          .join(',')
      )
      csv.unshift(
        header.map(column => '"' + this._.startCase(column) + '"').join(',')
      )
      csv = csv.join('\r\n')

      let blob = new Blob([csv], { type: 'text/plain; charset="utf-8"' })
      this.saveAs(blob, 'campaign_' + this.id + '_payouts.csv')
    },
    copyTrackingLinkToClipboard() {
      this.copyToClipboard(this.fullTrackingLink)
    }
  },
  mounted() {
    this.$events.listen('postbackCreated', () => {
      this.$apollo.queries.publisherCampaign.refetch()
    })
    this.$events.listen('postbackDeleted', () => {
      this.$apollo.queries.publisherCampaign.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('postbackCreated')
    this.$events.remove('postbackDeleted')
  }
}
</script>
<style scoped>
.campaign-description p:last-child {
  margin-bottom: 0 !important;
}

.x-row {
  @apply pb-4 bg-gray-100 rounded p-4 mb-4 border;
}

.x-row .x-col:first-child {
  @apply text-gray-600 text-left w-32 pr-4 mb-2;
  min-width: 8rem;
}
.x-row .x-col:last-child {
  @apply flex-grow;
}
</style>
