<template>
  <div>
    <div v-if="$apollo.queries.me.loading" class="my-4">
      <Spinner color="green" :size="10" />
    </div>
    <div v-else class="x-terms bg-gray-100">
      <div class="w-10/12 mx-auto">
        <pdf-viewer
          url="https://ytrack-documents.s3.us-east-1.amazonaws.com/ytz-terms-of-service.pdf"
          class="mb-4"
          :height="pdfHeight"
        ></pdf-viewer>
      </div>
      <div>
        <p v-if="me.userDetails.agreedToTerms" class="mb-4">
          <Icon
            name="check"
            class="mr-2 inline text-green-500"
            :size="4"
          />Terms accepted
          <template v-if="me.userDetails.agreedToTermsIp">
            by
            <span class="font-semibold text-blue-700">{{
              me.userDetails.agreedToTermsIp
            }}</span>
            at
            <span class="font-semibold text-blue-700">{{
              me.userDetails.agreedToTermsAt
            }}</span></template
          >
        </p>
        <form v-if="!me.userDetails.agreedToTerms">
          <YCheckbox
            id="agreed"
            :label="`I agree to the Terms & Conditions`"
            v-model="accepted"
            class="flex items-center mb-2"
          >
          </YCheckbox>

          <div class="flex">
            <YInput
              id="name"
              v-model="signature"
              label="Please sign your name..."
              class="flex-grow"
              inputClass=" border-r-0 rounded-r-none"
            />
            <YButton
              color="blue"
              class="flex-shrink rounded-l-none"
              :isLoading="submitting"
              :disabled="buttonDisabled"
              @click.prevent="submit"
              >Submit</YButton
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PdfViewer from '@/components/utilities/PdfViewer'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ME_QUERY from '@/graphql/User/MeQuery.gql'

export default {
  components: {
    PdfViewer
  },
  apollo: {
    me: {
      query: ME_QUERY
    }
  },
  props: {
    pdfHeight: {
      type: Number,
      default: 700
    }
  },
  data() {
    return {
      me: null,
      accepted: false,
      signature: null,
      submitting: false
    }
  },
  computed: {
    buttonDisabled() {
      return this.accepted == false ||
        this.signature == '' ||
        this.signature == null
        ? true
        : false
    }
  },
  methods: {
    submit() {
      this.submitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.me.id,
              userDetails: {
                update: {
                  id: this.me.userDetails.id,
                  agreedToTerms: true
                }
              }
            }
          }
        })
        .then(result => {
          this.$toastr.s('Thanks for accepting our terms.', 'Success!')
          this.submitting = false
          this.$emit('success', result)
          this.$emit('close')
          this.$router.push({ name: 'Publisher Dashboard' })
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.submitting = false
        })
    }
  },
  mounted() {
    //console.log(this.user)
  },
  updated() {}
}
</script>
<style scoped>
.x-terms {
  @apply py-6;
  max-height: 50rem;
}
</style>
