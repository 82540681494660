const blankOfferTemplate = {
  id: null,
  name: null,
  destinationType: null,
  details: null,
  advertiserIds: null,
  tags: [],
  tagNamesByType: {}
}

export const namespaced = true

export const state = {
  filters: {
    id: {
      value: null,
      title: 'ID',
      type: 'input'
    },
    oldId: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Old ID',
      type: 'input'
    },
    name: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Name',
      type: 'input'
    },
    offer: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Offer',
      type: 'input'
    },
    advertisers: {
      value: null,
      modifiers: {},
      title: 'Advertisers',
      type: 'select'
    },
    status: {
      value: null,
      modifiers: {},
      title: 'Status',
      type: 'select'
    },
    publisher: {
      value: null,
      modifiers: {},
      title: 'Publisher',
      type: 'select'
    },
    campaign: {
      value: null,
      modifiers: {},
      title: 'Campaign',
      type: 'select'
    },
    redirect: {
      value: null,
      modifiers: {},
      title: 'Redirect',
      type: 'select'
    },
    source: {
      value: null,
      modifiers: {},
      title: 'Source',
      type: 'select'
    },
    template: {
      value: null,
      title: 'Template',
      type: 'input'
    },
    verticalTags: {
      value: null,
      title: 'Verticals',
      type: 'select'
    },
    payoutTypeTags: {
      value: null,
      title: 'Payout Type',
      type: 'select'
    },
    destinationType: {
      value: null,
      title: 'Destination Type',
      type: 'select'
    },
    mobileDesktopTags: {
      value: null,
      title: 'Mobile / Desktop',
      type: 'select'
    },
    countryCodeTags: {
      value: null,
      title: 'Country',
      type: 'select'
    },
    mobileDesktopTrafficTags: {
      value: null,
      title: 'Mobile / Desktop',
      type: 'select'
    },
    countryCodeTrafficTags: {
      value: null,
      title: 'Country',
      type: 'select'
    },
    trafficTypeTags: {
      value: null,
      title: 'Traffic Type',
      type: ' select'
    },
    trafficTags: {
      value: null,
      title: 'Traffic Tags',
      type: 'select'
    },
    offerTypeTags: {
      value: null,
      title: 'Offer Type',
      type: ' select'
    },
    yleaderStatus: {
      value: null,
      modifiers: {},
      title: 'yLeader Status',
      type: 'select'
    },
    ignoreTimeout: {
      value: null,
      modifiers: {},
      title: 'Ignore Timeout',
      type: 'select'
    }
  },
  orderBy: [
    {
      column: 'id',
      order: 'DESC'
    }
  ],
  mutateOffer: JSON.parse(JSON.stringify(blankOfferTemplate)),
  cacheValid: null
}

export const mutations = {
  UPDATE_CACHE_VALIDITY(state, payload) {
    state.cacheValid = payload
  },
  UPDATE_MUTATE_OFFER(state, { value, replaceObject = false }) {
    if (replaceObject) {
      state.mutateOffer = value
    } else {
      Object.entries(value).forEach(([key, value]) => {
        state.mutateOffer[key] = value
      })
    }
  }
}

export const actions = {
  validateCache({ commit }) {
    commit('UPDATE_CACHE_VALIDITY', true)
  },
  invalidateCache({ commit }) {
    commit('UPDATE_CACHE_VALIDITY', false)
  },
  updateMutateOffer({ commit }, { value, replaceObject = false }) {
    commit('UPDATE_MUTATE_OFFER', { value, replaceObject })
  },
  clearMutateOffer({ commit }) {
    commit('UPDATE_MUTATE_OFFER', {
      value: JSON.parse(JSON.stringify(blankOfferTemplate)),
      replaceObject: true
    })
  }
}
