var render = function render(){
  var _vm$user$userDetails, _vm$user$userDetails2, _vm$userActivity$, _vm$userActivity$$pro;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "grid grid-cols-3 gap-2 py-2"
  }, [_c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_c('div', {
    staticClass: "uppercase text-xs font-semibold text-gray-800 flex justify-between"
  }, [_vm._v(" Signed Up On "), _c('Icon', {
    staticClass: "text-teal-500",
    attrs: {
      "name": "accountEdit",
      "size": "6"
    }
  })], 1), _c('div', {
    staticClass: "text-xl font-bold"
  }, [_vm._v(" " + _vm._s(_vm._f("luxon")(_vm.user.createdAt)) + " ")]), _c('div', {
    staticClass: "flex justify-between pt-1 text-xs text-gray-800"
  }, [_vm.user.createdMeta ? _c('div', [_vm.user.createdMeta.ip ? [_c('span', {
    staticClass: "opacity-50"
  }, [_vm._v(" " + _vm._s(_vm.user.createdMeta.ip) + " ")]), _c('a', {
    staticClass: "cursor-pointer",
    attrs: {
      "href": `https://www.ip2location.com/demo/${_vm.user.createdMeta.ip}`,
      "target": "_blank"
    }
  })] : _vm._e()], 2) : _vm._e()])]), _c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_c('div', {
    staticClass: "uppercase text-xs font-semibold text-gray-800 flex justify-between"
  }, [_vm._v(" Agreed to Terms "), _c('Icon', {
    staticClass: "text-purple-500",
    attrs: {
      "name": "clock",
      "size": "6"
    }
  })], 1), ((_vm$user$userDetails = _vm.user.userDetails) === null || _vm$user$userDetails === void 0 ? void 0 : _vm$user$userDetails.agreedToTerms) !== true ? _c('div', {
    staticClass: "text-base font-bold"
  }, [_vm._v(" Has not agreed to terms ")]) : _vm._e(), ((_vm$user$userDetails2 = _vm.user.userDetails) === null || _vm$user$userDetails2 === void 0 ? void 0 : _vm$user$userDetails2.agreedToTerms) == true ? _c('div', [_vm.user.userDetails.agreedToTermsAt !== '0000-00-00 00:00:00' ? _c('div', {
    staticClass: "text-xl font-bold"
  }, [_vm._v(" " + _vm._s(_vm._f("luxon")(_vm.user.userDetails.agreedToTermsAt)) + " ")]) : _vm._e(), _vm.user.userDetails ? _c('div', {
    staticClass: "flex justify-between pt-1 text-xs text-gray-800"
  }, [_c('span', {
    staticClass: "opacity-50"
  }, [_vm._v(" " + _vm._s(_vm.user.userDetails.agreedToTermsIp) + " ")])]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_c('div', {
    staticClass: "uppercase text-xs font-semibold text-gray-800 flex justify-between"
  }, [_vm._v(" Last Login "), _c('Icon', {
    staticClass: "text-blue-500",
    attrs: {
      "name": "logout",
      "size": "6"
    }
  })], 1), !_vm.user.lastLogin ? _c('div', {
    staticClass: "text-base font-bold"
  }, [_vm._v(" No Recent Data ")]) : _vm._e(), _vm.user.lastLogin ? _c('div', [_vm.user.userDetails.agreedToTermsAt !== '0000-00-00 00:00:00' ? _c('div', {
    staticClass: "text-xl font-bold"
  }, [_vm._v(" " + _vm._s(_vm._f("luxon")(_vm.user.lastLogin.createdAt)) + " ")]) : _vm._e(), _vm.user.userDetails ? _c('div', {
    staticClass: "flex justify-between pt-1 text-xs text-gray-800"
  }, [_c('span', {
    staticClass: "opacity-50"
  }, [_vm._v(" " + _vm._s((_vm$userActivity$ = _vm.userActivity[0]) === null || _vm$userActivity$ === void 0 ? void 0 : (_vm$userActivity$$pro = _vm$userActivity$.properties) === null || _vm$userActivity$$pro === void 0 ? void 0 : _vm$userActivity$$pro.ip) + " ")])]) : _vm._e()]) : _vm._e()])]), _c('Widget', {
    staticClass: "col-span-2"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100"
  }, [_vm._v("User Log")]), _c('WidgetBody', [_c('div', {
    staticClass: "widget-edge"
  }, [_vm.userActivity ? _c('YTable', {
    attrs: {
      "data": _vm.userActivity
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [props.rowData.causer ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.causer.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.causer.label))]) : _vm._e()], 1), _c('td', [props.rowData.subject ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.subject.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.subject.label))]) : _vm._e()], 1), _c('td', [_vm._v(_vm._s(props.rowData.description))]), _c('td', [props.rowData.properties ? [_c('UaParser', {
          attrs: {
            "input": props.rowData.properties.useragent
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (uaProps) {
              return [_c('ul', [_c('li', [_c('a', {
                staticClass: "link inline-flex items-center",
                attrs: {
                  "href": `https://www.ip2location.com/demo/${props.rowData.properties.ip}`,
                  "target": "_blank"
                }
              }, [props.rowData.properties.countryCode ? _c('Flag', {
                staticClass: "mr-2 rounded-sm overflow-hidden",
                attrs: {
                  "country": props.rowData.properties.countryCode
                }
              }) : _vm._e(), _vm._v(" " + _vm._s(props.rowData.properties.ip) + " ")], 1)]), uaProps.parsed != null ? _c('span', [_c('li', [_vm._v(" " + _vm._s(uaProps.parsed.browser.name) + " "), _c('span', {
                directives: [{
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: uaProps.parsed.browser.version,
                  expression: "uaProps.parsed.browser.version"
                }]
              }, [_vm._v(_vm._s(uaProps.parsed.browser.major))])]), _c('li', [_vm._v(" " + _vm._s(uaProps.parsed.os.name) + " " + _vm._s(uaProps.parsed.os.version) + " ")]), _c('li', [_vm._v(_vm._s(uaProps.parsed.device.type))])]) : _vm._e()])];
            }
          }], null, true)
        })] : _vm._e()], 2), _c('td', [_c('span', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.$luxon(props.rowData.createdAt, 'yyyy-MM-dd HH:mm:ss'),
            expression: "\n                  $luxon(props.rowData.createdAt, 'yyyy-MM-dd HH:mm:ss')\n                "
          }]
        }, [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(props.rowData.createdAt)) + " ")])])];
      }
    }], null, false, 2601050618)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("User (Causer)")]), _c('th', [_vm._v("User (Subject)")]), _c('th', [_vm._v("Description")]), _c('th', [_vm._v("Properties")]), _c('th', [_vm._v("Time")])])], 2) : _vm._e()], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }