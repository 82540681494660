<template>
  <div class="my-2 ml-4">
    <Tabs>
      <Tab name="Variables" :active="true">
        <div class="flex w-full gap-8 2xl:gap-12">
          <div
            class="w-full flex flex-col gap-3 pt-4"
            :style="existsTranslatedLanguages ? '' : 'max-width: 70rem'"
          >
            <div class="grid grid-cols-4 gap-3">
              <YInput label="Name of Offer" v-model="localOffer.name"></YInput>
              <YInput label="Category" v-model="localOffer.category"></YInput>
              <YInput label="Brand" v-model="localOffer.brand"></YInput>
              <YInput
                label="Review URL"
                v-model="localOffer.reviewUrl"
              ></YInput>
            </div>

            <div
              :class="existsTranslatedLanguages ? 'grid grid-cols-7 gap-4' : ''"
            >
              <div
                class="pt-2"
                :class="
                  existsTranslatedLanguages
                    ? 'col-span-3'
                    : 'grid grid-cols-2 gap-2'
                "
              >
                <div
                  class="w-full font-bold text-lg mb-5 text-gray-900"
                  :class="existsTranslatedLanguages ? '' : 'hidden'"
                >
                  English
                </div>
                <YTextarea
                  v-model="localOffer.description"
                  :label="
                    'Offer Description. Max ' + characterLimit + ' characters'
                  "
                  :characterLimit="characterLimit"
                ></YTextarea>

                <div class="flex flex-col gap-2">
                  <div
                    class="col-span-3 border rounded p-3 pt-1 bg-gray-50"
                    style="min-height: 3rem"
                    :key="saveFeature"
                  >
                    <span style="color: #667eea; font-size: 0.75rem">
                      Features</span
                    >
                    <ul
                      v-for="(feature, index) in localOffer.features"
                      :key="index"
                    >
                      <li>
                        <div class="flex space-between">
                          <div class="w-full">{{ feature }}</div>
                          <div>
                            <button @click="removeFeature(index)">
                              <Icon
                                name="close"
                                :size="4"
                                color="red"
                                class="inline"
                              />
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="flex gap-2 flex-start items-center">
                    <YInput
                      label="Enter Offer Feature"
                      v-model="attributeFeature"
                      class="w-full"
                    />
                    <button
                      @click="saveAttributeFeature"
                      class="bg-inverse-700 text-white h-8 w-8 rounded flex justify-center items-center"
                    >
                      <Icon name="plus" size="4" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="bg-gray-100 rounded-lg py-2 px-4 col-span-4 flex flex-col"
                :class="existsTranslatedLanguages ? '' : 'hidden'"
              >
                <div
                  class="w-full font-bold text-lg text-gray-900 flex justify-between"
                >
                  <div>
                    Language:
                    <span class="uppercase">{{ selectedLanguage }}</span>
                  </div>
                </div>
                <div class="flex text-xs text-gray-900">
                  <span class="w-1/2 pl-1">Original LLM</span>
                  <span class="w-1/2 pl-1">Manual Values</span>
                </div>
                <div class="grid grid-cols-2 gap-2 w-100 flex-1">
                  <YTextarea
                    v-model="descriptionLlmForSelectedLanguage"
                    :label="'Description_' + selectedLanguage"
                    disabled
                  ></YTextarea>
                  <div>
                    <YTextarea
                      v-model="manualDescriptionEntry"
                      :label="'Manual Description_' + selectedLanguage"
                    ></YTextarea>
                  </div>
                </div>

                <div
                  class="grid grid-cols-2 gap-2 w-100 flex-1"
                  v-if="featuresLlmForSelectedLanguage"
                >
                  <div class="rounded" style="min-height: 3rem">
                    <span
                      style="color: #667eea; font-size: 0.75rem"
                      class="py-1 pl-1 w-100 block border-t border-l border-r rounded-t"
                    >
                      Features_{{ selectedLanguage }}</span
                    >
                    <div
                      v-for="(feature, index) in featuresLlmForSelectedLanguage"
                      :key="index"
                    >
                      <YInput
                        disabled
                        class="w-full mb-1"
                        :label="
                          'feature_' + (index + 1) + '_' + selectedLanguage
                        "
                        :placeholder="feature"
                        :value="feature"
                      />
                    </div>
                  </div>

                  <div class="rounded bg-white" style="min-height: 3rem">
                    <span
                      class="py-1 pl-1 w-100 block border-t border-l border-r rounded-t"
                      style="color: #667eea; font-size: 0.75rem"
                    >
                      Manual Features_{{ selectedLanguage }}</span
                    >
                    <div
                      v-for="(value, key) in existsManualTranslatedFeatures"
                      :key="key"
                      class="mb-1"
                    >
                      <YInput
                        :label="'feature_' + (key + 1) + '_' + selectedLanguage"
                        placeholder="placeholder"
                        :value="value"
                        v-model="manualFeatureEntry[key]"
                        :alertIcon="
                          manualFeatureEntry[key] !==
                            featuresLlmForSelectedLanguage[key]
                        "
                        :muteInput="
                          manualFeatureEntry[key] ==
                            featuresLlmForSelectedLanguage[key]
                        "
                      />
                    </div>
                  </div>
                </div>

                <YButton
                  v-if="isSubmittingTranslations"
                  disabled
                  color="blue"
                  class="self-end mt-2 w-24"
                  style="width: 14rem"
                >
                  <Spinner color="white" :size="5" />
                </YButton>

                <YButton
                  v-if="!isSubmittingTranslations"
                  @click="saveManualTranslations"
                  color="blue"
                  class="self-end mt-2"
                  style="width: 14rem"
                  >Save Manual Translations</YButton
                >
              </div>
            </div>

            <div class="grid grid-cols-7 items-start gap-3 mt-2">
              <div class="minor-header">Theme Color</div>
              <div class="col-span-2 gap-4">
                <div class="grid grid-cols-2">
                  <div>
                    <ToggleButton
                      v-model="toggleThemeColor"
                      :color="{
                        checked: localOffer.themeColor,
                        unchecked: '#2a425a',
                        disabled: '#CCCCCC'
                      }"
                      :labels="{ checked: 'Custom', unchecked: 'Default' }"
                      :width="92"
                      :height="30"
                      :font-size="12"
                    />
                  </div>
                  <div class="flex justify-start items-center">
                    <template v-if="toggleThemeColor">
                      <Icon
                        name="colorFill"
                        :size="6"
                        class="inline text-gray-800 mr-1"
                      />
                      <verte
                        picker="square"
                        model="hex"
                        v-model="localOffer.themeColor"
                      ></verte>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-span-4">
                <span class="block text-gray-600 text-xs mt-1"
                  ><Icon
                    name="alertCircleOutline"
                    :size="4"
                    class="inline mr-2"
                  />
                  <span
                    >Default color is white. Controls background color of
                    images</span
                  >
                </span>
              </div>
            </div>
            <div class="grid grid-cols-7 items-start gap-3 mt-2">
              <div class="minor-header">Button Color</div>
              <div class="col-span-2 gap-4">
                <div class="grid grid-cols-2">
                  <div>
                    <ToggleButton
                      v-model="toggleThemeButtonColor"
                      :color="{
                        checked: localOffer.themeButtonColor,
                        unchecked: '#2a425a',
                        disabled: '#CCCCCC'
                      }"
                      :labels="{ checked: 'Custom', unchecked: 'Default' }"
                      :width="92"
                      :height="30"
                      :font-size="12"
                    />
                  </div>
                  <div class="flex justify-start items-center">
                    <template v-if="toggleThemeButtonColor">
                      <Icon
                        name="colorFill"
                        :size="6"
                        class="inline text-gray-800 mr-1"
                      />
                      <verte
                        picker="square"
                        model="hex"
                        v-model="localOffer.themeButtonColor"
                      ></verte>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-span-4">
                <span class="block text-gray-600 text-xs mt-1"
                  ><Icon
                    name="alertCircleOutline"
                    :size="4"
                    class="inline mr-2"
                  />
                  <span>Default is set by individual templates.</span>
                </span>
              </div>
            </div>

            <div class="flex flex-row items-start">
              <div class="minor-header w-32 mt-12">Image</div>
              <div class="flex items-center w-full">
                <div
                  class="p-2 rounded"
                  :class="thumbnailImageUrl ? 'border' : ''"
                  :style="{ backgroundColor: localOffer.themeColor }"
                >
                  <ImageInput
                    class="rounded"
                    :imageUrl.sync="thumbnailImageUrl"
                    :thumbnailImageUrl="thumbnailImageUrl"
                    :height="32"
                    :width="32"
                    :blankHeight="20"
                    :blankWidth="20"
                    :editable="true"
                    :editable-only-when-empty="false"
                    :file.sync="imageFile"
                    :key="isSubmitting"
                  ></ImageInput>
                </div>
                <div class="self-end ml-1 mb-1" v-if="thumbnailImageUrl">
                  <a :href="thumbnailImageUrl" target="_blank"
                    ><Icon name="openInNew"
                  /></a>
                </div>
                <div class="flex-1 flex justify-end">
                  <div>
                    <Spinner
                      :size="12"
                      v-if="isSubmitting"
                      color="green"
                    ></Spinner>
                  </div>
                  <YButton @click="updateOrCreate" color="green"
                    >Save Offer Changes</YButton
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex-end pt-3"
            :class="existsTranslatedLanguages ? 'flex' : 'invisible'"
            style="width: 18rem"
          >
            <div
              class="w-full rounded-lg border-2 border-blue-500 p-1"
              style="height: fit-content"
            >
              <Superselect
                class="w-full"
                title="Available Translations"
                v-model="selectedLanguage"
                :options="templateTranslatedLanguages"
                :multiple="false"
              />
            </div>
          </div>
        </div>
      </Tab>
      <Tab name="Languages">
        <div class="mt-4 flex flex-col gap-3" style="width: 28rem">
          <YButton v-if="isSubmitting" disabled color="blue">
            <Spinner color="white" :size="5" />
          </YButton>

          <YButton
            v-if="!isSubmitting"
            color="blue"
            @click="updateOrCreateTranslations"
          >
            Create or Update Translations</YButton
          >
          <Superselect
            title="Available Languages"
            v-model="localSelectedTranslatableLanguages"
            placeholder="Select Languages"
            :options="translatableLanguages"
            :showCheckBoxes="true"
            :multiple="true"
            :closeOnSelect="false"
          ></Superselect>
          <div class="w-20">
            <YButton
              color="link"
              @click="
                localSelectedTranslatableLanguages = [...translatableLanguages]
              "
              >Select All</YButton
            >
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import UPDATE_CREATE_OFFER_META from '@/graphql/Offer/UpdateOrCreateOfferMeta.gql'
import UPDATE_TRANSLATION from '@/graphql/Offer/UpdateTranslation.gql'
import SYNC_OFFER_TRANSLATE_LANGS from '@/graphql/Offer/SyncOfferTranslationLangs.gql'
import ImageInput from '@/components/utilities/ImageInput'
import { ToggleButton } from 'vue-js-toggle-button'
import Verte from 'verte'
import 'verte/dist/verte.css'

export default {
  offerShape: {
    name: '',
    category: '',
    brand: '',
    reviewUrl: '',
    description: '',
    features: [],
    images: [],
    themeColor: '',
    themeButtonColor: ''
  },
  mounted() {},
  updated() {},
  components: {
    ToggleButton,
    Verte,
    ImageInput
  },
  props: {
    offerMeta: {
      type: Object
    },
    offerTranslations: {
      type: Array
    },
    offerId: {
      type: String
    },
    translatableLanguages: {
      type: Array
    }
  },
  data() {
    let parsedOfferMetaData = {}

    if (typeof this.offerMeta?.data === 'string') {
      try {
        parsedOfferMetaData = JSON.parse(this.offerMeta.data)
      } catch (error) {
        console.error(error)
      }
    } else if (typeof this.offerMeta?.data === 'object') {
      parsedOfferMetaData = this.offerMeta.data
    }

    return {
      localOffer: {
        ...this.offerShape,
        ...parsedOfferMetaData
      },
      attributeFeature: '',
      saveFeature: true,
      isSubmitting: false,
      isSubmittingTranslations: false,
      imageFile: null,
      thumbnailImageUrl:
        this.offerMeta?.images[0]?.conversions[0]?.storageUrl ?? null,
      imageType: 'logo',
      characterLimit: 70,
      selectedLanguage: null,
      existsTranslatedLanguages: this.offerTranslations.length > 0,
      manualDescriptionEntry: undefined,
      localSelectedTranslatableLanguages: [],
      manualFeatureEntry: [],
      toggleThemeColor:
        this.offerMeta?.data && JSON.parse(this.offerMeta.data).themeColor
          ? true
          : false,
      toggleThemeButtonColor:
        this.offerMeta?.data && JSON.parse(this.offerMeta.data).themeButtonColor
          ? true
          : false
    }
  },
  methods: {
    removeFeature(index) {
      this.localOffer.features.splice(index, 1)
      this.saveFeature = !this.saveFeature
    },
    saveAttributeFeature() {
      if (this.attributeFeature) {
        this.localOffer.features
          ? this.localOffer.features.push(this.attributeFeature)
          : (this.localOffer.features = [this.attributeFeature])

        this.attributeFeature = ''
        this.saveFeature = !this.saveFeature
      } else {
        this.$toastr.e('Attribute feature cannot be blank')
      }
    },
    updateOrCreate() {
      this.isSubmitting = true

      let dataImage =
        this.imageFile && this.imageType
          ? [
              {
                type: this.imageType,
                file: this.imageFile
              }
            ]
          : []

      let themeColor = this.toggleThemeColor ? this.localOffer.themeColor : ''
      let themeButtonColor = this.toggleThemeButtonColor
        ? this.localOffer.themeButtonColor
        : ''

      let data = {
        name: this.localOffer.name ?? undefined,
        category: this.localOffer.category ?? undefined,
        brand: this.localOffer.brand ?? undefined,
        reviewUrl: this.localOffer.reviewUrl ?? undefined,
        //bonusValue: this.localOffer.bonusValue ?? undefined,
        //freeSpins: this.localOffer.freeSpins ?? undefined,
        description: this.localOffer.description ?? undefined,
        features: this.localOffer.features ?? undefined,
        themeColor: themeColor,
        themeButtonColor: themeButtonColor
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_CREATE_OFFER_META,
          variables: {
            input: {
              offerId: this.offerId,
              data: data,
              images: dataImage
            }
          }
        })
        .then(() => {
          this.$toastr.s('Attributes saved')
          this.$events.emit('offerTrafficked')
          this.$tabEvent.emit('refreshOffersTable')
          this.isSubmitting = false
          this.imageType = null
          this.imageFile = null
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false

          this.$toastr.e('Attribues not saved')
        })
    },
    saveManualTranslations() {
      let updateFeatures =
        JSON.stringify(this.manualFeatureEntry) !==
        JSON.stringify(this.featuresLlmForSelectedLanguage)

      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_TRANSLATION,
          variables: {
            input: {
              id: this.selectedTranslationId,
              dataOverride: {
                description: this.manualDescriptionEntry ?? undefined,
                features: updateFeatures ? this.manualFeatureEntry : undefined
              }
            }
          }
        })
        .then(() => {
          this.$toastr.s('Manual Changes Saved')
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
          this.$toastr.e('Manual Changes not saved')
        })
    },
    updateOrCreateTranslations() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SYNC_OFFER_TRANSLATE_LANGS,
          variables: {
            input: {
              id: this.offerId,
              languages: this.localSelectedTranslatableLanguages
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
        })
    },
    deepCloneLocalOffer(newVal, oldVal) {
      if (newVal === oldVal) return true

      if (typeof newVal !== 'object' || typeof oldVal !== 'object') return false

      const keys1 = Object.keys(newVal)
      const keys2 = Object.keys(oldVal)

      if (keys1.length !== keys2.length) return false

      for (let key of keys1) {
        if (
          !keys2.includes(key) ||
          !this.deepCloneLocalOffer(newVal[key], oldVal[key])
        )
          return false
      }

      return true
    }
  },
  computed: {
    selectedTranslationId() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation.id
      }
      return null
    },
    existsManualTranslatedDescription() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )

        if (
          translation &&
          translation.dataOverride &&
          translation.dataOverride.description
        ) {
          return translation.dataOverride.description
        }
      }
      return undefined
    },
    existsManualTranslatedFeatures() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )

        if (
          translation &&
          translation.dataOverride &&
          translation.dataOverride.features
        ) {
          return translation.dataOverride.features
        }
        return translation.data.features
      }
      return null
    },
    templateTranslatedLanguages() {
      if (this.offerTranslations) {
        return this.offerTranslations
          .map(item => item.language)
          .slice()
          .sort()
      }
      return null
    },
    descriptionLlmForSelectedLanguage() {
      if (this.selectedLanguage) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation ? translation.data.description : ''
      }
      return ''
    },
    featuresLlmForSelectedLanguage() {
      if (this.selectedLanguage) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation ? translation.data.features : ''
      }
      return ''
    }
  },
  created() {
    if (
      this.templateTranslatedLanguages &&
      this.templateTranslatedLanguages.length > 0
    ) {
      this.selectedLanguage = this.templateTranslatedLanguages[0]
      this.localSelectedTranslatableLanguages = [
        ...this.templateTranslatedLanguages
      ]

      this.manualFeatureEntry = [...this.existsManualTranslatedFeatures]
    } else {
      this.localSelectedTranslatableLanguages = [...this.translatableLanguages]
    }
  },
  watch: {
    clonedLocalOffer: {
      handler(newVal, oldVal) {
        if (!this.deepCloneLocalOffer(newVal, oldVal)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
      },
      deep: true
    },
    existsManualTranslatedDescription(newVal) {
      this.manualDescriptionEntry = newVal || []
    },
    selectedLanguage() {
      if (!this.existsManualTranslatedDescription) {
        this.manualDescriptionEntry = undefined
      }
      this.manualFeatureEntry = [...this.existsManualTranslatedFeatures]
    }
  }
}
</script>

<style scoped>
.minor-header {
  @apply text-gray-800 font-semibold capitalize;
}
.toggleTransition {
  transition: all 0.3s ease-in-out;
  transform: rotate(-180deg);
}
</style>
