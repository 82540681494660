<template>
  <div id="top">
    <widget class="mb-2">
      <widget-header class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <RedirectFilters :status="status"></RedirectFilters>
        </template>
      </widget-header>
    </widget>
    <div class="w-100">
      <div class="flex justify-between mb-1">
        <div></div>
        <div class="flex items-center">
          <YButton
            class="mr-1"
            color="light-blue"
            @click="shouldAutoEmail = !shouldAutoEmail"
          >
            <div class="flex justify-between">
              <YCheckbox
                id="auto-email"
                v-model="shouldAutoEmail"
                class="-ml-2 pointer-events-none"
              />
              <div>
                Auto Email
              </div>
            </div>
          </YButton>
          <template v-if="showBulkRedirectActions">
            <YButton
              color="blue"
              @click="
                openStatusModal({
                  modelName: 'Redirect',
                  modelId: selectedRowIds,
                  currentStatus: { name: 'subscribed' },
                  enumName: 'RedirectStatusEnum',
                  onSuccess: function(result) {
                    if (shouldAutoEmail) {
                      sendStatusUpdateEmails(selectedRowIds)
                    }
                  }
                })
              "
            >
              Update Statuses
            </YButton>
          </template>
        </div>
      </div>
      <YTable
        v-if="redirects"
        :data="redirects.data || []"
        :bottom-loading="$apolloData.queries.redirects.loading"
        :selectable="true"
        selectBy="id"
        :selected.sync="selectedRowIds"
      >
        <template slot="header-row">
          <th>
            <OrderByItem column="id" order="DESC" v-model="orderBy"
              >ID</OrderByItem
            >
          </th>
          <th>Link</th>
          <th>Campaign</th>
          <th>Publisher</th>
          <th>AM</th>
          <th>Status</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>
            <template v-if="props.rowData.id">
              <router-link
                :to="{
                  name: 'Redirect',
                  params: { id: props.rowData.id }
                }"
                class="link"
              >
                {{ props.rowData.id }}
              </router-link>
            </template>
          </td>
          <td>
            <template v-if="props.rowData.id">
              <a
                :href="'https://' + offerLinks(props.rowData)"
                class="link"
                target="_blank"
              >
                {{ offerLinks(props.rowData) }}
              </a>
            </template>
          </td>

          <td>
            <template v-if="props.rowData.campaign">
              <router-link
                :to="{
                  name: 'Campaign',
                  params: { id: props.rowData.campaign.id }
                }"
                class="link-wrap"
                v-tooltip="props.rowData.campaign.id"
              >
                {{ props.rowData.campaign.name }}
              </router-link>
            </template>
          </td>

          <td>
            <template v-if="props.rowData.user">
              <router-link
                :to="{
                  name: 'User',
                  params: { id: props.rowData.user.id }
                }"
                class="link"
                v-tooltip="props.rowData.user.id"
              >
                {{ props.rowData.user.email }}
              </router-link>
            </template>
          </td>

          <td>
            <div class="w-12">
              <template
                v-if="props.rowData.user && props.rowData.user.accountManager"
              >
                <span class="capitalize text-gray-800">
                  {{
                    props.rowData.user.accountManager.email.split('@')[0]
                  }}</span
                >
              </template>
            </div>
          </td>
          <td>
            <div
              v-if="props.rowData.user?.status == 'fraud'"
              v-tooltip="`User marked as fraud`"
            >
              <Tag color="gray"
                >unsubscribed
                <Icon
                  name="account"
                  size="3"
                  color="red"
                  class="inline-block"
                  style="margin-top:-4px;"
              /></Tag>
            </div>

            <div
              v-if="props.rowData.user?.status !== 'fraud'"
              class="flex items-center"
            >
              <div class="mr-1 flex w-4">
                <Icon
                  v-if="!props.rowData.userTrafficTagsMatchCampaign"
                  name="tagOff"
                  class="text-gray-400"
                  v-tooltip="`No matching traffic tags`"
                ></Icon>
              </div>
              <Tag
                v-if="props.rowData.statusInfo"
                v-tooltip="props.rowData.statusInfo.reason"
                :key="`${props.rowData.id}:${props.rowData.statusInfo.name}`"
                @click="
                  openStatusModal({
                    modelName: 'Redirect',
                    modelId: parseInt(props.rowData.id),
                    currentStatus: props.rowData.statusInfo,
                    enumName: 'RedirectStatusEnum',
                    onSuccess: function(result) {
                      if (shouldAutoEmail) {
                        sendStatusUpdateEmails([props.rowData.id])
                      } else {
                        openSendRedirectStatusUpdateEmailModal(props.rowData.id)
                      }
                    }
                  })
                "
              >
                {{ props.rowData.statusInfo.name }}
              </Tag>
              <div v-if="props.rowData.status == 'requested'" class="ml-1 flex">
                <Tag
                  color="green"
                  class="rounded-r-none"
                  v-tooltip="`Subscribe`"
                  @click="
                    openApproveSubscriptionRequestModal({
                      id: parseInt(props.rowData.id),
                      reason: props.rowData.statusInfo.reason,
                      onSuccess: function(result) {
                        if (shouldAutoEmail) {
                          sendStatusUpdateEmails([props.rowData.id])
                        } else {
                          openSendRedirectStatusUpdateEmailModal(
                            props.rowData.id
                          )
                        }
                      }
                    })
                  "
                  ><Icon name="check" :size="3"
                /></Tag>
                <Tag
                  color="red"
                  class="rounded-l-none"
                  v-tooltip="`Decline`"
                  @click="
                    openStatusModal({
                      modelName: 'Redirect',
                      modelId: parseInt(props.rowData.id),
                      newStatus: { name: 'declined' },
                      currentStatus: props.rowData.statusInfo,
                      onSuccess: function(result) {
                        if (shouldAutoEmail) {
                          sendStatusUpdateEmails([props.rowData.id])
                        } else {
                          openSendRedirectStatusUpdateEmailModal(
                            props.rowData.id
                          )
                        }
                      }
                    })
                  "
                  ><Icon name="close" :size="3"
                /></Tag>
              </div>
              <div v-if="props.rowData.status == 'declined'" class="ml-1 flex">
                <Tag
                  color="blue"
                  v-tooltip="`Restore`"
                  @click="
                    openStatusModal({
                      modelName: 'Redirect',
                      modelId: parseInt(props.rowData.id),
                      newStatus: { name: 'available' },
                      currentStatus: props.rowData.statusInfo,
                      onSuccess: function(result) {
                        if (shouldAutoEmail) {
                          sendStatusUpdateEmails([props.rowData.id])
                        } else {
                          openSendRedirectStatusUpdateEmailModal(
                            props.rowData.id
                          )
                        }
                      }
                    })
                  "
                  ><Icon name="refresh" :size="3"
                /></Tag>
              </div>
            </div>
          </td>
        </template>
        <template v-slot:after>
          <YPagination
            v-if="redirects.paginatorInfo"
            :data="redirects.paginatorInfo"
            @load-page="loadPage"
            @next-page="nextPage"
            @previous-page="previousPage"
            :key="totalPages"
            @per-page="perPageSelection"
          ></YPagination>
        </template>
      </YTable>
    </div>
  </div>
</template>

<script>
import RedirectFilters from '@/views/Admin/Redirects/RedirectFilters'
import SendRedirectStatusUpdateEmailModal from '@/views/Admin/Redirect/SendRedirectStatusUpdateEmailModal.vue'
import REDIRECTS_QUERY from '@/graphql/Redirect/RedirectsQuery.gql'
import TextColorMap from '@/assets/js/TextColorMap'
import HasStatusMixin from '@/mixins/HasStatusMixin'
import SEND_REDIRECT_STATUS_UPDATE_EMAILS_MUTATION from '@/graphql/Redirect/SendRedirectStatusUpdateEmailsMutation.gql'
import { mapState, mapActions } from 'vuex'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'
import ApproveRedirectSubscriptionRequestModal from '@/views/Admin/Redirects/ApproveRedirectSubscriptionRequestModal.vue'

export default {
  mixins: [HasStatusMixin, BackToTop],
  props: {
    overrideFilters: {
      type: Object
    },
    overrideGqlFilters: {
      type: Object
    },
    status: {
      type: String
    }
  },
  components: {
    RedirectFilters,
    YPagination
  },
  apollo: {
    redirects: {
      query: REDIRECTS_QUERY,
      variables() {
        return {
          filters: this.mergedGqlFilters,
          orderBy: this.orderBy,
          page: this.currentPage,
          first: this.perPage
        }
      },
      result({ data }, key) {
        this.totalPages = data[key].paginatorInfo.lastPage
      },
      deep: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      selectedRowIds: [],
      redirects: {},
      TextColorMap,
      shouldAutoEmail: true
    }
  },
  computed: {
    ...mapState({
      filters: state => state.redirect.filters
    }),
    showBulkRedirectActions() {
      return this.selectedRowIds.length > 0
    },
    mergedFilters() {
      // override filters merged with filters from vuex
      // Use case:
      //    Use this component in Campaigns section.
      //    But force the current campaign's id as the override filter so only it's own redirects will show
      if (!this.overrideFilters) {
        return this.filters
      } else {
        return { ...this.filters, ...this.overrideFilters }
      }
    },
    gqlPreparedFilters() {
      return {
        id: this.cleanFilter(this.mergedFilters.id),
        subdomain: this.cleanFilter(this.mergedFilters.subdomain),
        domain: this.cleanObjectFilter(this.mergedFilters.domain, 'id'),
        userId: this.cleanObjectFilter(this.mergedFilters.user, 'id'),
        campaignId: this.cleanObjectFilter(this.mergedFilters.campaign, 'id'),
        accountManagers:
          this.mergedFilters.accountManager.value &&
          this.mergedFilters.accountManager.value.length > 0
            ? this.filters.accountManager.value.map(value => value.id)
            : undefined,
        status:
          this.mergedFilters.status.value &&
          this.mergedFilters.status.value.length > 0
            ? this.filters.status.value
            : undefined
      }
    },
    mergedGqlFilters() {
      // override filters merged with filters from vuex
      // Use case:
      //    Use this component in Campaigns section.
      //    But force the current campaign's id as the override filter so only it's own redirects will show
      if (!this.overrideGqlFilters) {
        return this.gqlPreparedFilters
      } else {
        return { ...this.gqlPreparedFilters, ...this.overrideGqlFilters }
      }
    },
    orderBy: {
      get() {
        return this.$store.state.redirect.orderBy
      },
      set(value) {
        this.$store.state.redirect.orderBy = JSON.parse(JSON.stringify(value))
      }
    }
  },
  mounted() {
    if (this.status) {
      this.$store.dispatch('updateRedirectFilterValue', {
        filterKey: 'status',
        value: ['requested']
      })
      // this.$store.state.redirect.filters.status = this.status
    }
  },
  beforeDestroy() {},
  updated() {},
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    offerLinks(data) {
      if (!data.campaign) {
        return ''
      }
      if (data.campaign.offers.length > 0 && data.subdomain) {
        return `${data.subdomain}.${data.domain}${data.campaign.offers[0].path}`
      } else if (data.subdomain) {
        return `${data.subdomain}.${data.domain}`
      } else {
        return ''
      }
    },
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    sendStatusUpdateEmails(redirectIds) {
      if (!redirectIds || redirectIds.length == 0) {
        return
      }
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SEND_REDIRECT_STATUS_UPDATE_EMAILS_MUTATION,
          variables: {
            redirectIds: redirectIds
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('', 'Emails Added To Queue')
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false
          console.error(error)
          this.$toastr.e('There was an error queuing emails,', 'Oops!')
        })
    },
    openSendRedirectStatusUpdateEmailModal(redirectId) {
      this.$modal.show(
        SendRedirectStatusUpdateEmailModal,
        {
          redirectId: parseInt(redirectId)
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    openApproveSubscriptionRequestModal({ id, reason, onSuccess }) {
      this.$modal.show(
        ApproveRedirectSubscriptionRequestModal,
        {
          id,
          reason,
          onSuccess: onSuccess || undefined
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>
