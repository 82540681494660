var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.publisherCampaign ? _c('div', {
    staticClass: "widget-b-edge"
  }, [_vm.publisherCampaign.imageUrl && !_vm.editable ? _c('div', {
    staticClass: "mb-5 widget-edge widget-t-edge block sm:hidden"
  }, [_c('div', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: _vm.publisherCampaign.imageUrl
      },
      expression: "{\n        title: publisherCampaign.name,\n        src: publisherCampaign.imageUrl\n      }"
    }],
    style: `width: 100%; height: 200px; background-image: url('${_vm.publisherCampaign.imageUrl}'); background-size: cover; background-position: center;`
  })]) : _vm._e(), _c('div', {
    staticClass: "flex",
    staticStyle: {
      "min-height": "8rem"
    }
  }, [_vm.publisherCampaign.imageUrl && !_vm.editable ? _c('div', {
    staticClass: "mr-5 mb-0 widget-edge widget-t-edge hidden sm:block rounded-md w-48 h-48 overflow-hidden m-3"
  }, [_c('div', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: `${_vm.publisherCampaign.imageUrl}`
      },
      expression: "{\n          title: publisherCampaign.name,\n          src: `${publisherCampaign.imageUrl}`\n        }"
    }],
    style: `width: 250px; background-image: url('${_vm.publisherCampaign.imageUrl}'); background-size: cover; background-position: center; height: 100%;`
  })]) : _vm._e(), _c('div', [_c('h4', {
    staticClass: "mb-1"
  }, [_c('span', {
    staticClass: "pr-2 text-base font-bold"
  }, [_vm._v(_vm._s(_vm.publisherCampaign.name))]), _c('sup', {
    staticClass: "text-gray-600 text-sm"
  }, [_vm._v(_vm._s(_vm.publisherCampaign.id))])]), _vm._l(_vm.publisherCampaign.verticalTags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "mr-1 mb-1 inline-flex"
    }, [_c('Tag', {
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(_vm._s(tag.name))])], 1);
  }), _vm._l(_vm.publisherCampaign.campaignTags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "mr-1 mb-1 inline-flex"
    }, [_c('Tag', {
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(_vm._s(tag.name))])], 1);
  }), _c('div', [_c('ul', [_vm.publisherCampaign.caps && _vm.publisherCampaign.caps.length > 0 ? _vm._l(_vm.publisherCampaign.caps, function (cap) {
    return _c('li', {
      key: `Cap:${cap.id}`
    }, [_c('strong', [_vm._v(_vm._s(cap.frequency.capitalize()) + " Cap:")]), _c('span', {
      staticClass: "italic"
    }, [_vm._v(" " + _vm._s(cap.type != 'publisherActions' ? '$' : '') + _vm._s(cap.triggerValue) + " " + _vm._s(cap.type == 'publisherActions' ? 'actions' : '') + " ")])]);
  }) : _vm.publisherCampaign.capDefaults && _vm.publisherCampaign.capDefaults ? _vm._l(_vm.publisherCampaign.capDefaults, function (cap) {
    return _c('li', {
      key: `CapDefault:${cap.id}`
    }, [_c('strong', [_vm._v(_vm._s(cap.frequency.capitalize()) + " Cap:")]), _c('span', {
      staticClass: "italic"
    }, [_vm._v(" " + _vm._s(cap.type != 'publisherActions' ? '$' : '') + _vm._s(cap.triggerValue) + " " + _vm._s(cap.type == 'publisherActions' ? 'actions' : '') + " ")])]);
  }) : _vm._e()], 2)]), !_vm.editable ? _c('div', {
    staticClass: "mt-5 mb-5 campaign-description",
    domProps: {
      "innerHTML": _vm._s(_vm.publisherCampaign.description)
    }
  }) : _c('ckeditor', {
    staticClass: "mt-3 mb-3",
    staticStyle: {
      "min-width": "20rem"
    },
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig
    },
    model: {
      value: _vm.publisherCampaign.description,
      callback: function ($$v) {
        _vm.$set(_vm.publisherCampaign, "description", $$v);
      },
      expression: "publisherCampaign.description"
    }
  })], 2)]), _c('div', {
    staticClass: "mt-4 py-2"
  }, [_vm.publisherCampaign.redirect.status != 'subscribed' ? [_c('y-alert', {
    staticClass: "mt-3",
    attrs: {
      "type": "info",
      "allow-close": false
    }
  }, [_vm._v(" User is not subscribed ")])] : [_c('div', {
    staticClass: "x-row"
  }, [_vm._m(0), _c('div', {
    staticClass: "x-col"
  }, [_c('YTable', {
    staticClass: "table table-sm table-striped mb-0 w-100",
    attrs: {
      "data": _vm.visiblePayouts || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_vm.editable && _vm.publisherCampaign.previewOptions ? _c('td', [_c('y-checkbox', {
          attrs: {
            "type": "info"
          },
          model: {
            value: _vm.publisherCampaign.previewOptions.payouts[props.rowIndex],
            callback: function ($$v) {
              _vm.$set(_vm.publisherCampaign.previewOptions.payouts, props.rowIndex, $$v);
            },
            expression: "\n                    publisherCampaign.previewOptions.payouts[props.rowIndex]\n                  "
          }
        })], 1) : _vm._e(), _c('td', [props.rowData.countryCode == null ? [_vm._v(" ALL ")] : [_vm._v(" " + _vm._s(props.rowData.country.code) + " - " + _vm._s(props.rowData.country.name) + " ")]], 2), _c('td', [_vm._v(" " + _vm._s(props.rowData.rate ? props.rowData.rate + ' / ' : '') + " "), props.rowData.prettyType == 'Variable CPA' ? [_vm._v(" " + _vm._s(props.rowData.prettyType) + " ")] : props.rowData.prettyType == 'CPA' ? [_vm._v(" " + _vm._s(_vm.publisherCampaign.payoutType) + " ")] : [_vm._v(" " + _vm._s(props.rowData.prettyType) + " ")]], 2)];
      }
    }], null, false, 785080786)
  }, [_c('template', {
    slot: "header-row"
  }, [_vm.editable ? _c('th') : _vm._e(), _c('th', [_vm._v("Geo")]), _c('th', [_vm._v("Rate")])])], 2), _vm.unselectedPayoutsLength > 0 ? _c('div', {
    staticClass: "py-1"
  }, [_vm.payoutsAreExpanded ? [_c('a', {
    staticClass: "link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.togglePayoutsAreExpanded.apply(null, arguments);
      }
    }
  }, [_vm._v("Collapse")])] : [_c('span', {
    staticClass: "text-gray-600 mr-4"
  }, [_vm._v("+ " + _vm._s(_vm.invisibilePayoutsLength) + " more geos")]), _c('a', {
    staticClass: "link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.togglePayoutsAreExpanded.apply(null, arguments);
      }
    }
  }, [_vm._v("View All")])]], 2) : _vm._e()], 1)]), !_vm.editable && _vm.publisherCampaign.previewOptions.allowed || _vm.editable ? _c('div', {
    staticClass: "x-row"
  }, [_vm._m(1), _c('div', {
    staticClass: "x-col flex items-center"
  }, [_vm.editable ? _c('YCheckbox', {
    staticClass: "mr-2",
    model: {
      value: _vm.publisherCampaign.previewOptions.allowed,
      callback: function ($$v) {
        _vm.$set(_vm.publisherCampaign.previewOptions, "allowed", $$v);
      },
      expression: "publisherCampaign.previewOptions.allowed"
    }
  }) : _vm._e(), _c('div', {
    class: !_vm.publisherCampaign.previewOptions.allowed ? 'opacity-50' : ''
  }, _vm._l(_vm.publisherCampaign.allowedRestrictedTags.filter(function (tag) {
    return !tag.isNot;
  }), function (tag) {
    return _c('Tag', {
      key: tag.name,
      staticClass: "mr-1 mb-1",
      attrs: {
        "color": 'green'
      }
    }, [_vm._v(_vm._s(tag.name))]);
  }), 1)], 1)]) : _vm._e(), !_vm.editable && _vm.publisherCampaign.previewOptions.restricted || _vm.editable ? _c('div', {
    staticClass: "x-row"
  }, [_vm._m(2), _c('div', {
    staticClass: "x-col flex items-center"
  }, [_vm.editable ? _c('YCheckbox', {
    staticClass: "mr-2",
    model: {
      value: _vm.publisherCampaign.previewOptions.restricted,
      callback: function ($$v) {
        _vm.$set(_vm.publisherCampaign.previewOptions, "restricted", $$v);
      },
      expression: "publisherCampaign.previewOptions.restricted"
    }
  }) : _vm._e(), _c('div', {
    class: !_vm.publisherCampaign.previewOptions.restricted ? 'opacity-50' : ''
  }, _vm._l(_vm.publisherCampaign.allowedRestrictedTags.filter(function (tag) {
    return tag.isNot;
  }), function (tag) {
    return _c('Tag', {
      key: tag.name,
      staticClass: "mr-1 my-1",
      attrs: {
        "color": 'red'
      }
    }, [_vm._v(_vm._s(tag.name))]);
  }), 1)], 1)]) : _vm._e(), _c('div', {
    staticClass: "x-row"
  }, [_vm._m(3), _c('div', {
    staticClass: "x-col"
  }, [_vm.publisherCampaign.offers && _vm.publisherCampaign.offers.length > 0 ? _c('div', _vm._l(_vm.publisherCampaign.offers, function (offer) {
    return _c('div', {
      key: offer.id,
      staticClass: "flex items-center mb-2"
    }, [_c('div', {
      staticClass: "mr-4 w-20 h-20"
    }, [_c('YImage', {
      directives: [{
        name: "img",
        rawName: "v-img",
        value: {
          src: offer.imageUrl
        },
        expression: "{\n                    src: offer.imageUrl\n                  }"
      }],
      staticClass: "rounded w-20 h-20 block",
      attrs: {
        "src": offer.imageThumbnailUrl,
        "width": 20,
        "height": 20
      }
    })], 1), _c('div', [_c('p', {
      staticClass: "mb-1 fw-500"
    }, [offer.destinationType == 'direct' || offer.template && offer.template.path == 'redirect' ? [_vm._v(" Direct ")] : offer.template ? [_vm._v(" " + _vm._s(offer.template.name ? offer.template.name : 'Missing Name') + " ")] : _vm._e()], 2), _vm.publisherCampaign.redirect && _vm.publisherCampaign.redirect.status == 'subscribed' ? _c('div', [_c('a', {
      staticClass: "link without-truncate break-all",
      attrs: {
        "href": `${_vm.publisherCampaign.redirect.url}/o/${offer.encodedId}${_vm.redirectParametersString}`,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(_vm.publisherCampaign.redirect.url) + "/o/" + _vm._s(offer.encodedId) + _vm._s(_vm.redirectParametersString))])]) : _vm._e()])]);
  }), 0) : _vm.publisherCampaign.redirect && _vm.publisherCampaign.redirect.status == 'subscribed' ? _c('div', {
    staticClass: "flex items-center mb-2"
  }, [_c('div', [_c('div', {
    staticClass: "font-semibold text-gray-600"
  }, [_vm._v("Your Link")]), _c('a', {
    staticClass: "link without-truncate break-all",
    attrs: {
      "href": `${_vm.publisherCampaign.redirect.url}/${_vm.redirectParametersString}`,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.publisherCampaign.redirect.url) + "/" + _vm._s(_vm.redirectParametersString))])])]) : _vm._e()])])]], 2)]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x-col"
  }, [_c('small', {
    staticClass: "text-muted fw-500"
  }, [_vm._v("PAYOUTS:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x-col"
  }, [_c('small', {
    staticClass: "text-muted fw-500"
  }, [_vm._v("ALLOWED:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x-col"
  }, [_c('small', {
    staticClass: "text-muted fw-500"
  }, [_vm._v("RESTRICTED:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x-col"
  }, [_c('small', {
    staticClass: "text-muted fw-500"
  }, [_vm._v("LINKS:")])]);

}]

export { render, staticRenderFns }