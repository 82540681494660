import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import * as user from '@/store/modules/user.js'
import * as ui from '@/store/modules/ui.js'
import * as auth from '@/store/modules/auth.js'
import * as rule from '@/store/modules/rule.js'
import * as offer from '@/store/modules/offer.js'
import * as advertiser from '@/store/modules/advertiser.js'
import * as redirect from '@/store/modules/redirect.js'
import * as ydns from '@/store/modules/ydns.js'
import * as tag from '@/store/modules/tag.js'
import * as report from '@/store/modules/report.js'
import * as publisherCampaign from '@/store/modules/publisherCampaign.js'
import * as templateImages from '@/store/modules/templateImages.js'
import * as conversionType from '@/store/modules/conversionType.js'
import * as lead from '@/store/modules/lead.js'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    report: state.report,
    ydns: state.ydns
  })
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    ui,
    report,
    rule,
    advertiser,
    offer,
    redirect,
    ydns,
    tag,
    publisherCampaign,
    templateImages,
    conversionType,
    lead
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocal.plugin]
})
