var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetBody', {
    staticClass: "bg-gray-100"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div'), _c('y-button', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateAdvertiserAccountModal
    }
  }, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" New Account ")])], 1)]), _vm.advertiserAccounts ? _c('YTable', {
    attrs: {
      "data": _vm.advertiserAccounts.data || [],
      "bottom-loading": _vm.$apolloData.queries.advertiserAccounts.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$conver, _props$rowData$conver2;
        return [_c('td', [_vm._v(_vm._s(props.rowData.id))]), _c('td', [_c('editable-advertiser-account-ylead-type', {
          attrs: {
            "id": props.rowData.id
          }
        }, [_vm._v(_vm._s(props.rowData.yleadType))])], 1), _c('td', [_c('editable-advertiser-account-conversion-type', {
          attrs: {
            "id": props.rowData.id
          }
        }, [props.rowData.conversionType ? [_vm._v(" " + _vm._s((_props$rowData$conver = props.rowData.conversionType) === null || _props$rowData$conver === void 0 ? void 0 : _props$rowData$conver.name) + " ")] : _c('span', {
          staticClass: "text-gray-500"
        }, [_vm._v("None")])], 2)], 1), _c('td', [_c('editable-advertiser-account-username', {
          attrs: {
            "id": props.rowData.id
          }
        }, [_vm._v(_vm._s(props.rowData.userName))])], 1), _c('td', [_c('editable-advertiser-account-password', {
          attrs: {
            "id": props.rowData.id
          }
        }, [_vm._v(_vm._s(props.rowData.password))])], 1), _c('td', [_c('editable-advertiser-account-duplication-handler', {
          attrs: {
            "id": props.rowData.id
          }
        }, [_vm._v(_vm._s(props.rowData.duplicationHandler))])], 1), _c('td', [_c('div', {
          staticClass: "text-right"
        }, [_c('div', {
          staticClass: "inline-flex items-center"
        }, [((_props$rowData$conver2 = props.rowData.conversionType) === null || _props$rowData$conver2 === void 0 ? void 0 : _props$rowData$conver2.name) == 'Revshare Action' ? _c('a', {
          staticClass: "link x-action-icon",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              var _props$rowData$advert;
              $event.preventDefault();
              return _vm.openRevsharePullModel(props.rowData.id, (_props$rowData$advert = props.rowData.advertiserAccountRevshareHelper) === null || _props$rowData$advert === void 0 ? void 0 : _props$rowData$advert.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "information"
          }
        })], 1) : _vm._e(), _c('a', {
          staticClass: "link x-action-icon",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openAccountKeyModel(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "key"
          }
        })], 1), _c('a', {
          staticClass: "link x-action-icon",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openPixelModel(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "paperClip"
          }
        })], 1), _c('a', {
          staticClass: "text-red-600 hover:text-red-700 x-action-icon",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData.id, props.rowData.userName);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])])])];
      }
    }], null, false, 3950460555)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("yLead Type")]), _c('th', [_vm._v("Conversion Type")]), _c('th', [_vm._v("User Name")]), _c('th', [_vm._v("Password")]), _c('th', [_vm._v("Duplication Handler")]), _c('th', [_vm._v("Actions")])])], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }