<template>
  <div>
    <portal to="top-bar-right"></portal>
    <Widget class="reports-form">
      <WidgetHeader class="bg-gray-200">
        <template slot="rawContent">
          <div class="block lg:flex items-center lg:justify-between w-full">
            <YDateTimeRangePicker
              v-model="filters.dateTimeRange"
            ></YDateTimeRangePicker>
          </div>
        </template>
      </WidgetHeader>
      <transition
        @before-enter="animateShowFiltersBeforeEnter"
        @enter="animateShowFiltersEnter"
        @leave="animateShowFiltersLeave"
        :css="false"
      >
        <WidgetBody
          v-show="showFilters"
          class="pt-4 bg-gray-100"
          style="border-top: 1px solid #D9D9D9;"
        >
          <div class="grid grid-cols-1 md:grid-cols-6 gap-1">
            <div v-show="columnData.country">
              <Superselect
                title="Country"
                editPlaceholder="Paste Country Codes"
                v-model="filters.country.value"
                :modifiers.sync="filters.country.modifiers"
                :options="options.country"
                track-by="id"
                label="label"
                :query="queries.COUNTRIES_QUERY"
                :query-result-map="{
                  id: 'code',
                  label: 'label'
                }"
              ></Superselect>
            </div>
            <div v-show="columnData.campaign">
              <Superselect
                title="Campaign"
                v-model="filters.campaign.value"
                :modifiers.sync="filters.campaign.modifiers"
                track-by="id"
                label="label"
                :multiple="true"
                :options="campaigns || []"
              ></Superselect>
            </div>
            <div v-show="columnData.usageType">
              <Superselect
                title="Usage Type"
                v-model="filters.usageType.value"
                :modifiers.sync="filters.usageType.modifiers"
                editPlaceholder=""
                :forceEdit="true"
                track-by="id"
                label="label"
              >
              </Superselect>
            </div>
            <div v-show="columnData.keyword">
              <Superselect
                v-if="filters.keyword"
                title="Keyword"
                v-model="filters.keyword.value"
                :modifiers.sync="filters.keyword.modifiers"
                :forceEdit="true"
                editPlaceholder
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div v-show="columnData.subId">
              <Superselect
                title="Sub ID"
                v-model="filters.subId.value"
                :modifiers.sync="filters.subId.modifiers"
                :forceEdit="true"
                editPlaceholder
                track-by="id"
                label="label"
              ></Superselect>
            </div>
            <div v-show="columnData.payoutConversionType">
              <Superselect
                title="Conversion Type"
                v-model="filters.payoutConversionType.value"
                placeholder="Select One"
                :multiple="true"
                :modifiers.sync="filters.payoutConversionType.modifiers"
                track-by="id"
                label="label"
                :query="queries.CONVERSION_TYPE_OPTIONS_QUERY"
                queryDefaultInputText=""
                :queryVariables="{
                  filters: {
                    name: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :queryResultMap="{
                  id: 'name',
                  label: 'name'
                }"
              ></Superselect>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3 pt-5 gap-1">
            <div>
              <Superselect
                title="Show"
                v-model="showColumnsFilter"
                :options="showColumnsOptions"
                :multiple="true"
                placeholder="Select Columns"
                track-by="id"
                label="label"
                selectedLabel
                selectLabel
                deselectLabel
                group-values="values"
                group-label="label"
                :close-on-select="false"
                @select="columnSelected"
                @remove="columnRemoved"
              >
                <span slot="noResult">No Columns Found</span>
              </Superselect>
            </div>
            <!-- <div>
              <Superselect
                title="Sort By"
                v-model="sortByFilter"
                :options="sortByOptions"
                :multiple="true"
                placeholder="Sort By"
                track-by="value"
                :allow-empty="false"
                label="label"
                selectedLabel
                selectLabel
                deselectLabel
              >
                <span slot="noResult">No Columns Found</span>
              </Superselect>
            </div> -->
          </div>
        </WidgetBody>
      </transition>
      <WidgetHeader
        class="bg-opacity-faded flex-wrap"
        style="border-top: 1px solid #D9D9D9;"
      >
        <template slot="rawContent">
          <div class="mb-1 sm:mb-0">
            <YButton
              @click.prevent="showFilters = !showFilters"
              class="hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important"
            >
              <Icon
                :name="showFilters ? 'minus' : 'plus'"
                :size="4"
                class="inline mr-1"
              />
              {{ showFilters ? 'Hide' : 'Show Filters' }}
            </YButton>
          </div>
          <div class="flex items-center flex-row-reverse">
            <YButton
              class="hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important mt-2 px-3 md:px-6"
              @click="resetFilters()"
              :disabled="formButtonsDisabled"
              >Reset Filters</YButton
            >
            <div class="flex mt-2 md:mt-0">
              <YButton
                class="bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-3 outline-none-important"
                @click="doFilter()"
                :disabled="formButtonsDisabled"
                :isLoading="$apollo.queries.report.loading"
                >Submit</YButton
              >
              <YButton
                type="button"
                class="border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important"
                @click="doFilter({ type: 'export' }), (isExporting = true)"
                :disabled="formButtonsDisabled"
                :isLoading="isExporting"
                >Export</YButton
              >
            </div>
          </div>
        </template>
      </WidgetHeader>
    </Widget>
    <y-alert v-if="responseMessage" :type="responseMessage.type">{{
      responseMessage.content
    }}</y-alert>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import YDateTimeRangePicker from '@/components/ui/YDateTimeRangePicker'
import Velocity from 'velocity-animate'
import gql from 'graphql-tag'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import PUBLISHER_REDIRECTS_QUERY from '@/graphql/Redirect/PublisherRedirectsQuery.gql'
import { saveAs } from 'file-saver'
import CONVERSION_TYPE_OPTIONS_QUERY from '@/graphql/ConversionType/ConversionTypeOptionsQuery.gql'

import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],

  components: {
    YDateTimeRangePicker
  },
  props: {
    link: {
      type: Object
    },
    masterColumnData: {
      type: Object
    },
    createVuetableColumns: {
      type: Function
    },
    persistState: {
      type: Boolean,
      default: false
    },
    autoLoad: {
      type: Boolean,
      default: false
    },
    defaultDateRange: {
      type: Object,
      default: function() {
        return {
          start: this.$dateTime.local().toFormat('yyyy-LL-dd'),
          end: this.$dateTime.local().toFormat('yyyy-LL-dd')
        }
      }
    },
    defaultFilters: {
      type: Object
    }
  },
  apollo: {
    publisherRedirects: {
      query: PUBLISHER_REDIRECTS_QUERY,
      variables() {
        return {
          publisherId: this.$store.getters.authId,
          filters: {},
          orderBy: [{ column: 'campaign_id', order: 'DESC' }],
          page: 1,
          first: 1000
        }
      },
      result({ data }) {
        if (data) {
          this.campaigns = data.publisherRedirects.data
            .filter(publisherRedirects => publisherRedirects.campaign)
            .map(publisherRedirects => ({
              id: publisherRedirects.campaign.id,
              label: publisherRedirects.campaign.label
            }))
        }
      },
      deep: true
    },
    report: {
      query() {
        const query = gql`query getReport($filters: PublisherReportFilters!, $sortOrder: [SortItem!], $options: [ReportOptionsEnum], $export: Boolean, $fields: [String]!){
          report: publisherReport (filters: $filters, sortOrder: $sortOrder, export: $export, options: $options, fields: $fields) {
      		  name
            data{
              ${this.gqlColumns}
            }
            totals{
              ${this.gqlColumns}
            }
            message{
              type
              content
            }
            loadTimes
          }
        }`

        return query
      },
      variables() {
        return this.queryVariables
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        this.link.isLoading = true
        this.responseMessage = data.report.message ? data.report.message : null
        this.link.vuetableData = data.report.data
        this.link.totals = data.report.totals
        this.link.loadTimes = data.report.loadTimes
        this.link.startDateTime = this.cachedTableData.filters.dateTimeRange.start
        this.link.endDateTime = this.cachedTableData.filters.dateTimeRange.end
        this.refreshVuetable()
        if (this.activeOptions.type == 'export') {
          this.saveCsvString(
            this.jsonToCsv(this.cleanApolloData(data.report.data)),
            data.report.name + '.csv'
          )
        }
      },
      error(error) {
        this.setValidationErrors(error)
        this.link.vuetableData = []
        this.link.totals = []
        this.link.startDateTime = null
        this.link.endDateTime = null
        this.refreshVuetable()
      },
      skip() {
        return this.queryVariables ? false : true
      }
    }
  },
  data() {
    return {
      saveAs,
      activeOptions: {},
      queries: {
        CONVERSION_TYPE_OPTIONS_QUERY,
        COUNTRIES_QUERY,
        PUBLISHER_REDIRECTS_QUERY
      },
      campaigns: [],
      reportQuery: null,
      responseMessage: null,
      isSubmitting: false,
      isExporting: false,
      columnData: this.masterColumnData,
      colClass: 'col-xl-2 col-lg-6',
      dateTimeStart: null,
      dateTimeEnd: null,
      filters: {
        dateTimeRange: {
          start: this.defaultDateRange.start + ' 12:00 am',
          end: this.defaultDateRange.end + ' 11:59 pm'
        },
        payoutConversionType: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        country: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        campaign: {
          value: [],
          modifiers: {
            exclude: false
          }
        },
        keyword: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: true
          }
        },
        subId: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: true
          }
        },
        usageType: {
          value: [],
          modifiers: {
            exclude: false,
            matchType: 'exact',
            edit: false
          }
        }
      },
      options: {
        country: [],
        publisher: [],
        template: [],
        redirect: [],
        subId: []
      },
      configFilter: [],
      sortByFilter: [],
      showFilters: true,
      cachedTableData: {
        filters: null,
        sortOrder: null,
        fields: null,
        configFilter: null
      },
      originalState: {
        filters: null,
        sortOrder: null,
        fields: null,
        configFilter: null
      },
      queryVariables: null
    }
  },
  computed: {
    gqlColumns() {
      if (this.queryVariables) {
        return this.queryVariables.fields.join(' ')
      }
      return ''
    },
    sortByOptions() {
      let output = Object.values(this.showFiltersData)
      output = output
        .map(column => {
          if (column.filter.state) {
            return {
              label: column.filter.label,
              value: {
                field: column.column.name,
                sortField: column.column.sortField,
                direction: column.column.defSortDirection
                  ? column.column.defSortDirection
                  : 'asc'
              }
            }
          }
        })
        .filter(Boolean)
      return output
    },
    formButtonsDisabled() {
      return this.isSubmitting || this.isExporting ? true : false
    },
    showFiltersData() {
      let columns = this.masterColumnData
      var visibleFilters = {}
      for (var column in columns) {
        if (columns[column].filter.visible == true) {
          visibleFilters[column] = columns[column]
        }
      }
      return visibleFilters
    },
    showColumnsFilter: {
      get: function() {
        let columns = this.showFiltersData
        var activeColumns = []
        for (var column in columns) {
          if (
            columns[column].filter.visible == true &&
            columns[column].filter.state == true
          ) {
            activeColumns.push({
              id: column,
              label: columns[column].filter.label
            })
          }
        }
        return activeColumns
      },
      set: function(columns) {
        const activeStateColumns = columns.map(column => column.id)
        for (var key in this.showFiltersData) {
          this.showFiltersData[key].filter.state = activeStateColumns.includes(
            key
          )
            ? true
            : false
        }
      }
    },
    report: {
      get: function() {
        return {
          filters: this.filters,
          sortByFilter: this.sortByFilter,
          configFilter: this.configFilter,
          showColumnsFilter: this.showColumnsFilter
        }
      },
      set: function(newValue) {
        this.filters = newValue.filters
        this.sortByFilter = newValue.sortByFilter
        this.configFilter = newValue.configFilter
        this.showColumnsFilter = newValue.showColumnsFilter
      }
    },
    moreParamsFilters() {
      // get filters from data
      let filters = this.filters

      // modify filters as needed for API

      // add addtional filters
      filters.show = this.formattedShowFilters

      return filters
    },
    showColumnsOptions() {
      let groups = []
      this._.each(this.showFiltersData, function(value) {
        if (value.filter.group) {
          groups.push(value.filter.group)
        }
      })

      const uniqueArray = groups =>
        groups.filter((elem, pos, arr) => arr.indexOf(elem) == pos)
      groups = uniqueArray(groups)

      let options = []
      for (let group of groups) {
        let values = []
        this._.each(this.showFiltersData, function(value, key) {
          if (group == value.filter.group) {
            let option = {
              id: key,
              label: value.filter.label
            }
            values.push(option)
          }
        })
        options.push({
          label: this._.capitalize(group),
          values: values
        })
      }
      return options
    },
    formattedShowFilters() {
      // remove all properties but 'state'
      const filters = this.showFiltersData
      let formattedFilters = {}
      for (var key in filters) {
        formattedFilters[key] = filters[key].filter.state
      }
      return formattedFilters
    },
    showFiltersChunks() {
      // Create chunks for use in dynamically generating rows & columns (each row is a chunk)
      return this._.chunkObj(this.showFiltersData, 3)
    }
  },
  watch: {
    'link.isLoading': function(value) {
      if (value == false) {
        this.isSubmitting = false
        this.isExporting = false
      }
    },
    report: {
      handler: function(value) {
        if (this.persistState) {
          this.$store.dispatch('report/updatePublisherFilters', value)
        }
      },
      deep: true
    },
    'filters.domainMismatch.value': function(newValue) {
      if (newValue) {
        this.showFiltersData.redirect.filter.state = true
        this.showFiltersData.redirectLink.filter.state = true
      }
    },
    'showFiltersData.day.filter.state': function(value) {
      if (value == true) {
        this.showFiltersData.month.filter.state = false
        this.showFiltersData.hour.filter.state = false
      }
    },
    'showFiltersData.month.filter.state': function(value) {
      if (value == true) {
        this.showFiltersData.day.filter.state = false
        this.showFiltersData.hour.filter.state = false
      }
    },
    'showFiltersData.hour.filter.state': function(value) {
      if (value == true) {
        this.showFiltersData.day.filter.state = false
        this.showFiltersData.month.filter.state = false
      }
    },
    sortByFilter: function() {}
  },
  mounted() {
    // Set default sortByFilter
    //this.sortByFilter = [this.sortByOptions.filter(option => option.label == 'Revenue')[0]] || [this.sortByOptions[0]]
    this.filters = this._.merge(this.filters, this.defaultFilters)

    if (this.autoLoad) {
      this.doFilter()
    }

    this.$events.listen('changeReportPage', page => {
      this.changePage(page)
    })
    this.$events.listen('sortReportRows', sortBy => {
      this.updateCachedTableData({ sortBy: sortBy })
      this.loadRows()
    })
    this.saveOriginalState()
    if (this.persistState) {
      this.getPersistedState()
    }
  },
  beforeDestroy() {
    this.$events.remove('changeReportPage')
    this.$events.remove('sortReportRows')
  },
  updated() {},
  methods: {
    changePage(page) {
      this.loadRows({ currentPage: page })
    },
    cleanApolloData(data) {
      let output = data
      output = this.$omitDeep(output, '__typename')
      output = this.$omitDeep(output, 'Symbol(id)')
      output = JSON.parse(JSON.stringify(output))
      return output
    },
    saveOriginalState() {
      this.originalState = this._.cloneDeep(this.report)
    },
    getPersistedState() {
      //Get Persisted Session State
      this.report = this.$store.getters['report/publisherFilters']
        ? this.$store.getters['report/publisherFilters']
        : this.report
    },
    resetFilters() {
      this.report = this._.cloneDeep(this.originalState)
    },
    columnSelected(selectedOption) {
      this.showFiltersData[selectedOption.id].filter.state = true
    },
    columnRemoved(selectedOption) {
      this.showFiltersData[selectedOption.id].filter.state = false
    },
    getCountryOptions(countries) {
      let geoList = countries

      geoList = this._.filter(geoList, function(o) {
        return !(o.id == '?') && !(o.id == 'XX')
      })
      geoList = this._.partition(geoList, function(o) {
        return this._.includes(['US', 'CA', 'AU', 'GB', 'IE', 'NZ'], o.id)
      })

      var geoOptions = [
        {
          label: 'Top Geos',
          group: []
        },
        {
          label: 'Other',
          group: []
        }
      ]

      this._.forEach(geoList[0], function(value) {
        var geo = {
          label: value.id + ' - ' + value.label,
          id: value.id
        }
        geoOptions[0].group.push(geo)
      })

      this._.forEach(geoList[1], function(value) {
        var geo = {
          label: value.id + ' - ' + value.label,
          id: value.id
        }
        geoOptions[1].group.push(geo)
      })

      return geoOptions
    },
    animateShowFiltersBeforeEnter() {},
    animateShowFiltersEnter(el, done) {
      Velocity(el, 'slideDown', {
        duration: 300,
        easing: 'easeOutQuad',
        complete: done
      })
    },
    animateShowFiltersLeave(el, done) {
      Velocity(el, 'slideUp', {
        duration: 300,
        easing: 'easeOutQuad',
        complete: done
      })
    },
    getObjectKeyByIndex(obj, i) {
      var key = Object.keys(obj)[i]
      return key
    },
    getObjectByIndex(obj, i) {
      var key = Object.keys(obj)[i]
      return obj[key]
    },
    forceConditions() {
      // Force campaign to be checked when landing page is checked
      // this.showFiltersData.campaign.filter.state = this.showFiltersData.landingPage.filter.state ? true : this.showFiltersData.campaign.filter.state
    },
    doFilter(options = {}) {
      this.clearValidationErrors()
      this.responseMessage = null
      let getNewVuetableColumns = this.createVuetableColumns(
        this.masterColumnData
      )

      this.updateCachedTableData({
        fields: getNewVuetableColumns,
        filters: this.filters,
        sortOrder: JSON.parse(
          JSON.stringify(
            this.sortByFilter.map(o => {
              return o.value
            })
          )
        ),
        configFilter: this.configFilter
      })
      this.loadRows(options)
    },
    updateCachedTableData({
      fields = this.cachedTableData.fields,
      filters = this.cachedTableData.filters,
      sortOrder = this.cachedTableData.sortOrder,
      configFilter = this.cachedTableData.configFilter
    } = {}) {
      this.cachedTableData.fields = this._.cloneDeep(fields)
      this.cachedTableData.filters = this._.cloneDeep(filters)
      this.cachedTableData.sortOrder = this._.cloneDeep(sortOrder)
      this.cachedTableData.configFilter = this._.cloneDeep(configFilter)
    },
    refreshVuetable() {
      this.link.fields = this.cachedTableData.fields
      this.link.sortOrder = this.cachedTableData.sortOrder
      this.$nextTick(() => {
        this.link.$refs.vuetable.normalizeFields()
        this.link.isLoading = false
        //this.link.$refs.vuetable.refresh();
      })
    },
    tooltipOptions(label, color) {
      return {
        content: label,
        delay: 100,
        classes: ['tooltip-' + color]
      }
    },
    jsonToCsv(json) {
      const items = json
      const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
      const header = Object.keys(items[0])
      let csv = items.map(row =>
        header
          .map(fieldName => JSON.stringify(row[fieldName], replacer))
          .join(',')
      )
      csv.unshift(header.join(','))
      csv = csv.join('\r\n')
      return csv
    },
    saveCsvString(csvString, fileName = 'stats.csv') {
      let blob = new Blob([csvString], { type: 'text/csv' })
      this.saveAs(blob, fileName)
    },
    printColumnsForQuery() {
      const output = this.showColumnsFilter.map(value => value.id).join(' ')
      return output
    },
    loadRows(options = {}) {
      this.activeOptions = options ? options : {}
      let showColumnsFilters = this._.cloneDeep(this.showColumnsFilter)
      this.queryVariables = {
        filters: this.clearUnusedFilters(this.cachedTableData.filters),
        sortOrder: this._.cloneDeep(this.cachedTableData.sortOrder),
        options: this._.cloneDeep(this.cachedTableData.configFilter),
        export: this.activeOptions.type == 'export' ? true : false,
        fields: showColumnsFilters.map(value => value.id)
      }
      this.$apollo.queries.report.refetch()
    },
    clearUnusedFilters(input) {
      let filters = this._.cloneDeep(input)
      for (let filter in filters) {
        if (
          filter == 'dateTimeRange' ||
          filter == 'dateRange' ||
          (filter != 'dateRange' &&
            filter != 'dateTimeRange' &&
            Array.isArray(filters[filter].value) &&
            filters[filter].value.length > 0) ||
          (filter != 'dateRange' &&
            filter != 'dateTimeRange' &&
            !Array.isArray(filters[filter].value) &&
            filters[filter].value)
        ) {
          //
        } else {
          delete filters[filter]
        }
      }
      return filters
    },
    sortByKeys(object) {
      const keys = Object.keys(object)
      const sortedKeys = this._.sortBy(keys)

      return this._.fromPairs(this._.map(sortedKeys, key => [key, object[key]]))
    }
  }
}
</script>
<style>
.disabled-and-faded {
  opacity: 0.4;
  pointer-events: none;
}

.datetimepicker.visible {
  z-index: 99999 !important;
}

.date-time-picker {
  font-size: 0.925rem;
}

.date-time-picker .btn {
  font-weight: 500;
}

.shortcuts-container button.shortcut-button {
  background-color: #f8f8f8;
  margin-bottom: 2px !important;
  border: none !important;
}

.shortcuts-container button.shortcut-button:first-child {
}
</style>
