<template>
  <div class="flex flex-col flex-grow bg-white">
    <Widget>
      <WidgetHeader>Report Communication</WidgetHeader>
      <WidgetBody>
        <div
          class="grid grid-cols-1 xl:grid-cols-2 gap-4"
          style="grid-template-columns: 35% 1fr;"
        >
          <div class="flex flex-col">
            <div
              v-for="contactType in contactTypes"
              :key="contactType"
              class="mr-6 flex items-center mb-1"
            >
              <input
                type="radio"
                name="contactType"
                required
                :id="contactType"
                :value="contactType"
                v-model="contactTypeSelected"
                class="mr-2"
              />
              <label class="capitalize" for="type">{{ contactType }}</label>
            </div>
            <div class="flex items-center mt-3">
              <input
                type="checkbox"
                id="checkbox"
                v-model="followUpRequired"
                class="mr-2"
                :disabled="user.userDetails.priorityStatus == 'rejected'"
              />
              <label for="checkbox" v-if="highPriorityStatus"
                >Follow Up
                <span class="ml-1 text-xs text-red-600 uppercase font-semibold"
                  >required</span
                ></label
              >
              <label for="checkbox" v-else>Follow Up</label>
            </div>
            <div class="relative mt-2">
              <div
                class="absolute top-0 right-0 z-50 pr-1 text-2xl text-red-500"
              >
                *
              </div>
              <VueCtkDateTimePicker
                v-model="followUpDate"
                :onlyDate="true"
                :format="'YYYY-MM-DD'"
                :formatted="'ll'"
                :label="'Follow Up Date'"
                locale="en"
                :range="false"
                :inititale-value="initialDateValue"
                class="mb-2"
                :disabled="!followUpRequired"
              >
              </VueCtkDateTimePicker>
            </div>
          </div>
          <div>
            <textarea
              class="px-3 py-3 w-full rounded mb-2 border block"
              v-model="message"
              placeholder="Type here..."
              @keydown.exact.prevent.enter="addComment()"
              style="height: 96px;"
            />
            <YButton
              color="blue"
              class="block w-full py-2 mt-4"
              @click="addComment"
              :is-loading="isAdding"
              :disabled="!checkForm"
              >Add Annotation</YButton
            >
          </div>
        </div>
      </WidgetBody>
    </Widget>

    <div class="x-comments flex-grow overflow-y-auto py-4">
      <div
        class="comments p-4 grid grid-cols-1"
        v-for="comment in displayedComments"
        :key="comment.id"
      >
        <div class="w-full flex flex-col xl:flex-row">
          <div class="flex flex-row items-start w-full xl:w-1/3">
            <div class="flex flow-row items-center">
              <UserAvatar height="8" :id="comment.commenter.id" />
              <div class="font-bold text-gray-800 truncate ml-2">
                {{ comment.commenter.primaryContact.name }}
              </div>
            </div>
          </div>
          <div class="w-full xl:w-2/3">
            {{ comment.message }}
          </div>
        </div>

        <div class="w-full flex flex-row flex-col xl:flex-row">
          <div class="w-1/3"></div>
          <div class="w-2/3 flex justify-between mt-1">
            <div>
              <Tag :color="tagDefaultColour(comment.tags[0].name)">{{
                comment.tags[0].name
              }}</Tag>
            </div>
            <div class="text-gray-500 text-xs">
              {{ comment.createdAt | luxon('MMM-dd, yyyy') }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 w-100 flex justify-center">
        <YButton :color="'blue'" @click="loadMore" v-if="hasMoreComments"
          >Load More</YButton
        >
      </div>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import CREATE_COMMENT_MUTATION from '@/graphql/Comment/CreateCommentMutation.gql'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import TagDefaultColors from '@/mixins/TagDefaultColours.js'

export default {
  mounted() {
    this.displayInitialComments()
  },
  mixins: [ValidationErrorsMixin, TagDefaultColors],
  components: {},
  props: {
    comments: {
      type: Array
    },
    modelId: {
      type: [Number, String],
      required: true
    },
    modelClass: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: null,
      isAdding: false,
      contactTypes: ['messenger', 'email', 'phone', 'in-person'],
      contactTypeSelected: [],
      followUpDate: this.$luxon(this.user.userDetails.followUpAt),
      initialDateValue: this.$luxon(this.user.userDetails.followUpAt),
      followUpRequired: this.user.userDetails.priorityStatus == 'high',
      initialDisplayCount: 5,
      perLoadCount: 5,
      displayedComments: []
    }
  },
  computed: {
    highPriorityStatus() {
      return this.user.userDetails.priorityStatus == 'high'
    },
    checkForm() {
      return this.contactTypeSelected.length > 0 && this.message
    },
    hasMoreComments() {
      return this.displayedComments.length < this.comments.length
    }
  },
  methods: {
    addComment() {
      this.isAdding = true
      this.$apollo
        .mutate({
          mutation: CREATE_COMMENT_MUTATION,
          variables: {
            input: {
              modelId: this.modelId,
              modelClass: this.modelClass,
              message: this.message,
              commentType: 'lead',
              tags: this.contactTypeSelected
            }
          }
        })
        .then(() => {
          this.isAdding = false
          this.clearValidationErrors()
          this.$toastr.s('', 'Comment Added')
          this.message = null
          this.contactTypeSelected = []
          this.$events.emit('refreshCommunications')
          this.followUpDateUpdate()
        })
        .catch(error => {
          this.isAdding = false
          this.setValidationErrors(error)
        })
    },
    followUpDateUpdate() {
      this.isAdding = true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.modelId,
              userDetails: {
                update: {
                  id: this.user.userDetails.id,
                  followUpAt: this.followUpRequired ? this.followUpDate : null
                }
              }
            }
          }
        })
        .then(() => {
          this.isAdding = false
        })
        .catch(error => {
          console.error(error)
          this.isAdding = false
          this.$toastr.e('Error')
        })
    },
    displayInitialComments() {
      this.displayedComments = this.comments.slice(0, this.initialDisplayCount)
    },
    loadMore() {
      const nextDisplayCount = this.displayedComments.length + this.perLoadCount
      this.displayedComments = this.comments.slice(0, nextDisplayCount)
    }
  }
}
</script>

<style scoped>
.comments:nth-child(odd) {
  @apply bg-gray-100 rounded-lg my-1;
}

.x-message {
  @apply;
}
</style>
