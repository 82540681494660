<template>
  <div></div>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import TASK_SUBSCRIPTION from '@/graphql/Task/TaskSubscription.gql'
import UPLOAD_PAYOUTS_SUBSCRIPTION from '@/graphql/Payout/UploadPayoutsSubscription.gql'

export default {
  apollo: {
    me: {
      query: ME_QUERY,
      result({ data }) {
        this.roles = data.me.roles.map(role => role.name)
        this.initialize()
      }
    }
  },
  data() {
    return {
      roles: [],
      subscriptions: [
        {
          name: 'taskSubscription',
          roles: ['admin'],
          options: {
            query: TASK_SUBSCRIPTION,
            result({ data }) {
              this.$events.fire('pushBackgroundTask', data.taskSubscription)
            }
          }
        },
        {
          name: 'uploadPayoutsSubscription',
          roles: ['admin'],
          options: {
            query: UPLOAD_PAYOUTS_SUBSCRIPTION,
            variables() {
              return {}
            },
            result(result) {
              this.isLoading = false
              this.$events.fire(
                'uploadPayoutsFinished',
                result.data.uploadPayoutsSubscription
              )
              if (result.data.uploadPayoutsSubscription.error) {
                this.$toastr.e(result.data.uploadPayoutsSubscription.error)
              } else {
                this.$toastr.s(result.data.uploadPayoutsSubscription.result)
              }
            }
          }
        }
      ]
    }
  },
  methods: {
    initialize() {
      this.authorizedSubscriptions.forEach(sub =>
        this.$apollo.addSmartSubscription(sub.name, sub.options)
      )
    }
  },
  computed: {
    authorizedSubscriptions() {
      return this.subscriptions.filter(sub =>
        sub.roles.some(role => this.roles.includes(role))
      )
    }
  }
}
</script>

<style></style>
