<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Upsert Payouts
    </WidgetHeader>
    <WidgetBody class="pt-2">
      <form class="row" @submit.prevent="create" ref="form">
        <div
          class="grid grid-cols-2 bg-gray-100 shadow-md rounded-md p-3 gap-x-3 mb-3"
        >
          <div class="relative">
            <div class="absolute top-0 right-0 z-50 pr-1 text-2xl text-red-500">
              *
            </div>
            <VueCtkDateTimePicker
              v-model="startAt"
              :onlyDate="true"
              :format="'YYYY-MM-DD'"
              :formatted="'ll'"
              :label="'Start Date'"
              locale="en"
              :range="false"
              :inititale-value="dateRange"
              class="mb-2"
            >
            </VueCtkDateTimePicker>
          </div>
          <div>
            <Superselect
              title="Payout Level"
              v-model="payout.level"
              :multiple="false"
              :options="payoutTypeOptions"
              :allow-empty="false"
              :required="true"
              class="mb-2"
            ></Superselect>
          </div>
          <div class="flex flex-row justify-center gap-2">
            <div class="mb-2 w-1/2">
              <Superselect
                title="Payout Type"
                v-model="payout.type"
                :multiple="false"
                :options="typeOptions"
                :allow-empty="false"
                placeholder="Select One"
                :required="true"
              ></Superselect>
            </div>
            <div class="mb-2 w-1/2">
              <Superselect
                v-if="payout.type == 'CPA'"
                title="Conversion Type"
                v-model="payout.conversionType"
                :multiple="false"
                :options="conversionTypeOptions"
                :required="true"
              ></Superselect>
            </div>
          </div>
          <div v-if="payout.level == 'Campaign' || !payout.level"></div>
          <div v-if="payout.level == 'Redirect'">
            <Superselect
              :disabled="payout.level != 'Redirect'"
              title="Redirects"
              v-model="payout.redirects"
              :multiple="true"
              :options="redirects ? redirects.data : []"
              track-by="id"
              label="label"
              class="mb-2"
              :close-on-select="false"
            ></Superselect>
          </div>
          <div v-if="payout.level == 'Source'">
            <YInput
              :disabled="payout.level != 'Source'"
              label="Source Id"
              tooltip="Comma seperate values"
              v-model="payout.sourceId"
            >
            </YInput>
          </div>

          <div
            class="span-col-2 h-5 m-1 mt-3 uppercase text-xs font-bold text-gray-900"
          >
            ADD ROWS
          </div>
          <div class="span-col-2 h-5"></div>
          <div>
            <Superselect
              title="Countries"
              v-model="payout.geoCountry"
              :options="countries"
              :modifiers="countryModifiers"
              :close-on-select="false"
              :required="true"
              track-by="code"
              label="label"
              class=""
            />
          </div>
          <div
            class="flex flex-row bgray-100 justify-between items-center gap-2 "
          >
            <div class="flex-grow">
              <YInput
                v-model="payout.value"
                :label="valueLabel"
                :required="true"
              ></YInput>
            </div>
            <div class="flex-grow">
              <YInput v-model="payout.floorValue" :label="floorLabel"></YInput>
            </div>
            <div>
              <YButton
                color="inverse"
                @click="addNewPayoutRow($event)"
                :disabled="requiredFields"
                ><Icon name="plus"
              /></YButton>
            </div>
          </div>
        </div>

        <YTable :data="payoutDetails" class="mt-8">
          <template slot="header-row">
            <th>Level</th>
            <th></th>
            <th>Country</th>
            <th>Value</th>
            <th>Floor</th>
            <th v-if="!multipleEditSelected">Type</th>
            <th>Conversion</th>
            <th v-if="multipleEditSelected">Pause</th>
            <th></th>
          </template>
          <template slot="row" slot-scope="props">
            <td>
              {{ props.rowData.level.capitalize() }}
            </td>

            <td>
              <div v-if="props.rowData.sourceId">
                (<router-link
                  :to="{
                    name: 'Source',
                    params: { id: props.rowData.sourceId }
                  }"
                  target="_blank"
                  class="link"
                  >{{ props.rowData.sourceId }} </router-link
                >)
              </div>
              <div
                class="w-11/12"
                v-if="props.rowData.redirect && !props.rowData.sourceId"
              >
                <p
                  v-for="(item, index) in props.rowData.redirect"
                  :key="index"
                  class="inline"
                >
                  <router-link
                    :to="{
                      name: 'Redirect',
                      params: { id: item.id }
                    }"
                    target="_blank"
                    class="link"
                  >
                    {{ item.id }}</router-link
                  >
                  <span v-if="index != props.rowData.redirect.length - 1"
                    >,</span
                  >
                </p>
              </div>
            </td>
            <td>
              <div class="w-11/12">
                <p
                  v-for="(item, index) in props.rowData.country"
                  :key="index"
                  class="inline"
                >
                  {{ item.code ? item.code : 'All Geos' }}
                  <span v-if="index != props.rowData.country.length - 1"
                    >,</span
                  >
                </p>
              </div>
            </td>
            <td>
              <span v-if="!isRevShareType(props.rowData.type)">$</span>
              <div
                contenteditable
                ref="contentEditable"
                class="focus:bg-indigo-200 inline p-2"
                @focus="focusEvent($event.target)"
                @blur="valueEdit(props.rowData, $event)"
                @keydown.enter="$event.target.blur()"
              >
                {{ props.rowData.value }}
              </div>

              <span v-if="isRevShareType(props.rowData.type)">%</span>
            </td>
            <td>
              <span v-if="!isRevShareType(props.rowData.type)">$</span>
              <div
                contenteditable
                ref="contentFloorEditable"
                class="focus:bg-blue-200 inline p-2"
                @focus="focusEvent($event.target)"
                @blur="floorEdit(props.rowData, $event)"
                @keydown.enter="$event.target.blur()"
              >
                {{ props.rowData.floorValue }}
              </div>
              <span v-if="isRevShareType(props.rowData.type)">%</span>
            </td>
            <td v-if="!multipleEditSelected">{{ props.rowData.type }}</td>
            <td>
              {{
                props.rowData.conversionType
                  ? props.rowData.conversionType
                  : null
              }}
            </td>
            <td v-if="multipleEditSelected">
              <ToggleButton
                :value="toggleStatus(props.rowData.isPaused)"
                :color="{
                  checked: '#41b883',
                  unchecked: '#888888',
                  disabled: '#CCCCCC'
                }"
                :labels="{ checked: 'Active', unchecked: 'Paused' }"
                :width="68"
                @change="updateStatus($event, props.rowData)"
              ></ToggleButton>
            </td>
            <td style="width:20px;">
              <div @click="deleteRow(props.rowData)">
                <Icon
                  name="trashCan"
                  :size="4"
                  class="text-red-600"
                  v-tooltip="'Delete Row'"
                />
              </div>
            </td>
          </template>
        </YTable>
      </form>
    </WidgetBody>

    <WidgetFooter class="bg-gray-100 flex justify-between p-4 items-center">
      <div class="ml-1">
        <YCheckbox v-model="sendEmails">
          <template v-slot:label>
            <span class="text-gray-600 font-semibold uppercase text-xs my-1">
              Send Publisher Emails
            </span>
          </template>
        </YCheckbox>
      </div>
      <Spinner v-if="$apollo.queries.sources.loading" :size="8" color="blue" />
      <YButton
        :is-loading="isSubmitting"
        type="button"
        class="btn-blue"
        @click="create"
        :disabled="this.payoutDetails.length == 0"
      >
        {{ multipleEditSelected ? 'Upsert Payout' : 'Create Payouts' }}
      </YButton>
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import UPSERT_PAYOUTS_MUTATION from '@/graphql/Payout/UpsertPayoutsMutation.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import CONVERSION_TYPES_QUERY from '@/graphql/ConversionType/ConversionTypesQuery.gql'
import PAYOUTS_QUERY from '@/graphql/Payout/PayoutsQuery.gql'
import SOURCES_QUERY from '@/graphql/Source/SourcesQuery.gql'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  mixins: [ValidationErrorsMixin],
  components: { ToggleButton },
  mounted() {},
  updated() {},
  props: {
    userId: {
      type: Number,
      required: false
    },
    campaignId: {
      type: Number,
      required: false
    },
    redirectId: {
      type: Number,
      required: false
    },
    type: {
      type: String,
      default: 'CPA'
    },
    selectedRows: {
      type: Array
    },
    payoutType: {
      type: String
    },
    conversionType: {
      type: String
    }
  },

  apollo: {
    conversionTypes: {
      query: CONVERSION_TYPES_QUERY,
      variables() {
        return {
          first: 999
        }
      },
      result({ data }) {
        this.conversionTypeOptions = data?.conversionTypes.data
          ?.filter(conversionType => conversionType.slug != 'default')
          ?.map(object => object.name)
      }
    },
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    },
    redirects: {
      query: REDIRECT_OPTIONS_QUERY,
      update: data => data.redirectOptions,
      variables() {
        return {
          filters: {
            campaignId: { value: this.campaignId }
          },
          first: 1000
        }
      }
    },
    payouts: {
      query: PAYOUTS_QUERY,
      variables() {
        return {
          first: 1000,
          filters: {
            id: {
              value: this.selectedRows
            },
            isFuture: false
          }
        }
      },
      result(data) {
        data ? this.convertMultipleSelected(data.data.payouts.data) : undefined
      },
      skip() {
        return !this.multipleEditSelected
      },
      fetchPolicy: 'network-only'
    },
    sources: {
      query: SOURCES_QUERY,
      variables() {
        return {
          input: {
            ids: this.sourceId,
            campaignId: this.campaignId
          }
        }
      },
      skip() {
        return this.skipQuery
      },
      result(data) {
        data ? this.createSource() : null
      }
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      conversionTypeOptions: [],
      payout: {
        level: 'Campaign',
        type: this.type,
        userId: this.userId,
        sourceId: this.sourceId,
        redirectId: this.redirectId,
        campaignId: this.campaignId,
        geoCountry: [],
        redirects: []
      },
      typeOptions: ['PPM', 'CPA', 'Revshare'],
      payoutTypeOptions: ['Campaign', 'Redirect', 'Source'],
      isSubmitting: false,
      errors: {},
      valueLimit: 300,
      sendEmails: false,
      payoutDetails: [],
      startAt: this.$dateTime.local().toFormat('yyyy-LL-dd'),
      firstLoad: true,
      dateRange: this.$dateTime.local().toFormat('yyyy-LL-dd'),
      skipQuery: true,
      disableUpsert: false,
      countryModifiers: {
        edit: false
      }
    }
  },
  watch: {
    payout: {
      handler(input) {
        if (input.value > this.valueLimit && input.type === 'CPA') {
          this.$toastr.Add({
            type: 'error',
            title: 'High Value Entered',
            msg: 'Are your sure you entered the correct amount?',
            position: 'toast-bottom-right',
            timeout: '7000',
            progressbar: false
          })
        }
        if (input.conversionType || input.geoCountry) {
          this.duplicatesSelected(input)
        }
      },
      deep: true
    }
  },
  computed: {
    requiredFields() {
      if (this.payout.type !== 'CPA') {
        return (
          this.payout.geoCountry.length == 0 ||
          !this.payout.value ||
          this.disableUpsert
        )
      } else {
        return (
          this.payout.geoCountry.length == 0 ||
          !this.payout.value ||
          !this.payout.conversionType ||
          this.disableUpsert
        )
      }
    },
    multiSelectedCountryOptions() {
      let selectedCountries = this.payoutDetails.map(
        item => item.country[0].code
      )
      let filteredCountries = this.countries.filter(
        country => !selectedCountries.includes(country.code)
      )
      return filteredCountries
    },
    multipleEditSelected() {
      return this.selectedRows.length > 0
    },
    valueLabel() {
      let output
      if (this.payout.type && this.payout.type.toLowerCase() == 'revshare') {
        output = 'Value %'
      } else {
        output = 'Value $'
      }
      return output
    },
    floorLabel() {
      let output
      if (this.payout.type && this.payout.type.toLowerCase() == 'revshare') {
        output = 'Floor %'
      } else {
        output = 'Floor $'
      }
      return output
    },
    payoutTypeString() {
      return this.payouts.data[0].type
    }
  },
  methods: {
    isRevShareType(value) {
      return value.toLowerCase() == 'revshare'
    },
    duplicatesSelected(input) {
      let conversionType = input.conversionType
      let geoCountries = input.geoCountry.map(country => country.code)

      let redirect =
        input.redirects.length > 0
          ? input.redirects.map(redirect => redirect.id)
          : undefined

      let results =
        this.payoutDetails.filter(payout => {
          return (
            payout.level == input.level.toLowerCase() &&
            payout.conversionType == conversionType &&
            payout.redirectId == redirect &&
            geoCountries.includes(payout.country[0].code)
          )
        }).length > 0

      if (results) {
        this.disableUpsert = true
        this.$toastr.e(
          'You have already selected this country and conversion type in the table. Please edit selection inline.',
          'Duplicates Found'
        )
      } else {
        this.disableUpsert = false
      }
    },
    focusEvent(e) {
      return (e.textContent = e.textContent.trim())
    },
    toggleStatus(status) {
      return !status
    },
    updateStatus(status, props) {
      let newStatus = !status.value
      let selectedCountry = props.country[0].code

      this.payoutDetails.forEach(item => {
        let countryObject = item.country[0]
        if (countryObject.code == selectedCountry) {
          item.isPaused = newStatus
        }
      })
    },
    valueEdit(props, event) {
      let newValue = parseFloat(event.target.textContent)
      let selectedCountry = props.country[0].code
      let selectedConversionType = props.conversionType

      this.payoutDetails.forEach(item => {
        let countryObject = item.country[0]

        if (
          countryObject.code == selectedCountry &&
          item.conversionType == selectedConversionType
        ) {
          item.value = newValue
        }
      })
    },
    floorEdit(props, event) {
      let newValue = parseFloat(event.target.textContent)
      let selectedCountry = props.country[0].code

      this.payoutDetails.forEach(value => {
        let countryObject = value.country[0]
        if (countryObject.code == selectedCountry) {
          value.floorValue = newValue
        }
      })
    },
    deleteRow(props) {
      this.payoutDetails = this.payoutDetails.filter(payout => payout !== props)
    },
    resetPayoutRow() {
      this.payout.geoCountry = []
      this.payout.redirects = []
      this.payout.value = ''
      this.payout.level = ''
      this.payout.floorValue = null
      this.payout.sourceId = ''
      this.type = 'CPA'
      this.payout.type = 'CPA'
      this.payout.level = 'Campaign'
      this.payout.conversionType = null
      this.payout.sourceId = null
    },
    convertMultipleSelected(data) {
      if (this.firstLoad) {
        data.forEach(item => {
          this.payoutDetails.push({
            conversionType: item.conversionType
              ? item.conversionType.name
              : null,
            country: [
              {
                code: item.country ? item.country.code : undefined,
                label: item.country ? item.country.label : undefined
              }
            ],
            floorValue: item.floorValue
              ? parseFloat(item.floorValue)
              : undefined,
            isPaused: item.pausedAt ? true : undefined,
            startAt: item.startAt,
            type: item.type,
            value: parseFloat(item.value),
            level: item.level.toLowerCase(),
            redirect: item.redirect ? [item.redirect] : undefined,
            redirectId: item.redirectId ? item.redirectId : undefined,
            campaignId: item.campaignId ? item.campaignId : undefined,
            sourceId: item.sourceId ? item.sourceId : undefined
          })
        })

        this.firstLoad = false
      }
    },
    addNewPayoutRow(e) {
      if (this.requiredFields) {
        e.preventDefault()
        this.$toastr.e('Some payout fields are missing', 'Error')
      } else if (this.payout.level == 'Source') {
        e.preventDefault()
        this.getSource()
      } else {
        this.payoutDetails.push({
          campaignId:
            this.payout.level == 'Campaign' ? this.campaignId : undefined,
          conversionType: this.payout.conversionType,
          country: this.payout.geoCountry,
          floorValue: this.payout.floorValue
            ? parseFloat(this.payout.floorValue)
            : undefined,
          level: this.payout.level,
          redirect:
            this.payout.level == 'Redirect' ? this.payout.redirects : undefined,
          startAt: this.startAt ? this.startAt : undefined,
          type: this.payout.type,
          value: parseFloat(this.payout.value)
        })

        this.resetPayoutRow()
      }
    },

    create() {
      let mutations = []

      this.payoutDetails.forEach(payout => {
        let payoutLevel = payout.level.toLowerCase()
        let startDate =
          this.startAt == this.$dateTime.local().toFormat('yyyy-LL-dd')
            ? null
            : this.startAt
        let conversionType = payout.conversionType
          ? payout.conversionType
          : undefined

        if (payout.country.length > 1 && payoutLevel != 'redirect') {
          payout.country.forEach(item =>
            mutations.push({
              ...payout,
              startAt: startDate,
              countryCode: item.code,
              redirectId:
                payoutLevel == 'campaign'
                  ? item.redirectId
                  : payoutLevel == 'source'
                  ? payout.redirectId
                  : undefined,
              conversionType: conversionType
            })
          )
        } else if (payoutLevel != 'redirect') {
          mutations.push({
            ...payout,
            startAt: startDate,
            countryCode: payout.country[0].code,
            redirectId: payout.redirectId ? payout.redirectId : undefined,
            conversionType: conversionType
          })
        }

        if (payoutLevel == 'redirect') {
          payout.redirect.forEach(redirect => {
            if (payout.country.length > 1) {
              payout.country.forEach(item =>
                mutations.push({
                  ...payout,
                  startAt: startDate,
                  redirectId: redirect.id,
                  countryCode: item.code,
                  conversionType: conversionType
                })
              )
            } else {
              mutations.push({
                ...payout,
                startAt: startDate,
                redirectId: redirect.id,
                countryCode: payout.country[0].code,
                conversionType: conversionType
              })
            }
          })
        }
      })

      mutations.forEach(mutate => delete mutate.redirect)
      mutations.forEach(mutate => delete mutate.country)

      this.upsertPayout(mutations)
    },
    getSource() {
      this.sourceId = this.payout.sourceId.replace(/\s/g, '').split(',')
      this.$apollo.queries.sources.skip = false
      this.$apollo.queries.sources.refetch()
    },
    createSource() {
      if (this.sources.length > 0) {
        this.sources.forEach(source => {
          this.payoutDetails.push({
            campaignId: this.campaignId,
            conversionType: this.payout.conversionType,
            country: this.payout.geoCountry,
            floorValue: this.payout.floorValue
              ? parseFloat(this.payout.floorValue)
              : undefined,
            level: this.payout.level,
            redirectId: source.redirectId,
            startAt: this.startAt ? this.startAt : undefined,
            type: this.payout.type,
            value: parseFloat(this.payout.value),
            sourceId: source.id
          })
        })

        this.resetPayoutRow()
        this.$apollo.queries.sources.skip = true
      } else {
        this.$toastr.e(
          'No source exists for this campaign.',
          'Source Not Found'
        )
        this.$apollo.queries.sources.skip = true
      }
    },
    upsertPayout(payouts) {
      this.isSubmitting = true
      this.clearValidationErrors()

      return this.$apollo
        .mutate({
          mutation: UPSERT_PAYOUTS_MUTATION,
          variables: {
            input: {
              payouts: payouts,
              sendEmails: this.sendEmails
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$emit('close')
          this.$events.emit('refetchPayouts')
          this.$tabEvent.emit('refetchPayouts')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
          console.log(error)
        })
    }
  }
}
</script>

<style scoped></style>
