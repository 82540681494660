export const state = {
  filters: {
    campaign: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Campaign',
      type: 'input'
    },
    verticals: {
      value: null,
      modifiers: {
        exclude: false
      }
    },
    countries: {
      value: null,
      title: 'Countries',
      type: 'select',
      multiple: false,
      optionLabelKey: 'label',
      trackByKey: 'code'
    },
    devices: {
      value: null,
      modifiers: {
        matchType: 'exact'
      }
    },
    allowed: {
      value: null,
      modifiers: {
        exclude: false
      }
    },
    status: {
      value: null,
      modifiers: {
        matchType: 'exact'
      }
    },
    offerType: {
      value: null,
      modifiers: {
        matchType: 'exact'
      }
    },
    campaignBrowser: {
      value: null,
      type: 'select',
      trackByKey: 'name'
    },
    campaignOS: {
      value: null,
      type: 'select',
      trackByKey: 'name'
    },
    conversionType: {
      value: null,
      type: 'select',
      trackByKey: 'name'
    }
  },
  orderBy: [
    {
      column: 'created_at',
      order: 'DESC'
    }
  ]
}
