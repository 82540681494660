var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('Superselect', {
          staticStyle: {
            "min-width": "8rem"
          },
          attrs: {
            "options": _vm.helpers,
            "title": "Helper",
            "multiple": false,
            "allow-empty": false
          },
          on: {
            "close": _vm.onHelperChange
          },
          model: {
            value: _vm.input.helperClass,
            callback: function ($$v) {
              _vm.$set(_vm.input, "helperClass", $$v);
            },
            expression: "input.helperClass"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v("Revshare Pull Helper Info ")]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "x-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('YInput', {
    attrs: {
      "label": "Url"
    },
    model: {
      value: _vm.input.url,
      callback: function ($$v) {
        _vm.$set(_vm.input, "url", $$v);
      },
      expression: "input.url"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Click Column Name"
    },
    model: {
      value: _vm.data.clickColumnName,
      callback: function ($$v) {
        _vm.$set(_vm.data, "clickColumnName", $$v);
      },
      expression: "data.clickColumnName"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Revenue Column Name"
    },
    model: {
      value: _vm.data.revenueColumnName,
      callback: function ($$v) {
        _vm.$set(_vm.data, "revenueColumnName", $$v);
      },
      expression: "data.revenueColumnName"
    }
  }), _vm.input.helperClass == 'CellxpertHelper' || _vm.input.helperClass == 'OxTrafficHelper' ? _c('YInput', {
    attrs: {
      "label": "Api User Name"
    },
    model: {
      value: _vm.data.apiUserName,
      callback: function ($$v) {
        _vm.$set(_vm.data, "apiUserName", $$v);
      },
      expression: "data.apiUserName"
    }
  }) : _vm._e(), _vm.input.helperClass == 'CellxpertHelper' ? _c('YInput', {
    attrs: {
      "label": "User Password"
    },
    model: {
      value: _vm.data.apiPassword,
      callback: function ($$v) {
        _vm.$set(_vm.data, "apiPassword", $$v);
      },
      expression: "data.apiPassword"
    }
  }) : _vm._e(), _vm.input.helperClass == 'MapHelper' || _vm.input.helperClass == 'OxTrafficHelper' ? _c('YInput', {
    attrs: {
      "label": "Api Key"
    },
    model: {
      value: _vm.data.apiKey,
      callback: function ($$v) {
        _vm.$set(_vm.data, "apiKey", $$v);
      },
      expression: "data.apiKey"
    }
  }) : _vm._e(), _vm.input.helperClass == 'MapHelper' ? _c('YInput', {
    attrs: {
      "label": "User Name"
    },
    model: {
      value: _vm.data.userName,
      callback: function ($$v) {
        _vm.$set(_vm.data, "userName", $$v);
      },
      expression: "data.userName"
    }
  }) : _vm._e(), _vm.input.helperClass == 'MyAffiliatesHelper' ? _c('YInput', {
    attrs: {
      "label": "Client Identifier"
    },
    model: {
      value: _vm.data.clientIdentifier,
      callback: function ($$v) {
        _vm.$set(_vm.data, "clientIdentifier", $$v);
      },
      expression: "data.clientIdentifier"
    }
  }) : _vm._e(), _vm.input.helperClass == 'MyAffiliatesHelper' ? _c('YInput', {
    attrs: {
      "label": "Client Secret"
    },
    model: {
      value: _vm.data.clientSecret,
      callback: function ($$v) {
        _vm.$set(_vm.data, "clientSecret", $$v);
      },
      expression: "data.clientSecret"
    }
  }) : _vm._e(), _c('Superselect', {
    staticStyle: {
      "min-width": "8rem"
    },
    attrs: {
      "options": _vm.statuses,
      "title": "Status",
      "multiple": false,
      "allow-empty": false
    },
    model: {
      value: _vm.input.status,
      callback: function ($$v) {
        _vm.$set(_vm.input, "status", $$v);
      },
      expression: "input.status"
    }
  })], 1)]), _c('WidgetFooter', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveHelper,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveHelper.apply(null, arguments);
      }
    }
  }, [_vm._v("Update")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }