var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col flex-grow bg-white"
  }, [_c('Widget', [_c('WidgetHeader', [_vm._v("Report Communication")]), _c('WidgetBody', [_c('div', {
    staticClass: "grid grid-cols-1 xl:grid-cols-2 gap-4",
    staticStyle: {
      "grid-template-columns": "35% 1fr"
    }
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_vm._l(_vm.contactTypes, function (contactType) {
    return _c('div', {
      key: contactType,
      staticClass: "mr-6 flex items-center mb-1"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.contactTypeSelected,
        expression: "contactTypeSelected"
      }],
      staticClass: "mr-2",
      attrs: {
        "type": "radio",
        "name": "contactType",
        "required": "",
        "id": contactType
      },
      domProps: {
        "value": contactType,
        "checked": _vm._q(_vm.contactTypeSelected, contactType)
      },
      on: {
        "change": function ($event) {
          _vm.contactTypeSelected = contactType;
        }
      }
    }), _c('label', {
      staticClass: "capitalize",
      attrs: {
        "for": "type"
      }
    }, [_vm._v(_vm._s(contactType))])]);
  }), _c('div', {
    staticClass: "flex items-center mt-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.followUpRequired,
      expression: "followUpRequired"
    }],
    staticClass: "mr-2",
    attrs: {
      "type": "checkbox",
      "id": "checkbox",
      "disabled": _vm.user.userDetails.priorityStatus == 'rejected'
    },
    domProps: {
      "checked": Array.isArray(_vm.followUpRequired) ? _vm._i(_vm.followUpRequired, null) > -1 : _vm.followUpRequired
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.followUpRequired,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.followUpRequired = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.followUpRequired = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.followUpRequired = $$c;
        }
      }
    }
  }), _vm.highPriorityStatus ? _c('label', {
    attrs: {
      "for": "checkbox"
    }
  }, [_vm._v("Follow Up "), _c('span', {
    staticClass: "ml-1 text-xs text-red-600 uppercase font-semibold"
  }, [_vm._v("required")])]) : _c('label', {
    attrs: {
      "for": "checkbox"
    }
  }, [_vm._v("Follow Up")])]), _c('div', {
    staticClass: "relative mt-2"
  }, [_c('div', {
    staticClass: "absolute top-0 right-0 z-50 pr-1 text-2xl text-red-500"
  }, [_vm._v(" * ")]), _c('VueCtkDateTimePicker', {
    staticClass: "mb-2",
    attrs: {
      "onlyDate": true,
      "format": 'YYYY-MM-DD',
      "formatted": 'll',
      "label": 'Follow Up Date',
      "locale": "en",
      "range": false,
      "inititale-value": _vm.initialDateValue,
      "disabled": !_vm.followUpRequired
    },
    model: {
      value: _vm.followUpDate,
      callback: function ($$v) {
        _vm.followUpDate = $$v;
      },
      expression: "followUpDate"
    }
  })], 1)], 2), _c('div', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "px-3 py-3 w-full rounded mb-2 border block",
    staticStyle: {
      "height": "96px"
    },
    attrs: {
      "placeholder": "Type here..."
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.addComment();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.message = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('YButton', {
    staticClass: "block w-full py-2 mt-4",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isAdding,
      "disabled": !_vm.checkForm
    },
    on: {
      "click": _vm.addComment
    }
  }, [_vm._v("Add Annotation")])], 1)])])], 1), _c('div', {
    staticClass: "x-comments flex-grow overflow-y-auto py-4"
  }, [_vm._l(_vm.displayedComments, function (comment) {
    return _c('div', {
      key: comment.id,
      staticClass: "comments p-4 grid grid-cols-1"
    }, [_c('div', {
      staticClass: "w-full flex flex-col xl:flex-row"
    }, [_c('div', {
      staticClass: "flex flex-row items-start w-full xl:w-1/3"
    }, [_c('div', {
      staticClass: "flex flow-row items-center"
    }, [_c('UserAvatar', {
      attrs: {
        "height": "8",
        "id": comment.commenter.id
      }
    }), _c('div', {
      staticClass: "font-bold text-gray-800 truncate ml-2"
    }, [_vm._v(" " + _vm._s(comment.commenter.primaryContact.name) + " ")])], 1)]), _c('div', {
      staticClass: "w-full xl:w-2/3"
    }, [_vm._v(" " + _vm._s(comment.message) + " ")])]), _c('div', {
      staticClass: "w-full flex flex-row flex-col xl:flex-row"
    }, [_c('div', {
      staticClass: "w-1/3"
    }), _c('div', {
      staticClass: "w-2/3 flex justify-between mt-1"
    }, [_c('div', [_c('Tag', {
      attrs: {
        "color": _vm.tagDefaultColour(comment.tags[0].name)
      }
    }, [_vm._v(_vm._s(comment.tags[0].name))])], 1), _c('div', {
      staticClass: "text-gray-500 text-xs"
    }, [_vm._v(" " + _vm._s(_vm._f("luxon")(comment.createdAt, 'MMM-dd, yyyy')) + " ")])])])]);
  }), _c('div', {
    staticClass: "mt-4 w-100 flex justify-center"
  }, [_vm.hasMoreComments ? _c('YButton', {
    attrs: {
      "color": 'blue'
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("Load More")]) : _vm._e()], 1)], 2), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }