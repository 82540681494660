import { render, staticRenderFns } from "./CampaignDetails.vue?vue&type=template&id=4f01df45&scoped=true"
import script from "./CampaignDetails.vue?vue&type=script&lang=js"
export * from "./CampaignDetails.vue?vue&type=script&lang=js"
import style0 from "./CampaignDetails.vue?vue&type=style&index=0&id=4f01df45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f01df45",
  null
  
)

export default component.exports