var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$apollo.queries.me.loading ? _c('div', {
    staticClass: "my-4"
  }, [_c('Spinner', {
    attrs: {
      "color": "green",
      "size": 10
    }
  })], 1) : _c('div', {
    staticClass: "x-terms bg-gray-100"
  }, [_c('div', {
    staticClass: "w-10/12 mx-auto"
  }, [_c('pdf-viewer', {
    staticClass: "mb-4",
    attrs: {
      "url": "https://ytrack-documents.s3.us-east-1.amazonaws.com/ytz-terms-of-service.pdf",
      "height": _vm.pdfHeight
    }
  })], 1), _c('div', [_vm.me.userDetails.agreedToTerms ? _c('p', {
    staticClass: "mb-4"
  }, [_c('Icon', {
    staticClass: "mr-2 inline text-green-500",
    attrs: {
      "name": "check",
      "size": 4
    }
  }), _vm._v("Terms accepted "), _vm.me.userDetails.agreedToTermsIp ? [_vm._v(" by "), _c('span', {
    staticClass: "font-semibold text-blue-700"
  }, [_vm._v(_vm._s(_vm.me.userDetails.agreedToTermsIp))]), _vm._v(" at "), _c('span', {
    staticClass: "font-semibold text-blue-700"
  }, [_vm._v(_vm._s(_vm.me.userDetails.agreedToTermsAt))])] : _vm._e()], 2) : _vm._e(), !_vm.me.userDetails.agreedToTerms ? _c('form', [_c('YCheckbox', {
    staticClass: "flex items-center mb-2",
    attrs: {
      "id": "agreed",
      "label": `I agree to the Terms & Conditions`
    },
    model: {
      value: _vm.accepted,
      callback: function ($$v) {
        _vm.accepted = $$v;
      },
      expression: "accepted"
    }
  }), _c('div', {
    staticClass: "flex"
  }, [_c('YInput', {
    staticClass: "flex-grow",
    attrs: {
      "id": "name",
      "label": "Please sign your name...",
      "inputClass": " border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.signature,
      callback: function ($$v) {
        _vm.signature = $$v;
      },
      expression: "signature"
    }
  }), _c('YButton', {
    staticClass: "flex-shrink rounded-l-none",
    attrs: {
      "color": "blue",
      "isLoading": _vm.submitting,
      "disabled": _vm.buttonDisabled
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v("Submit")])], 1)], 1) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }