var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex"
  }, [_c('YAlert', {
    staticClass: "w-full",
    style: _vm.unsavedChanges ? 'opacity: 1' : 'opacity: 0',
    attrs: {
      "type": "warning",
      "color": "blue",
      "allow-close": false
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_vm.icon ? _c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "alert",
      "size": "40"
    }
  }) : _vm._e(), _c('span', [_vm._v("You have unsaved changes")])], 1), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSaving,
      "color": "blue"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)])], 1), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute pt-16 w-full"
  }, [_vm.loading ? _c('Spinner', {
    attrs: {
      "size": 16,
      "color": "blue"
    }
  }) : _vm._e()], 1), _c('VueSchedule', {
    class: [_vm.loading ? 'disable' : ''],
    attrs: {
      "day-table": _vm.dayTable,
      "bg-active": _vm.bgActive,
      "bg-hover": _vm.bgHover,
      "bg": _vm.bg
    },
    model: {
      value: _vm.schedule,
      callback: function ($$v) {
        _vm.schedule = $$v;
      },
      expression: "schedule"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }