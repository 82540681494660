var render = function render(){
  var _this$paymentSummary;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "grid grid-cols-3 gap-6 mb-6"
  }, [_vm.user.isQuarantined && _vm.user.caps.length > 0 ? _c('QuarantineLevelCard', {
    staticClass: "col-span-3",
    attrs: {
      "level": _vm.user.quarantine.level,
      "campaign-subscribe-count": _vm.user.quarantine.campaignSubscribeCount,
      "payout": _vm.user.caps ? _vm.user.caps.filter(function (cap) {
        return cap.type == 'payout' && cap.frequency == 'monthly';
      })[0].currentValue : 0
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "grid grid-cols-4 gap-6"
  }, [_c('div', {
    staticClass: "col-span-3 flex gap-6"
  }, [_vm.featuredCampaigns.length > 1 ? _c('div', {
    staticClass: "w-4/12 max-w-sm flex flex-col rounded-md p-6 relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-800"
  }, [_c('div', {
    staticClass: "absolute rounded-full bg-gradient-to-r from-blue-500 to-blue-900",
    staticStyle: {
      "right": "-8rem",
      "top": "-8rem",
      "width": "16rem",
      "height": "16rem"
    }
  }), _c('div', {
    staticClass: "absolute rounded-full bg-gradient-to-r from-blue-500 to-blue-300",
    staticStyle: {
      "left": "4rem",
      "top": "2.5rem",
      "width": "1.5rem",
      "height": "1.5rem"
    }
  }), _vm._m(0)]) : _vm._e(), _vm.$apollo.queries.publisherCampaigns.loading ? _c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 8
    }
  }) : _vm._e(), _vm.featuredCampaigns.length > 1 ? _c('div', {
    staticClass: "featuredCampaigns w-8/12 rounded-md border"
  }, [_c('agile', {
    key: _vm.featuredCampaigns.length,
    attrs: {
      "autoplay": true,
      "pauseOnHover": true,
      "navButtons": false,
      "autoplaySpeed": 9000
    }
  }, _vm._l(_vm.featuredCampaigns, function (feature) {
    return _c('span', {
      key: feature.id
    }, [_c('FeaturedCampaign', {
      attrs: {
        "data": feature
      }
    })], 1);
  }), 0)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "col-span-1 flex flex-col"
  }, [_c('div', {
    staticClass: "flex-shrink mb-2"
  }, [_c('AccountManager')], 1), _c('div', {
    staticStyle: {
      "padding": "4px"
    }
  }), _c('div', {
    staticClass: "flex-grow rounded-md p-3 text-gray-900 border flex items-center",
    staticStyle: {
      "border-top": "4px solid #ef4565"
    }
  }, [_c('span', {
    staticClass: "block"
  }, [_vm._v(" 🎉 YTZ is happy to offer our publishers a referral program. Grab your "), _c('router-link', {
    staticClass: "link font-bold",
    attrs: {
      "to": {
        name: 'Publisher Account',
        params: {
          tab: 'referrals'
        }
      }
    }
  }, [_vm._v(" referral link ")]), _vm._v(" and share! ")], 1)])]), _c('div', {
    staticClass: "col-span-3"
  }, [_c('Reports', {
    attrs: {
      "auto-load": true,
      "show-filters": false,
      "default-filters": _vm.reportFilters
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function () {
        return [_c('span', {
          staticClass: "widget-title"
        }, [_vm._v("Daily Summary")])];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('router-link', {
          staticClass: "link text-uppercase text-xs",
          attrs: {
            "to": {
              name: 'Publisher Reports'
            }
          }
        }, [_vm._v(" VIEW REPORTS ")])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "col-span-1"
  }, [_c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_c('div', {
    staticClass: "uppercase text-xs font-semibold text-gray-800 flex justify-between"
  }, [_vm._v(" Outstanding Balance "), _c('Icon', {
    staticClass: "text-teal-500",
    attrs: {
      "name": "alertCircle",
      "size": "6"
    }
  })], 1), _vm.paymentSummary ? _c('div', {
    staticClass: "text-xl font-bold"
  }, [_vm._v(" " + _vm._s(Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(this.paymentSummary.paymentBalance)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "flex justify-between pt-3 text-xs text-gray-800"
  }, [_c('div', [_vm._v(" Last Updated ")]), _c('div', {
    staticClass: "font-semibold"
  }, [_vm._v(" " + _vm._s(_vm._f("luxon")((_this$paymentSummary = this.paymentSummary) === null || _this$paymentSummary === void 0 ? void 0 : _this$paymentSummary.confirmedAsDate, {
    output: {
      format: 'MMMM dd, yyyy'
    }
  })) + " ")])])]), _c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `The following traffic types are registered to your account. <br>Contact your AM if the list is inaccurate or incomplete.`,
      expression: "\n              `The following traffic types are registered to your account. <br>Contact your AM if the list is inaccurate or incomplete.`\n            "
    }],
    staticClass: "uppercase text-xs font-semibold text-gray-800 flex justify-between"
  }, [_vm._v(" Your Traffic Types "), _c('Icon', {
    staticClass: "text-blue-500",
    attrs: {
      "name": "information",
      "size": "6"
    }
  })], 1), this.me ? _c('div', {
    staticClass: "text-xl font-bold"
  }, _vm._l(this.me.tags.filter(function (tag) {
    return tag.type == 'trafficType';
  }), function (tag) {
    return _c('Tag', {
      key: tag.slug,
      staticClass: "mr-1",
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 1) : _vm._e()]), _c('TopCampaigns')], 1), _c('div', {
    staticClass: "col-span-4"
  })])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-auto"
  }, [_c('div', {
    staticClass: "uppercase mb-2 text-xs tracking-wider font-semibold text-white"
  }, [_vm._v(" YTZ ")]), _c('div', {
    staticClass: "font-bold text-white text-3xl leading-tightest"
  }, [_vm._v(" Featured Campaigns ")])]);

}]

export { render, staticRenderFns }