var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "mr-2 rounded-full h-2 w-2 inline-flex md:hidden text-opacity-0",
    class: `bg-${_vm.TextColorMap[_vm.user.status]}-500 hover:bg-${_vm.TextColorMap[_vm.user.status]}-600`
  }), _c('div', {
    staticClass: "x-topbar-username break-all"
  }, [_vm._v(" " + _vm._s(_vm.user.label) + " ")])])]), _c('portal', {
    attrs: {
      "to": "top-bar-left-header-after"
    }
  }, [_c('div', {
    staticClass: "flex items-center gap-1 ml-2"
  }, [_vm.user.tags.some(function (tag) {
    return tag.name == 'auto-rejected';
  }) ? _c('Tag', {
    attrs: {
      "color": "darkRed"
    }
  }, [_vm._v("auto-rejected")]) : _vm._e(), _c('Tag', {
    key: _vm.user.status,
    staticClass: "hidden md:inline-flex",
    on: {
      "click": function ($event) {
        return _vm.openSetStatusWidget();
      }
    }
  }, [_vm._v(_vm._s(_vm.user.status))]), _vm.user.isQuarantined ? _c('Tag', {
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("Quarantined"), _c('span', {
    staticClass: "bg-white rounded-lg px-1 ml-1 -mr-1"
  }, [_vm._v(_vm._s(_vm.user.quarantine.level))])]) : _vm._e()], 1)]), _vm._m(0), _c('div', {
    staticClass: "flex flex-row"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Overview",
      "active": true
    }
  }, [_c('Overview', {
    attrs: {
      "filters": _vm.dailyGraphFilters,
      "user": _vm.user
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Stats"
    }
  }, [_c('simple-report', {
    staticClass: "col-span-4 mt-4",
    attrs: {
      "filters": _vm.reportFilters
    }
  }, [_c('h4', {
    staticClass: "widget-title",
    attrs: {
      "slot": "table-header"
    },
    slot: "table-header"
  }, [_vm._v("Daily Summary")])])], 1), _c('Tab', {
    attrs: {
      "name": "Details"
    }
  }, [_c('Widget', {
    staticClass: "rounded-t-none border-t-0"
  }, [_c('WidgetBody', [_c('Tabs', {
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    staticClass: "mt-2",
    attrs: {
      "name": "Primary",
      "active": true
    }
  }, [_c('PrimaryContact', {
    attrs: {
      "user": _vm.user
    }
  })], 1), _vm.user.billingContact ? _c('Tab', {
    attrs: {
      "name": "Billing"
    }
  }, [_c('BillingContact', {
    attrs: {
      "user": _vm.user
    }
  })], 1) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Details"
    }
  }, [_c('Details', {
    attrs: {
      "user": _vm.user
    }
  })], 1) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Pay Details"
    }
  }, [_vm.user.tipaltiDetails.publisherDetails ? _c('iframe', {
    staticClass: "w-full",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": _vm.user.tipaltiDetails.publisherDetails,
      "height": "800"
    }
  }) : _vm._e()]) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Pay History"
    }
  }, [_vm.user.tipaltiDetails.paymentHistoryLink ? _c('iframe', {
    staticClass: "w-full",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": _vm.user.tipaltiDetails.paymentHistoryLink,
      "height": "800"
    }
  }) : _vm._e()]) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Password"
    }
  }, [_c('div', {
    staticClass: "py-2"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.openUpdatePasswordModal
    }
  }, [_vm._v("Change Password")])], 1)])], 1)], 1)], 1)], 1), _c('Tab', {
    attrs: {
      "name": "Communications"
    }
  }, [_c('div', {
    staticClass: "pt-3"
  }, [_c('Lead', {
    attrs: {
      "id": _vm.user.id
    }
  })], 1)]), _c('Tab', {
    attrs: {
      "name": "Payouts"
    }
  }, [_c('Payouts', {
    attrs: {
      "payouts": _vm.user.payouts,
      "userId": parseInt(_vm.id),
      "level": "user"
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Postbacks"
    }
  }, [_c('Postbacks', {
    attrs: {
      "userId": parseInt(_vm.id)
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Redirects"
    }
  }, [_c('RedirectsTable', {
    attrs: {
      "override-filters": {
        user: {
          value: [{
            id: _vm.id
          }]
        }
      }
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "History"
    }
  }, [_c('UserHistory', {
    attrs: {
      "user": _vm.user,
      "userActivity": _vm.userActivity
    }
  })], 1), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Rules"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_c('Tag', [_vm._v(_vm._s(_vm.rulesTotalCount))])];
      },
      proxy: true
    }], null, false, 1623784179)
  }, [_c('RulesTableWrapper', {
    attrs: {
      "default-filter-values": {
        publishers: [_vm.user]
      },
      "hide-filters": ['redirects', 'campaigns', 'publishers', 'sources']
    },
    on: {
      "totalRulesTab": _vm.totalRulesTab
    },
    scopedSlots: _vm._u([{
      key: "beforeTable",
      fn: function () {
        return [_c('div', {
          staticClass: "flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200"
        }, [_c('div', {
          staticClass: "text-muted"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected ")])]), _c('div', {
          staticClass: "flex gap-4"
        }, [_c('YButton', {
          attrs: {
            "color": "red",
            "disabled": !_vm.rowsAreSelected
          },
          on: {
            "click": _vm.openDeleteRulesModal
          }
        }, [_vm._v("Delete")]), _c('YButton', {
          attrs: {
            "color": "blue",
            "disabled": _vm.rowsAreSelected
          },
          on: {
            "click": _vm.openCreateRulesModal
          }
        }, [_vm._v("Create Rules")])], 1)])];
      },
      proxy: true
    }], null, false, 704410060)
  })], 1) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Quarantine"
    }
  }, [_vm.user.isQuarantined && _vm.user.caps.length > 0 ? _c('Quarantine', {
    staticClass: "my-4",
    attrs: {
      "quarantine": _vm.user.quarantine,
      "current-payout": _vm.user.caps.filter(function (cap) {
        return cap.type === 'payout' && cap.frequency == 'monthly';
      })[0].currentValue
    },
    on: {
      "deleted": _vm.refetch
    }
  }) : _c('div', {
    staticClass: "my-4 text-center"
  }, [_c('span', {
    staticClass: "italic text-gray-600"
  }, [_vm._v("User is not quarantined")])])], 1)], 1)], 1)]), _c('portal', {
    attrs: {
      "to": "sidebar-right"
    }
  }, [_c('SideBarRightItem', {
    scopedSlots: _vm._u([{
      key: "root",
      fn: function () {
        return [_c('div', {
          key: _vm.user.accountManager ? _vm.user.accountManager.id : 0,
          staticClass: "x-header group cursor-pointer",
          on: {
            "click": function ($event) {
              return _vm.openSyncAccountManagerWidget();
            }
          }
        }, [_c('div', {
          staticClass: "x-icon-container"
        }, [_vm.user.accountManager ? _c('UserAvatar', {
          attrs: {
            "id": _vm.user.accountManager.id,
            "height": "10"
          }
        }) : _c('div', {
          staticClass: "text-white w-10 h-10 text-center leading-10 rounded-full font-extrabold bg-gray-300"
        }, [_c('Icon', {
          staticClass: "p-2",
          attrs: {
            "name": "accountQuestion",
            "size": 10
          }
        })], 1), _vm._v(" --> ")], 1), _c('div', {
          staticClass: "x-header-text flex-1"
        }, [_c('div', {
          staticClass: "text-xs font-semibold text-gray-500"
        }, [_vm._v(" Account Manager ")]), _c('div', {
          staticClass: "text-gray-700"
        }, [_vm.user.accountManager && _vm.user.accountManager.primaryContact ? [_vm._v(" " + _vm._s(_vm.user.accountManager.primaryContact.name) + " ")] : [_c('span', {
          staticClass: "text-gray-400"
        }, [_vm._v(" Not Assigned")])]], 2)]), _c('Icon', {
          staticClass: "text-blue-600 mx-2 invisible group-hover:visible",
          attrs: {
            "name": "pencil"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 4183588422)
  }), _c('SideBarRightItem', {
    attrs: {
      "title": "Traffic Sources"
    }
  }, [_c('EditableValue', {
    ref: "trafficSourcesEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'trafficType';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'trafficType',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "orange",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trafficType']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 1178113921)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Verticals"
    }
  }, [_c('EditableValue', {
    ref: "verticalsEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'vertical';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'vertical',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "blue",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['vertical']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2547501416)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Geos"
    }
  }, [_c('EditableValue', {
    ref: "geosEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'geoLocation';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'geoLocation',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "green",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['geoLocation']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2354433743)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Tracking Platform"
    }
  }, [_c('EditableValue', {
    ref: "trackingPlatformEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'trackingPlatform';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'trackingPlatform',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "purple",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trackingPlatform']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 991470234)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Referred By"
    }
  }, [_c('EditableValue', {
    ref: "referredEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'referredOption';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'referredOption',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "gray",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['referredOption']
                }
              },
              first: 500
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 1095304123)
  })], 1), _c('SideBarRightItem', {
    staticClass: "flex-1",
    attrs: {
      "title": "Comments",
      "icon": "message",
      "count": _vm.user.commentsAdmin.length
    }
  }, [_c('SideBarComments', {
    attrs: {
      "comments": _vm.user.commentsAdmin,
      "modelId": _vm.user.id,
      "modelClass": "User"
    }
  })], 1)], 1)], 1) : _c('div', [_c('Spinner', {
    staticClass: "text-green-500",
    attrs: {
      "size": 10
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row align-items-center mb-2"
  }, [_c('div')]);

}]

export { render, staticRenderFns }