var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.me && _vm.me.isAPublisher && _vm.me.userDetails.agreedToTerms == false ? _c('YAlert', {
    staticClass: "mt-0 mb-0 px-6",
    attrs: {
      "allow-close": false,
      "color": "blue"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0 font-weight-semibold"
  }, [_vm._v(" You must accept "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("YTZ Terms & Conditions")]), _vm._v(" before viewing campaigns. You will be redirected to the dashboard upon acceptance of terms. ")])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }