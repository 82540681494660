var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "w-full mb-1"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-6 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.filters.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offer, "value", $$v);
      },
      expression: "filters.offer.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Advertisers",
      "modifiers": {},
      "track-by": "id",
      "label": "label",
      "options": _vm.advertiserOptions ? _vm.advertiserOptions.data : [],
      "multiple": true
    },
    model: {
      value: _vm.filters.advertisers.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.advertisers, "value", $$v);
      },
      expression: "filters.advertisers.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Verticals",
      "modifiers": _vm.filters.verticalTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['vertical']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.verticalTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.verticalTags, "value", $$v);
      },
      expression: "filters.verticalTags.value"
    }
  }), _c('Superselect', {
    staticClass: "col-span-1",
    attrs: {
      "title": _vm.filters.destinationType.title,
      "multiple": false,
      "options": ['direct', 'template'],
      "hide-selected": false,
      "modifiers": _vm.filters.destinationType.modifiers
    },
    model: {
      value: _vm.filters.destinationType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.destinationType, "value", $$v);
      },
      expression: "filters.destinationType.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": _vm.filters.template.title
    },
    model: {
      value: _vm.filters.template.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.template, "value", $$v);
      },
      expression: "filters.template.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Status",
      "modifiers": {},
      "options": ['Active', 'Created', 'Paused', 'Inactive'],
      "multiple": false
    },
    model: {
      value: _vm.filters.status.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": _vm.filters.mobileDesktopTags.title,
      "modifiers": _vm.filters.mobileDesktopTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['mobileDesktop']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.mobileDesktopTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.mobileDesktopTags, "value", $$v);
      },
      expression: "filters.mobileDesktopTags.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": _vm.filters.countryCodeTags.title,
      "modifiers": _vm.filters.countryCodeTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['countryCode']
          }
        },
        first: 300
      }
    },
    model: {
      value: _vm.filters.countryCodeTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.countryCodeTags, "value", $$v);
      },
      expression: "filters.countryCodeTags.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": _vm.filters.trafficTypeTags.title,
      "modifiers": _vm.filters.trafficTypeTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trafficType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.trafficTypeTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.trafficTypeTags, "value", $$v);
      },
      expression: "filters.trafficTypeTags.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": _vm.filters.offerTypeTags.title,
      "modifiers": _vm.filters.offerTypeTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['offerType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.offerTypeTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offerTypeTags, "value", $$v);
      },
      expression: "filters.offerTypeTags.value"
    }
  }), _c('Superselect', {
    staticClass: "col-span-1",
    attrs: {
      "title": _vm.filters.yleaderStatus.title,
      "modifiers": _vm.filters.yleaderStatus.modifiers,
      "options": ['yLeader', 'Regular'],
      "multiple": false
    },
    model: {
      value: _vm.filters.yleaderStatus.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.yleaderStatus, "value", $$v);
      },
      expression: "filters.yleaderStatus.value"
    }
  }), _c('Superselect', {
    staticClass: "col-span-1",
    attrs: {
      "title": _vm.filters.ignoreTimeout.title,
      "modifiers": _vm.filters.ignoreTimeout.modifiers,
      "options": ['No', 'Yes'],
      "multiple": false
    },
    model: {
      value: _vm.filters.ignoreTimeout.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.ignoreTimeout, "value", $$v);
      },
      expression: "filters.ignoreTimeout.value"
    }
  })], 1)]), _c('div', {
    staticClass: "w-full mt-3"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('YCheckbox', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "text-gray-600 font-semibold uppercase text-xs my-1"
        }, [_vm._v(" Emulate Traffic ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showTrafficFilters,
      callback: function ($$v) {
        _vm.showTrafficFilters = $$v;
      },
      expression: "showTrafficFilters"
    }
  })], 1), _vm.showTrafficFilters ? _c('div', {
    staticClass: "grid grid-cols-6 sm:grid-cols6 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Publisher",
      "modifiers": _vm.filters.publisher.modifiers,
      "options": [],
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.filters.publisher.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.publisher, "value", $$v);
      },
      expression: "filters.publisher.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Campaign",
      "track-by": "id",
      "label": "label",
      "modifiers": _vm.filters.campaign.modifiers,
      "multiple": false,
      "options": [],
      "query": _vm.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Redirect",
      "track-by": "id",
      "label": "label",
      "modifiers": {},
      "multiple": false,
      "options": [],
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Source"
    },
    model: {
      value: _vm.filters.source.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.source, "value", $$v);
      },
      expression: "filters.source.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": _vm.filters.mobileDesktopTrafficTags.title,
      "modifiers": _vm.filters.mobileDesktopTrafficTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['mobileDesktop']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.mobileDesktopTrafficTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.mobileDesktopTrafficTags, "value", $$v);
      },
      expression: "filters.mobileDesktopTrafficTags.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": _vm.filters.countryCodeTrafficTags.title,
      "modifiers": _vm.filters.countryCodeTrafficTags.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['countryCode']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.countryCodeTrafficTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.countryCodeTrafficTags, "value", $$v);
      },
      expression: "filters.countryCodeTrafficTags.value"
    }
  })], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }