var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    class: `w-${_vm.width} h-${_vm.height} flex items-center overflow-hidden relative`
  }, [_vm.showLoader ? _c('Spinner', {
    staticClass: "m-auto",
    attrs: {
      "size": 8,
      "color": "blue"
    }
  }) : _c('label', {
    class: `text-gray-300 hover:text-blue-400 ${_vm.isEditable ? 'x-hoverable' : ''} w-${_vm.width} h-${_vm.height} flex items-center`
  }, [_vm.imageUrl || _vm.localImageUrl ? _c('div', {
    staticClass: "x-upload-image"
  }, [_c('div', {
    staticClass: "x-overlay"
  }, [_c('svg', {
    staticClass: "x-overlay-icon",
    attrs: {
      "fill": "white",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
    }
  })])]), _vm.isEditable ? _c('img', {
    class: `w-${_vm.width} h-${_vm.height} block object-contain`,
    attrs: {
      "src": _vm.imageUrl || _vm.localImageUrl
    }
  }) : _c('img', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        src: _vm.imageUrl
      },
      expression: "{ src: imageUrl }"
    }],
    class: `w-${_vm.width} h-${_vm.height} block object-contain`,
    attrs: {
      "src": _vm.thumbnailUrl || _vm.imageUrl || _vm.localImageUrl
    }
  })]) : _c('svg', {
    class: `feather feather-image w-${_vm.blankWidth || _vm.width} h-${_vm.blankHeight || _vm.height} block m-auto`,
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('rect', {
    attrs: {
      "x": "3",
      "y": "3",
      "width": "18",
      "height": "18",
      "rx": "2",
      "ry": "2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "8.5",
      "cy": "8.5",
      "r": "1.5"
    }
  }), _c('polyline', {
    attrs: {
      "points": "21 15 16 10 5 21"
    }
  })]), _c('input', {
    ref: "imageInput",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "disabled": !_vm.isEditable
    },
    on: {
      "change": _vm.onImageChange
    }
  })])], 1), _vm.fileSizeExceedsLimit ? _c('div', {
    staticClass: "text-red-500"
  }, [_vm._v(" File size exceeds " + _vm._s(_vm.fileSizeLimit) + "MB. Please choose a smaller file. ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }