<template>
  <div>
    <Widget>
      <WidgetBody>
        <div
          class="flex justify-end items-center gap-3 mb-2"
          v-if="changesAllowed"
        >
          <YButton
            class="btn btn-red"
            @click="openDeleteModal"
            :disabled="!rowsAreSelected"
            >Delete</YButton
          >
          <YButton
            v-if="hasDefautConversionTypePayout && level == 'campaign'"
            class="btn btn-blue mr-1"
            @click="openUpdateDefaultConversionType(campaignId)"
          >
            Update Defaults
          </YButton>
          <YButton
            class="btn btn-blue"
            :disabled="hasDefautConversionTypePayout || hasFutureSelected"
            @click="
              openUpsertPayout(userId, redirectId, campaignId, sourceId, level)
            "
          >
            Upsert Payouts
          </YButton>
          <Icon
            v-if="hasDefautConversionTypePayout"
            name="alert"
            class="text-red-600 ml-2"
            :size="6"
            v-tooltip="hasDefaultConverionTypeWarning"
          />
        </div>

        <YTable
          v-if="payouts"
          :data="payouts || []"
          :selectable="changesAllowed"
          :row-classes="
            row =>
              row.startAt != null
                ? ['future-payout-row']
                : row.isPaused
                ? ['paused-payout-row']
                : []
          "
          :selected.sync="selectedRowIds"
        >
          <template slot="header-row">
            <th></th>
            <th>Level</th>
            <th>Payout Type</th>
            <th>Conversion</th>
            <th>Country</th>
            <th>Rate</th>
            <th>Floor</th>
            <th v-if="payouts.status">status</th>
            <th>Start Date</th>
            <th></th>
          </template>

          <template slot="row" slot-scope="props">
            <td>
              <template v-if="props.rowData.mutationAction == 'create'">
                <y-tag type="danger" text="UNSAVED"></y-tag>
              </template>
              <div
                v-if="props.rowData.isPaused"
                class="row-span-2 flex items-center"
              >
                <Icon
                  name="pause"
                  size="5"
                  color="orange"
                  v-tooltip="'Paused'"
                />
              </div>
            </td>

            <td>
              <payouts-level-cell
                :row-data="props.rowData"
              ></payouts-level-cell>
            </td>
            <td>
              {{
                props.rowData.type
                  ? props.rowData.type.toUpperCase()
                  : props.rowData.type
              }}
            </td>
            <td>
              {{ props.rowData.conversionType?.name }}
            </td>
            <td>
              <template v-if="!props.rowData.country"> ALL </template>
              <template v-else>
                <span v-tooltip="props.rowData.country.name">{{
                  props.rowData.country.code
                }}</span>
              </template>
            </td>

            <td>
              <div v-if="props.rowData.rate == null">
                $0
              </div>
              <div v-else>
                {{ props.rowData.rate }}
              </div>
            </td>
            <td>{{ props.rowData.floorRate }}</td>

            <td v-if="payouts.status">
              <Tag>{{ props.rowData.status }}</Tag>
            </td>
            <td>
              <span class="text-xs text-gray-800">
                {{
                  props.rowData.startAt
                    ? props.rowData.startAt.split(' ')[0]
                    : null
                }}
              </span>
            </td>
            <td>
              <payouts-actions-cell
                :row-data="props.rowData"
              ></payouts-actions-cell>
            </td>
          </template>
        </YTable>
      </WidgetBody>
    </Widget>
  </div>
</template>

<script>
import PayoutsDelete from '@/views/Admin/Payouts/PayoutsDelete.vue'
import PayoutsUpsert from '@/views/Admin/Payouts/PayoutsUpsert.vue'
import PayoutUpdateDefaultConversionTypes from '@/views/Admin/Payouts/PayoutUpdateDefaultConversionTypes.vue'
import PayoutsLevelCell from '@/components/legacy/VuetableCells/PayoutsLevelCell'
import PayoutsActionsCell from '@/components/legacy/VuetableCells/PayoutsActionsCell'
import yTag from '@/components/legacy/yTag.vue'
import PAYOUTS_QUERY from '@/graphql/Payout/PayoutsQuery.gql'

export default {
  components: {
    PayoutsLevelCell,
    PayoutsActionsCell,
    yTag
  },
  apollo: {
    payouts: {
      query: PAYOUTS_QUERY,
      variables() {
        return {
          first: 100,
          filters: {
            id: {
              value: this.selectedRowIds
            }
          }
        }
      },
      result(data) {
        this.checkSelectedTypes(data.data.payouts.data)
      },
      skip() {
        return this.selectedRowIds.length == 0
      }
    }
  },
  props: {
    userId: {
      type: Number,
      default: null,
      required: false
    },
    redirectId: {
      type: Number,
      default: null,
      required: false
    },
    campaignId: {
      type: Number,
      default: null,
      required: false
    },
    sourceId: {
      type: String,
      default: null,
      required: false
    },
    level: {
      type: String
    },
    payouts: {
      default: () => {
        ;[]
      },
      type: Array
    }
  },
  data() {
    return {
      hasDefaultConverionTypeWarning:
        'You must delete any default payouts before upserting',
      selectedRowIds: [],
      payoutsCloned: this._.cloneDeep(this.value),
      checkSelectedTypesEdit: false,
      payoutType: '',
      conversionType: '',
      selectedPayouts: null
    }
  },
  methods: {
    checkSelectedTypes(data) {
      this.selectedPayouts = data
      if (this.selectedRowIds.length > 0) {
        let payoutTypes = data.map(payout => payout.type)
        let payoutResult = payoutTypes.every(element => element == data[0].type)

        if (payoutResult) {
          this.payoutType = payoutTypes[0]
        }
        this.checkSelectedTypesEdit = payoutResult
      }
    },
    onPaginationData() {
      this.$events.emit('onPaginationData', true)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    loading() {
      this.$events.emit('loading', true)
    },
    loaded() {
      this.$events.emit('loaded', true)
    },
    openDeleteModal() {
      this.$modal.show(
        PayoutsDelete,
        {
          ids: this.selectedRowIds
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openUpsertPayout(user_id, redirect_id, campaign_id) {
      if (this.checkSelectedTypesEdit || this.selectedRowIds == 0) {
        this.$modal.show(
          PayoutsUpsert,
          {
            userId: user_id,
            redirectId: redirect_id,
            campaignId: campaign_id,
            selectedRows: this.selectedRowIds,
            payoutType: this.payoutType,
            conversionType: this.conversionType
          },
          {
            height: 'auto',
            width: '80%',
            maxWidth: 800,
            adaptive: true,
            scrollable: true,
            classes: ['overflow-visible-important']
          }
        )
      } else {
        this.$toastr.e(
          'Payout fields must match in order to edit in bulk',
          'Error'
        )
      }
    },
    openUpdateDefaultConversionType(campaignId) {
      this.$modal.show(
        PayoutUpdateDefaultConversionTypes,
        {
          campaignId: campaignId
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          classes: ['overflow-visible-important']
        }
      )
    }
  },
  watch: {},
  computed: {
    hasFutureSelected() {
      let output = this.selectedPayouts
        ? this.selectedPayouts.filter(payout => payout.startAt).length
        : null

      return output > 0
    },
    payoutsSorted() {
      let output = this.payouts
      let result = output.sort((a, b) => {
        if (a.isPaused > b.isPaused) return 1
        if (a.isPaused < b.isPaused) return -1
        if (a.level > b.level) return 1
        if (a.level < b.level) return -1
        if (a.countryCode > b.countryCode) return 1
        if (a.countryCode < b.countryCode) return -1
      })
      return result
    },
    changesAllowed() {
      return this.level == 'campaign'
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    },
    computedLevel() {
      if (this.level) {
        return this.level
      } else {
        return this.redirectId
          ? 'redirect'
          : this.campaignId
          ? 'campaign'
          : 'user'
      }
    },
    hasDefautConversionTypePayout() {
      return (
        this.payouts.filter(payout => payout?.conversionType?.slug == 'default')
          ?.length > 0
      )
    }
  },
  mounted() {
    this.$events.listen('refetchPayouts', () => {
      this.selectedRowIds = []
    })
  },
  updated() {}
}
</script>
<style>
.paused-payout-row {
  @apply bg-gray-100 text-gray-900;
}
.future-payout-row {
  color: #999;
}

.future-payout-row .select-checkbox {
  opacity: 0.35;
}
</style>
