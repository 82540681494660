<template>
  <div style="position:relative; z-index:1;">
    <div class="flex mb-2 justify-between">
      <div></div>
      <Tag @click="toggleHide">
        <template v-if="hide">Show All</template>
        <template v-else> Hide Unused</template>
      </Tag>
    </div>
    <div
      v-for="definition of definitions"
      :key="definition.key"
      class="pb-1"
      style="min-width: 15rem;"
    >
      <template v-if="definition.type == 'string'">
        <YInput
          v-show="!hide || inputs[definition.key]"
          v-model="inputs[definition.key]"
          :id="definition.key"
          :label="definition.key"
          :required="definition.required"
          :tooltip="definition.tooltip"
          :icons="[
              definition.tokenSwapper ? {
                    name: 'codeBraces',
                     tooltip: 'Dynamic tokens enabled. Use {country}, {region}, or {city}'
            } : undefined
          ].filter(icon=>icon)"
        />
        <TemplateVarVariantsContainer
          v-show="!hide || inputs[definition.key]"
          v-if="linkedDefinitions[definition.key]"
          class="mt-1"
          :variant-definitions="linkedDefinitions[definition.key]"
          :inputs="inputs"
        >
          <template v-slot:default="props">
            <YInput
              v-show="props.show"
              v-model="inputs[props.definition.key]"
              :id="props.definition.key"
              :label="props.definition.key"
            />
          </template>
        </TemplateVarVariantsContainer>
      </template>
      <template v-if="definition.type == 'textarea'">
        <YTextarea
          v-show="!hide || inputs[definition.key]"
          v-model="inputs[definition.key]"
          :id="definition.key"
          :label="definition.key"
          :required="definition.required"
          :tooltip="definition.tooltip"
          :icons="[
              definition.tokenSwapper ? {
                    name: 'codeBraces',
                       tooltip: 'Dynamic tokens enabled. Use {country}, {region}, or {city}'
            } : undefined
          ].filter(icon=>icon)"
        />
        <TemplateVarVariantsContainer
          v-show="!hide || inputs[definition.key]"
          v-if="linkedDefinitions[definition.key]"
          class="mt-1"
          :variant-definitions="linkedDefinitions[definition.key]"
          :inputs="inputs"
        >
          <template v-slot:default="props">
            <YTextarea
              v-show="props.show"
              v-model="inputs[props.definition.key]"
              :id="props.definition.key"
              :label="props.definition.key"
            />
          </template>
        </TemplateVarVariantsContainer>
      </template>

      <template v-if="definition.type == 'colorSelect'">
        <div class="flex items-center gap-4">
           <YInput
            v-model="inputs[definition.key]"
            @input="updateColorValue(definition, $event)"
            :label="definition.key"
            class="w-full"
          />
          <verte
            v-model="inputs[definition.key]"
            @input="updateColorValue(definition, $event)"
            picker="wheel"
            model="hex"
            menuPosition="left"
          ></verte>
        </div>
      </template>

      <template v-if="definition.type == 'boolean'">
        <Superselect
          v-show="!hide || inputs[definition.key]"
          :id="definition.key"
          :title="definition.key"
          :multiple="false"
          v-model="inputs[definition.key]"
          :options="['yes', 'no']"
          :tooltip="definition.tooltip"
        />
        <TemplateVarVariantsContainer
          v-if="linkedDefinitions[definition.key]"
          v-show="!hide || inputs[definition.key]"
          class="mt-1"
          :variant-definitions="linkedDefinitions[definition.key]"
          :inputs="inputs"
        >
          <template v-slot:default="props">
            <Superselect
              v-show="props.show"
              :id="props.definition.key"
              :title="props.definition.key"
              :multiple="false"
              v-model="inputs[props.definition.key]"
              :options="['yes', 'no']"
            />
          </template>
        </TemplateVarVariantsContainer>
      </template>
      <template v-if="definition.type == 'select'">
        <Superselect
          v-show="!hide || inputs[definition.key]"
          :id="definition.key"
          :title="definition.key"
          :multiple="false"
          v-model="inputs[definition.key]"
          :options="definition.options"
          :tooltip="definition.tooltip"
        />
        <TemplateVarVariantsContainer
          v-if="linkedDefinitions[definition.key]"
          v-show="!hide || inputs[definition.key]"
          class="mt-1"
          :variant-definitions="linkedDefinitions[definition.key]"
          :inputs="inputs"
        >
          <template v-slot:default="props">
            <Superselect
              v-show="props.show"
              :id="props.definition.key"
              :title="props.definition.key"
              :multiple="false"
              v-model="inputs[props.definition.key]"
              :options="props.definition.options"
            />
          </template>
        </TemplateVarVariantsContainer>
      </template>
      <template
        v-if="definition.type == 'offer' || definition.type == 'offerFeed'"
      >
        <div>
          <OfferVarSelect
            v-show="!hide || inputs[definition.key]"
            :id="definition.key"
            :title="definition.key"
            :types="
              definition.type == 'offerFeed' ? ['feed'] : ['offer', 'campaign']
            "
            v-model="inputs[definition.key]"
            :required="definition.required"
            :tooltip="definition.tooltip"
          />
          <TemplateVarVariantsContainer
            v-if="offerVariantDefinitions[definition.key]"
            v-show="!hide || inputs[definition.key]"
            class="mt-1"
            :variant-definitions="offerVariantDefinitions[definition.key]"
            :inputs="inputs"
          >
            <template v-slot:default="props">
              <OfferVarSelect
                v-show="props.show"
                :id="props.definition.key"
                :title="props.definition.key"
                :types="definition.type == 'offerFeed' ? ['feed'] : undefined"
                v-model="inputs[props.definition.key]"
              />
            </template>
          </TemplateVarVariantsContainer>
        </div>
      </template>
      <template v-if="definition.type == 'image'">
        <Superselect
          v-show="!hide || inputs[definition.key]"
          :id="definition.key"
          :title="definition.key"
          v-model="inputs[definition.key]"
          :options="[]"
          track-by="id"
          label="name"
          :multiple="false"
          no-result="Sorry, no images found."
          :required="definition.required"
          :tooltip="definition.tooltip"
          :query="TEMPLATE_IMAGES_QUERY"
          :query-variables="{
            first: 10,
            filters: {
              name: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        >
          <template v-slot:singleLabel="props">
            <div class="flex items-center">
              <YImage
                :src="props.option.imageThumbnailUrl"
                :width="6"
                :height="6"
                class="rounded mr-2"
              />
              <div>{{ props.option.name }}</div>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="flex items-center -mx-2">
              <YImage
                :src="props.option.imageThumbnailUrl"
                :width="12"
                :height="12"
                class="rounded mr-2"
              />
              <div>{{ props.option.name }}</div>
            </div>
          </template>
        </Superselect>
        <TemplateVarVariantsContainer
          v-if="linkedDefinitions[definition.key]"
          v-show="!hide || inputs[definition.key]"
          class="mt-1"
          :variant-definitions="linkedDefinitions[definition.key]"
          :inputs="inputs"
        >
          <template v-slot:default="props">
            <Superselect
              v-show="props.show"
              :id="props.definition.key"
              :title="props.definition.key"
              v-model="inputs[props.definition.key]"
              :options="[]"
              track-by="id"
              label="name"
              :multiple="false"
              no-result="Sorry, no images found."
              :query="TEMPLATE_IMAGES_QUERY"
              :query-variables="{
                first: 10,
                filters: {
                  name: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                }
              }"
            >
              <template v-slot:singleLabel="props">
                <div class="flex items-center">
                  <YImage
                    :src="props.option.imageThumbnailUrl"
                    :width="6"
                    :height="6"
                    class="rounded mr-2"
                  />
                  <div>{{ props.option.name }}</div>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="flex items-center -mx-2">
                  <YImage
                    :src="props.option.imageThumbnailUrl"
                    :width="12"
                    :height="12"
                    class="rounded mr-2"
                  />
                  <div>{{ props.option.name }}</div>
                </div>
              </template>
            </Superselect>
          </template>
        </TemplateVarVariantsContainer>
      </template>
    </div>
  </div>
</template>

<script>
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import TEMPLATE_IMAGES_QUERY from '@/graphql/TemplateImages/TemplateImagesQuery.gql'
import TemplateVarVariantsContainer from '@/views/Admin/Templates/TemplateVarVariantsContainer'
import OfferVarSelect from '@/views/Admin/Templates/OfferVarSelect'
import Verte from 'verte'
import 'verte/dist/verte.css'

export default {
  props: {
    definitions: {
      type: Array
    },
    linkedVars: {
      type: Object
    },
    initialInputs: {
      type: Object
    },
    template: {
      type: Object
    },
    varEndings: {
      type: Array
    }
  },
  components: {
    TemplateVarVariantsContainer,
    OfferVarSelect,
    Verte
  },
  mounted() {
    this.setUpdateVarFunc()
    this.updateVars(this.inputs)
  },
  created() {
    this.$store.dispatch('setUpdateVars', this.updateVars)
  },
  data() {
    return {
      OFFER_OPTIONS_QUERY,
      TEMPLATE_IMAGES_QUERY,
      vars: {},
      inputs: {},
      updateVars: () => {},
      hide: true
    }
  },
  watch: {
    initialInputs: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value) {
          this.hide = false
          return
        }
        if (Object.values(value).filter(item => item).length == 0) {
          this.hide = false
        }
        this.inputs = value
        this.updateVars(value)
      }
    },
    inputs: {
      deep: true,
      handler(value) {
        this.$emit('inputsUpdated', value)
        this.updateVars(value)
      }
    },
    vars: {
      deep: true,
      handler(vars) {
        if (Object.values(vars).filter(item => item).length == 0) {
          this.hide = false
        }
        this.$emit('updated', vars)
        this.$emit('queryStringUpdated', this.getQueryString(vars))
      }
    }
  },
  computed: {
    linkedDefinitions() {
      let definitionsByKey = JSON.parse(JSON.stringify(this.definitionsByKey))
      let linkedDefinitions = {}
      Object.keys(this.linkedVars).forEach(key => {
        this.varEndings.forEach(ending => {
          if (this.vars[key + ending]) {
            this.linkedVars[key].forEach(linkedTo => {
              if (!linkedDefinitions[linkedTo]) {
                linkedDefinitions[linkedTo] = {}
              }
              var linkedDefinition = JSON.parse(
                JSON.stringify(definitionsByKey[linkedTo])
              )
              linkedDefinition.key = linkedTo + ending
              linkedDefinitions[linkedTo][linkedTo + ending] = linkedDefinition
            })
          }
        })
      })
      return linkedDefinitions
    },
    offerVariantDefinitions() {
      let definitionsByKey = JSON.parse(JSON.stringify(this.definitionsByKey))

      let variantDefinitions = {}

      this.offerDefinitions.forEach(offerDef => {
        this.varEndings.forEach(ending => {
          let variantDefinition = JSON.parse(
            JSON.stringify(definitionsByKey[offerDef.key])
          )
          variantDefinition.key = offerDef.key + ending
          if (!variantDefinitions[offerDef.key]) {
            variantDefinitions[offerDef.key] = {}
          }
          variantDefinitions[offerDef.key][
            variantDefinition.key
          ] = variantDefinition
        })
      })

      return variantDefinitions
    },
    definitionsByKey() {
      const output = {}
      this.definitions.forEach(function(definition) {
        output[definition.key] = definition
      })
      return output
    },
    offerDefinitions() {
      return this.definitions.filter(
        definition =>
          definition.type == 'offer' || definition.type == 'offerFeed'
      )
    }
  },

  methods: {
    toggleHide() {
      this.hide = !this.hide
    },
    getQueryString(vars) {
      return Object.keys(vars)
        .filter(key => vars[key] != null)
        .map(key => key + '=' + vars[key])
        .join('&')
    },
    setUpdateVarFunc() {
      this.updateVars = this._.debounce(function(inputs) {
        const vars = {}
        Object.keys(inputs).forEach(inputKey => {
          if (inputs[inputKey]?.__typename == 'TemplateImage') {
            return (vars[inputKey] = inputs[inputKey].slug)
          }

          if (inputs[inputKey]?.__typename == 'Offer') {
            return (vars[inputKey] = '/o/' + inputs[inputKey].encodedId)
          }

          if (inputs[inputKey]?.__typename == 'Campaign') {
            return (vars[inputKey] = '/c/' + inputs[inputKey].encodedId)
          }

          if (inputs[inputKey]?.__typename == 'OfferFeed') {
            return (vars[inputKey] = '/f/' + inputs[inputKey].id)
          }

          return (vars[inputKey] = inputs[inputKey])
        })

        this.vars = vars
      }, 300)
    }
  }
}
</script>

<style scoped>
ul.offer-var-options-menu li > a {
  @apply p-2;
}
</style>
