<template>
  <div class="slide flex px-4 py-3">
    <div class="w-2/5 flex justify-center items-center">
      <router-link
        :to="{
          name: 'Publisher Campaign',
          params: { id: data.id }
        }"
      >
        <img
          :src="data.imageUrl"
          class="object-cover h-48 w-56 rounded-md shadow block"
        />
      </router-link>
    </div>

    <div class="pl-5 my-1 w-3/5">
      <span class="font-bold text-xl pb-2">
        <router-link
          :to="{
            name: 'Publisher Campaign',
            params: { id: data.id }
          }"
          class="text-blue-600 hover:text-blue-700"
          >{{ data.label }}</router-link
        ></span
      >
      <!-- tags -->
      <div class="flex my-1 flex-wrap gap-y-1">
        <div
          class="mr-2 inline-flex"
          v-for="tag in data.verticalTags"
          :key="tag.id"
        >
          <Tag :color="tag.color">{{ tag.name }}</Tag>
        </div>
        <div
          class="mr-2  inline-flex"
          v-for="tag in data.conversionType"
          :key="tag.id"
        >
          <Tag :color="tag.color">{{ tag.name }}</Tag>
        </div>
        <div
          class="mr-2 inline-flex"
          v-for="tag in data.payoutDescriptions"
          :key="tag.id"
        >
          <Tag color="inverse" class="mr-2">{{ tag }}</Tag>
        </div>
      </div>
      <!-- caps -->
      <div class="mt-4">
        <template>
          <div
            v-for="capDefault in data.capDefaults"
            :key="capDefault.id"
            class="font-medium inline-block mr-8"
          >
            {{ formatCapToString(capDefault) }}
          </div>
        </template>
      </div>
      <!-- geos -->
      <div class="flex mt-1">
        <span class="font-medium pr-3">Available Geos:</span>
        <expandable-countries-from-payouts
          :payouts="data.payouts"
        ></expandable-countries-from-payouts>
      </div>

      <!-- action button -->
      <div class="flex justify-start mt-4">
        <router-link
          :to="{
            name: 'Publisher Campaign',
            params: { id: data.id }
          }"
          class="font-bold whitespace-normal"
        >
          <div
            class="font-bold py-2 px-4 rounded-full uppercase"
            :class="buttonStatus"
          >
            {{ buttonStatusText }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ExpandableCountriesFromPayouts from '@/components/general/ExpandableCountriesFromPayouts'

export default {
  components: { ExpandableCountriesFromPayouts },
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
  mounted() {},
  updated() {},
  computed: {
    buttonStatus() {
      switch (this.data.status) {
        case 'pending approval':
          return 'bg-yellow-500 text-white hover:bg-yellow-600'
        default:
          return 'border-2 border-blue-600 text-blue-600 hover:bg-blue-100 "'
      }
    },
    buttonStatusText() {
      switch (this.data.status) {
        case 'pending approval':
          return 'pending approval'
        default:
          return 'click for details'
      }
    }
  },
  methods: {
    formatCapToString(cap) {
      if (cap.type == 'payout') {
        return `$ ${cap.triggerValue} ${cap.frequency.capitalize()} Cap `
      } else if (cap.type == 'publisherActions') {
        return `${cap.triggerValue} ${cap.frequency.capitalize()} Actions Cap `
      } else {
        return ' '
      }
    }
  }
}
</script>

<style>
.agile__dots {
  @apply pb-2;
  margin: auto;
  list-style: none;
  white-space: nowrap;
}
.agile__dot {
  @apply mx-3;
}

.agile__dot button {
  @apply bg-gray-400 m-0 p-0 h-3 w-3;
  border: none;
  border-radius: 50%;
  line-height: 0;
}

.agile__dot--current button {
  @apply bg-pink-500;
}
</style>
