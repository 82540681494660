<template>
  <div>
    <div class="flex">
      <YAlert
        type="warning"
        color="blue"
        class="w-full"
        :allow-close="false"
        :style="unsavedChanges ? 'opacity: 1' : 'opacity: 0'"
      >
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <Icon v-if="icon" name="alert" size="40" class="mr-2" />
            <span>You have unsaved changes</span>
          </div>
          <YButton :is-loading="isSaving" @click="save" color="blue"
            >Save</YButton
          >
        </div>
      </YAlert>
    </div>
    <div class="relative">
      <div class="absolute pt-16 w-full">
        <Spinner v-if="loading" :size="16" color="blue" />
      </div>
      <VueSchedule
        :class="[loading ? 'disable' : '']"
        v-model="schedule"
        :day-table="dayTable"
        :bg-active="bgActive"
        :bg-hover="bgHover"
        :bg="bg"
      />
    </div>
  </div>
</template>

<script>
import 'vue-daily-scheduler/dist/vue-schedule.min.css'
import VueSchedule from 'vue-daily-scheduler'
import DAYPART_QUERY from '@/graphql/Daypart/DaypartQuery.gql'
import CREATE_OR_UPDATE_DAYPART_MUTATION from '@/graphql/Daypart/CreateOrUpdateDaypartMutation.gql'

export default {
  components: {
    VueSchedule
  },
  props: {
    daypartableId: {
      type: String,
      required: true
    },
    daypartableType: {
      type: String,
      required: true
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    daypart: {
      query: DAYPART_QUERY,
      variables() {
        return {
          input: {
            daypartableId: this.daypartableId,
            daypartableType: this.daypartableType
          }
        }
      },
      result({ data }) {
        this.initializeSchedule(data.daypart?.schedule ?? null)
      }
    }
  },
  data() {
    return {
      dayTable: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      bgActive: '#4299e1',
      bgHover: '#63b3ed',
      bg: '#edf2f7',
      schedule: null,
      initialSchedule: null,
      isSaving: false
    }
  },
  computed: {
    loading() {
      return this.$apollo.queries.daypart.loading || this.isSaving
    },
    scheduleIsEmpty() {
      return (
        !this.schedule ||
        Object.values(this.schedule).filter(array => array.length > 0).length ==
          0
      )
    },
    unsavedChanges() {
      return (
        JSON.stringify(this.initialSchedule) != JSON.stringify(this.schedule)
      )
    }
  },
  watch: {
    scheduleIsEmpty(value) {
      if (value == true) {
        this.schedule = null
      }
    }
  },
  methods: {
    save() {
      this.isSaving = true
      this.$apollo
        .mutate({
          mutation: CREATE_OR_UPDATE_DAYPART_MUTATION,
          variables: {
            input: {
              daypartableId: this.daypartableId,
              daypartableType: this.daypartableType,
              schedule: this.scheduleIsEmpty ? null : this.schedule
            }
          }
        })
        .then(({ data }) => {
          this.isSaving = false
          this.initializeSchedule(data.createOrUpdateDaypart?.schedule ?? null)
          this.$toastr.s('Daypart Saved')
        })
        .catch(error => {
          this.isSaving = false
          console.error(error)
        })
    },
    initializeSchedule(schedule) {
      this.schedule = schedule
        ? Object.assign({}, JSON.parse(JSON.stringify(schedule)))
        : null
      this.initialSchedule = JSON.parse(JSON.stringify(this.schedule))
    }
  }
}
</script>

<style scoped>
.disable {
  opacity: 0.4;
  pointer-events: none;
}
</style>
