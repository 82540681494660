<template>
  <div>
    <YTable v-if="campaigns" :data="campaigns.data || []" :loading="isLoading">
      <template slot="header-row">
        <th>Campaign</th>
        <th>Geos</th>
        <th>Payout</th>
        <th style="text-align:center;">Vertical</th>
        <th style="text-align:center;">Status</th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <div class="flex items-center">
            <div class="mr-5">
              <EditableImage
                :image-url="props.rowData.imageUrl"
                :image-thumbnail-url="props.rowData.imageThumbnailUrl"
                :editable="false"
                :width="32"
                :height="24"
                :blankWidth="20"
                :blankHeight="20"
                :model-id="props.rowData.id"
                :model-class="`Campaign`"
                class="rounded-md"
              />
            </div>
            <div class="flex">
              <Icon
                v-if="props.rowData.isFeatured"
                v-tooltip="'Featured'"
                class="mr-2  mt-1 text-orange-400 flex-shrink inline-flex leading-relaxed"
                name="star"
              ></Icon>
              <div>
                <div class="mb-1 w-10/12 xl:w-full">
                  <router-link
                    :to="{
                      name: 'Publisher Campaign',
                      params: { id: props.rowData.id }
                    }"
                    class="link "
                  >
                    <span
                      class="font-semibold text-gray-800 text-base inline leading-5 hover:text-gray-900 whitespace-normal "
                    >
                      {{ props.rowData.name }}
                    </span>
                  </router-link>
                  <small class="text-gray-600">{{ props.rowData.id }}</small>
                </div>
                <div class="x-tags mb-3">
                  <Tag
                    v-for="tag in props.rowData.campaignTags"
                    :key="tag.id"
                    :color="tag.color"
                    class="my-px mr-1"
                  >
                    {{ tag.name }}
                  </Tag>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <expandable-countries-from-payouts
            :payouts="props.rowData.payouts"
          ></expandable-countries-from-payouts>
        </td>

        <td>
          <template v-if="props.rowData?.payoutDescriptions?.length">
            <ExpandablePayouts :payouts="props.rowData.payoutDescriptions" />
          </template>
        </td>
        <td style="text-align:center;">
          <tag
            v-for="vertical in props.rowData.verticalTags"
            :key="vertical.name"
            class="mr-1"
            :color="vertical.color"
            >{{ vertical.name }}</tag
          >
        </td>
        <td style="text-align:center;">
          <router-link
            :to="{
              name: 'Publisher Campaign',
              params: { id: props.rowData.id }
            }"
          >
            <YButton
              class="mr-1 font-bold uppercase text-xs"
              :class="statusButton(props.rowData.status)"
              >{{ props.rowData.status }}</YButton
            >
          </router-link>
        </td>
      </template>
      <template v-slot:after>
        <div
          class="w-100 py-4"
          v-if="
            campaigns &&
              campaigns.paginatorInfo &&
              campaigns.paginatorInfo.hasMorePages
          "
        >
          <YButton
            class="block m-auto"
            color="link"
            @click="onClickButton"
            :is-loading="isLoading"
            >LOAD MORE</YButton
          >
        </div>
      </template>
    </YTable>
  </div>
</template>

<script>
import ExpandableCountriesFromPayouts from '@/components/general/ExpandableCountriesFromPayouts'
import EditableImage from '@/components/utilities/EditableImage'
import PublisherStatusButton from '@/mixins/PublisherStatusButtonsMixin'
import ExpandablePayouts from '@/views/Publisher/Campaigns/ExpandablePayouts.vue'

export default {
  components: {
    ExpandableCountriesFromPayouts,
    EditableImage,
    ExpandablePayouts
  },
  mixins: [PublisherStatusButton],
  props: {
    campaigns: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  mounted() {},
  updated() {},
  methods: {
    onClickButton() {
      this.$emit('loadMore')
    }
  }
}
</script>
