var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-b flex px-6 py-2 items-center flex-none",
    staticStyle: {
      "min-height": "4.1rem"
    }
  }, [_c('transition', {
    attrs: {
      "name": "width"
    }
  }, [_vm.hasBurger ? _c('div', {
    staticClass: "w-10 h-10 flex"
  }, [_c('button', {
    staticClass: "x-toggle-sidebar-button flex -ml-5 text-gray-400 hover:text-gray-600 focus:outline-none",
    on: {
      "click": function ($event) {
        return _vm.toggleSidebar();
      }
    }
  }, [_c('Icon', {
    staticClass: "mx-5 my-2",
    attrs: {
      "name": "menu",
      "size": 6
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "flex flex-col"
  }, [_c('portal-target', {
    attrs: {
      "name": "top-bar-left"
    }
  }, [_c('h3', {
    staticClass: "text-gray-800 font-extrabold flex"
  }, [_c('portal-target', {
    attrs: {
      "name": "top-bar-left-header-before"
    }
  }), _c('portal-target', {
    attrs: {
      "name": "top-bar-left-header"
    }
  }, [_vm._v(" " + _vm._s(_vm.route.meta.publicName || _vm.route.name) + " ")]), _c('portal-target', {
    attrs: {
      "name": "top-bar-left-header-after"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "ml-auto block flex items-center"
  }, [_c('Icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "w-10 h-10 text-green-500 spin-animation block",
    attrs: {
      "name": "loading"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    staticClass: "flex items-center"
  }, [_c('portal-target', {
    attrs: {
      "name": "top-bar-right"
    }
  }, [_c('ActiveUser', {
    attrs: {
      "impersonatingContentClass": "bg-red-600 rounded-md text-white hover:text-white hover:bg-red-700 hidden md:flex",
      "contentClass": "text-gray-700 rounded-md hover:text-blue-400 hover:bg-blue-100 hidden md:flex",
      "iconName": "chevronDown",
      "enableResponsiveAvatar": true
    }
  })], 1)], 1), _c('Tasks')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }