<template>
  <YAlert
    :allow-close="false"
    color="blue"
    v-if="me && me.isAPublisher && me.userDetails.agreedToTerms == false"
    class="mt-0 mb-0 px-6"
  >
    <div class="flex justify-between items-center">
      <div>
        <h6 class="mb-0 font-weight-semibold">
          You must accept
          <span class="font-bold">YTZ Terms & Conditions</span> before viewing
          campaigns. You will be redirected to the dashboard upon acceptance of
          terms.
        </h6>
      </div>
    </div>
  </YAlert>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'

export default {
  apollo: {
    me: {
      query: ME_QUERY
    }
  },
  methods: {}
}
</script>

<style></style>
