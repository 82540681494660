<template>
    <widget>
      <!-- <div class="background-gradient-green-blue widget-top-graphic" style="height: 6px;"></div> -->
      <widget-header>Revshare Pull Helper Info
        <template v-slot:right>
          <Superselect
            v-model="input.helperClass"
            :options="helpers"
            title="Helper"
            :multiple="false"
            :allow-empty="false"
            @close="onHelperChange"
            style="min-width: 8rem;"
          ></Superselect>
        </template>
      </widget-header>
      <widget-body>
        <form @submit.prevent="update" ref="form" class="x-form">
            <YInput v-model="input.url" label="Url"></YInput>
            <YInput  v-model="data.clickColumnName" label="Click Column Name"></YInput>
            <YInput  v-model="data.revenueColumnName" label="Revenue Column Name"></YInput>
            <YInput v-if="input.helperClass == 'CellxpertHelper' || input.helperClass =='OxTrafficHelper'" v-model="data.apiUserName" label="Api User Name"></YInput>
            <YInput v-if="input.helperClass == 'CellxpertHelper'" v-model="data.apiPassword" label="User Password"></YInput>
            <YInput v-if="input.helperClass == 'MapHelper' || input.helperClass =='OxTrafficHelper'" v-model="data.apiKey" label="Api Key"></YInput>
            <YInput v-if="input.helperClass == 'MapHelper'" v-model="data.userName" label="User Name"></YInput>
            <YInput v-if="input.helperClass == 'MyAffiliatesHelper'" v-model="data.clientIdentifier" label="Client Identifier"></YInput>
            <YInput v-if="input.helperClass == 'MyAffiliatesHelper'" v-model="data.clientSecret" label="Client Secret"></YInput>
            <Superselect
            v-model="input.status"
            :options="statuses"
            title="Status"
            :multiple="false"
            :allow-empty="false"
            style="min-width: 8rem;"
          ></Superselect>
        </form>
      </widget-body>
      
      <WidgetFooter class="bg-gray-100 py-2 px-4 text-right">
        <y-button
            class="btn btn-blue"
            :is-loading="isSubmitting"
            @click="saveHelper"
            @keydown.enter="saveHelper"
            >Update</y-button
        >
      </WidgetFooter>
    </widget>
  </template>
  
  <script>
  import gql from 'graphql-tag'
  export default {
    props: {
      accountId: {
        type: Number,
        required: true
      },
      id: {
        type: Number,
        required: false
      }
    },
    components: {},
    apollo: {
    advertiserAccountRevshareHelper: {
      query: gql`
        query  advertiserAccountRevshareHelper($id: ID) {
            advertiserAccountRevshareHelper(id: $id) {
                id
                advertiserAccountId 
                helperClass
                url
                data
                status    
          }
        }
      `,
    fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.id
        }
      },
      result({ data }) {
        if (data?.advertiserAccountRevshareHelper){
            this.input = data.advertiserAccountRevshareHelper
            if (this.input.data){
                this.data = JSON.parse(this.input.data)
            }
        }
      },
    }
  },
    data() {
      return {
        url: '',
        helper: '',
        exampleUrl: '',
        data: {},
        input: {},
        status: 'active',
        helpers: ['MapHelper','MyAffiliatesHelper','CellxpertHelper','OxTrafficHelper'],
        statuses:['active','dead'],
        isSubmitting: false,
      }
    },
    watch: {},
    computed: {},
    methods: {
      onHelperChange() {
        this.exampleUrl = ''
        this.data = {}
        this.input.url = ''
        if (this.input.helperClass == 'MapHelper'){
            this.exampleUrl = 'https://partners.revolutionaffiliates.com/generatemapreports.aspx?ver=3.0&username={userName}&apikey={apiKey}&reportstartdate={startDate}&reportenddate={endDate}&command=cidreport&format=CSV';
        }
      },
      saveHelper() {
        this.isSubmitting = true
        let input = {
            helperClass: this.input.helperClass,
            status: this.input.status,
            advertiserAccountId: this.accountId,
            url: this.input.url,
            data: JSON.stringify(this.data),
        }

        this.isSubmitting = false
        this.$apollo
            .mutate({
            mutation: gql`
                mutation updateCreateAccountRevshareHelper($input: UpdateRevshareHelperInput!) {
                    updateCreateAccountRevshareHelper(input: $input) {
                    id
                }
                }
            `,
            variables: {
                input: input
            }
            })
            .then(() => {
                //remove result from above function ()
                this.$toastr.s('', 'Updated')
                this.isSubmitting = false
                this.$events.emit('refreshAdvertiserAccounts', true)
                this.$emit('close')
            })
            .catch(() => {
                //removed error from function
                this.isSubmitting = false
            })
        },
      mounted() {},
      updated() {}
    }
  }
  </script>

  