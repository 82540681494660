<template>
  <div>
    <div class="flex flex-row gap-6 justify-between my-4">
      <div class="w-7/12">
        <Superselect
          select-class="border-0"
          v-model="showColumnsFilter"
          :options="showColumnsFilterOptions || []"
          title="Show Columns"
          placeholder="Select Columns"
          track-by="id"
          label="label"
          selectedLabel="Selected"
          selectLabel="Add"
          deselectLabel="Remove"
          :closeOnSelect="false"
          :multiple="true"
          :allow-empty="false"
        ></Superselect>
      </div>

      <div class="w-5/12">
        <domains-actions
          ref="domainActions"
          :selections="selectedDomainRecords"
        ></domains-actions>
      </div>
    </div>
    <div class="w-100">
      <YTable
        :data="data"
        :selectable="true"
        selectBy="id"
        :selected.sync="selected"
        ref="domainTable"
        @select="newDomainsSelected"
        @deselect="domainsDeselected"
      >
        <template slot="header-row" slot-scope="props">
          <th v-if="viewableColumns.includes('id')">
            <div class="flex">
              <y-sort-column
                class="ml-1"
                :sort-order="$store.getters['ydns/domainsTableSortOrder']"
                column="id"
                order="ASC"
                @sort="updateSortOrder"
                >Domain</y-sort-column
              >
              <a
                href="#"
                class="inline-block"
                @click.prevent="
                  copyToClipboard(props.data.map(row => row.id).join('\r\n'))
                "
              >
                <Icon
                  v-tooltip.top="`Copy Values`"
                  name="contentCopy"
                  :size="4"
                  class="ml-4 inline-block text-blue-600"
                />
              </a>
            </div>
          </th>
          <th v-if="viewableColumns.includes('registrar')">
            <y-sort-column
              class="ml-1"
              :sort-order="$store.getters['ydns/domainsTableSortOrder']"
              column="registrar"
              order="ASC"
              @sort="updateSortOrder"
              >Registrar</y-sort-column
            >
          </th>
          <th></th>
          <th v-if="viewableColumns.includes('type')">
            <y-sort-column
              class="ml-1"
              :sort-order="$store.getters['ydns/domainsTableSortOrder']"
              column="type"
              order="ASC"
              @sort="updateSortOrder"
              >Type</y-sort-column
            >
          </th>
          <th v-if="viewableColumns.includes('expiry')">
            <y-sort-column
              class="ml-1"
              :sort-order="$store.getters['ydns/domainsTableSortOrder']"
              column="expiry"
              order="DESC"
              @sort="updateSortOrder"
              >Expiry</y-sort-column
            >
          </th>
          <th v-if="viewableColumns.includes('status')">Status</th>
          <th v-if="viewableColumns.includes('publisherStatus')">Pub Status</th>
          <th v-if="viewableColumns.includes('publishers')">
            <y-sort-column
              class="ml-1"
              :sort-order="$store.getters['ydns/domainsTableSortOrder']"
              column="active_user_count"
              order="DESC"
              @sort="updateSortOrder"
              >Publishers</y-sort-column
            >
          </th>
          <th v-if="viewableColumns.includes('publisherHistory')">
            Historical Publishers
          </th>
          <th v-if="viewableColumns.includes('tags')">Tags</th>
          <th v-if="viewableColumns.includes('dns')">DNS</th>
          <th v-if="viewableColumns.includes('createdAt')">
            <y-sort-column
              class="ml-1"
              :sort-order="$store.getters['ydns/domainsTableSortOrder']"
              column="createdAt"
              @sort="updateSortOrder"
              >Created</y-sort-column
            >
          </th>
          <th v-if="viewableColumns.includes('updatedAt')">
            <y-sort-column
              class="ml-1"
              :sort-order="$store.getters['ydns/domainsTableSortOrder']"
              column="updatedAt"
              @sort="updateSortOrder"
              >Updated</y-sort-column
            >
          </th>
          <th v-if="viewableColumns.includes('records')">Records</th>
          <th style="text-align:right; padding: 0 !important;"></th>
        </template>
        <template slot="row" slot-scope="props">
          <td v-if="viewableColumns.includes('id')">
            {{ props.rowData.id }}
          </td>
          <td
            v-if="viewableColumns.includes('registrar')"
            style="min-width:250px;"
          >
            <div class="flex">
              <div class="flex flex-col">
                <div>
                  {{ props.rowData.registrar }}
                </div>
                <div
                  v-if="props.rowData.registrarAccount"
                  class="text-xs italic -mt-1"
                >
                  {{ props.rowData.registrarAccount }}
                </div>
              </div>
              <button
                class="text-blue-600 hover:text-blue-800 px-2"
                @click="hydrateWhoIsData([props.rowData.id])"
              >
                <Icon name="refresh" :size="4" />
              </button>
            </div>
          </td>
          <td style="padding: 0">
            <v-popover
              offset="10"
              placement="left-center"
              :autoHide="true"
              popoverClass
              :class="['sidebar-user-popover']"
              popoverBaseClass="popover-menu sidebar-popover-menu"
              popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
            >
              <!-- This will be the popover target (for the events and position) -->
              <YButton class="btn btn-transparent btn-rounded tooltip-target ">
                <Icon name="dotsVertical" :size="4" />
              </YButton>

              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div class="list-group">
                  <template v-if="props.rowData.deletedAt">
                    <button
                      type="button"
                      class="list-group-item list-group-item-action"
                      @click="$refs.domainActions.restore([props.rowData])"
                    >
                      <span class="flex items-center">
                        <Icon name="refresh" :size="4" />Restore
                      </span>
                    </button>
                  </template>
                  <template v-else>
                    <SpamhausCheckPopover
                      model="Domain"
                      :rowData="props.rowData"
                    />

                    <button
                      class="list-group-item list-group-item-action"
                      @click="
                        $refs.domainActions.openEditModal([props.rowData])
                      "
                    >
                      <span class="flex items-center">
                        <Icon
                          name="fileEditOutline"
                          :size="4"
                          class="mr-2"
                        />Edit Records
                      </span>
                    </button>

                    <button
                      type="button"
                      class="list-group-item list-group-item-action"
                      @click="
                        $refs.domainActions.openEditTagsModal(props.rowData)
                      "
                    >
                      <span class="flex items-center">
                        <Icon
                          name="tagMultipleOutline"
                          :size="4"
                          class="mr-2"
                        />Edit Tags
                      </span>
                    </button>

                    <button
                      type="button"
                      class="list-group-item list-group-item-action"
                      @click="
                        $refs.domainActions.openEditPublishersModal({
                          modelIds: [props.rowData.id],
                          modelClass: 'domain',
                          ability: 'use',
                          all: permissionsByDomainId[props.rowData.id]
                            ? permissionsByDomainId[props.rowData.id].all
                            : false,
                          only: permissionsByDomainId[props.rowData.id]
                            ? permissionsByDomainId[props.rowData.id].only
                            : undefined,
                          except: permissionsByDomainId[props.rowData.id]
                            ? permissionsByDomainId[props.rowData.id].except
                            : undefined
                        })
                      "
                    >
                      <span class="flex items-center">
                        <Icon
                          name="accountMultipleOutline"
                          :size="4"
                          class="mr-2"
                        />Edit Publishers
                      </span>
                    </button>

                    <button
                      type="button"
                      class="list-group-item list-group-item-action"
                      @click="
                        $refs.domainActions.openEditDomainStatusModal(
                          props.rowData
                        )
                      "
                    >
                      <span class="flex items-center">
                        <Icon name="circleOutline" :size="4" class="mr-2" />
                        Edit Status
                      </span>
                    </button>

                    <button
                      v-if="props.rowData.type !== 'nameserver'"
                      type="button"
                      class="list-group-item list-group-item-action"
                      @click="openEditNameserverModal(props.rowData)"
                    >
                      <span class="flex items-center">
                        <Icon name="pencil" :size="4" class="mr-2" />
                        Edit Nameserver
                      </span>
                    </button>
                    <ClickAndReplace :duration="5000">
                      <button
                        class="text-red-500 hover:bg-red-100 list-group-item list-group-item-action"
                      >
                        <span class="flex items-center">
                          <Icon
                            name="trashCanOutline"
                            :size="4"
                            class="mr-2"
                          />Delete
                        </span>
                      </button>
                      <template v-slot:replacement>
                        <button
                          class="bg-red-500 text-white hover:bg-red-600 list-group-item list-group-item-action"
                          @click="deleteDomains([props.rowData])"
                        >
                          Confirm Delete
                        </button>
                      </template>
                    </ClickAndReplace>
                  </template>
                </div>
              </template>
            </v-popover>
          </td>
          <td v-if="viewableColumns.includes('type')">
            {{ props.rowData.type && props.rowData.type.capitalize() }}
          </td>
          <td v-if="viewableColumns.includes('expiry')" class="truncate">
            <span class="flex items-center">
              <Icon
                v-if="
                  props.rowData.tags.filter(
                    tag => tag.tagConfig.tag == 'Let Expire'
                  ).length > 0
                "
                name="checkCircle"
                :size="4"
                class="text-indigo-600 mr-1"
                v-tooltip="`Let Expire`"
              />
              <span :class="getExpiryClass(props.rowData.expiry)">
                {{ transformExpiry(props.rowData.expiry) }}
              </span>
              <button
                class="text-blue-600 hover:text-blue-800 px-2"
                @click="hydrateWhoIsData([props.rowData.id])"
              >
                <Icon name="refresh" :size="4" />
              </button>
            </span>
          </td>
          <td v-if="viewableColumns.includes('status')">
            <template>
              <a
                class="cursor-pointer flex"
                @click="
                  $refs.domainActions.openEditDomainStatusModal(props.rowData)
                "
              >
                <span
                  v-if="props.rowData.status == 'active'"
                  class="text-green-500 hover:text-green-600"
                  >{{ props.rowData.status }}</span
                >
                <span
                  v-else-if="
                    props.rowData.status == 'ready' ||
                      props.rowData.status == null
                  "
                  class="text-blue-500 hover:text-blue-600"
                  >ready</span
                >
                <span
                  v-else-if="props.rowData.status == 'flagged'"
                  class="text-red-500"
                  >{{ props.rowData.status }}</span
                >
                <span v-else class="text-red-500 hover:text-red-600">
                  {{ props.rowData.status }}
                </span>
                <div
                  v-if="
                    props.rowData.spamhausCheck &&
                      props.rowData.spamhausCheck.lastRunOutput
                  "
                  v-tooltip="
                    `<div class='text-center'>Listed <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`
                  "
                  href="#"
                  class="ml-1 mt-1 w-3"
                >
                  <img
                    src="@/assets/images/logo/spamhaus-logo-red.png"
                    class="h-3"
                  />
                </div>
                <div
                  v-else-if="props.rowData.spamhausCheck"
                  v-tooltip="
                    `<div class='text-center'>Unlisted <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`
                  "
                  href="#"
                  class="ml-1 mt-1 w-3"
                >
                  <img
                    src="@/assets/images/logo/spamhaus-logo.png"
                    class="h-3"
                  />
                </div>
                <span
                  v-if="
                    props.rowData.spamhausCheck &&
                      props.rowData.spamhausCheck.enabled
                  "
                >
                  *
                </span>
              </a>
            </template>
          </td>
          <td v-if="viewableColumns.includes('publishers')">
            <template
              v-if="
                permissionsByDomainId[props.rowData.id] &&
                  props.rowData.status != 'flagged'
              "
            >
              <Tag
                v-if="permissionsByDomainId[props.rowData.id].all"
                color="blue"
                class="my-2"
                >All Publishers</Tag
              >
              <ul v-if="permissionsByDomainId[props.rowData.id].only">
                <li
                  v-for="publisher in permissionsByDomainId[props.rowData.id]
                    .only"
                  :key="publisher.id"
                >
                  <router-link
                    :to="{
                      name: 'User',
                      params: { id: publisher.id }
                    }"
                    class="link truncate block"
                    style="max-width: 13rem;"
                    v-tooltip="{
                      placement: 'left',
                      content: publisher.id
                    }"
                  >
                    {{ publisher.status == 'active' ? publisher.email : null }}
                  </router-link>
                </li>
              </ul>
              <ul v-if="permissionsByDomainId[props.rowData.id].except">
                <li
                  v-for="publisher in permissionsByDomainId[props.rowData.id]
                    .except"
                  :key="publisher.id"
                >
                  <router-link
                    :to="{
                      name: 'User',
                      params: { id: publisher.id }
                    }"
                    class="text-red-600 hover:text-red-800 truncate block"
                    style="max-width: 13rem;"
                    v-tooltip.left="publisher.id"
                    >{{ publisher.email }}</router-link
                  >
                </li>
              </ul>
            </template>
          </td>
          <td v-if="viewableColumns.includes('publisherHistory')">
            <ul v-if="props.rowData.userDomainHistory.length > 0">
              <li
                v-for="history in props.rowData.userDomainHistory"
                :key="history.id"
              >
                <router-link
                  v-if="history.user"
                  :to="{
                    name: 'User',
                    params: { id: history.user.id }
                  }"
                  class="truncate block"
                  style="max-width: 13rem;"
                  v-tooltip="{
                    placement: 'left',
                    content:
                      history.user.status == 'fraud'
                        ? 'FRAUD ' + history.user.id
                        : history.user.id
                  }"
                  :class="[
                    history.user.status == 'fraud' ? 'text-red-600' : 'link'
                  ]"
                  >{{ history.user.email }}</router-link
                >
              </li>
            </ul>
          </td>
          <td v-if="viewableColumns.includes('tags')">
            <template
              v-if="props.rowData.tags && props.rowData.tags.length > 0"
            >
              <a
                href="#"
                @click.prevent="
                  $refs.domainActions.openEditTagsModal(props.rowData)
                "
              >
                <Tag
                  v-for="tag in props.rowData.tags"
                  :key="tag.tagConfig.tag"
                  class="my-1 mr-1"
                  :color="tag.tagConfig.color"
                  >{{ tag.tagConfig.tag }}</Tag
                >
              </a>
            </template>
            <template v-else>
              <a
                href="#"
                class="text-blue-600 hover:text-blue-800"
                @click.prevent="
                  $refs.domainActions.openEditTagsModal(props.rowData)
                "
                >Add Tags</a
              >
            </template>
          </td>
          <td>
            <YTable
              v-if="props.rowData.nameServerDomain"
              :data="props.rowData.nameServerDomain.nameServers"
              class="w-100 w-min-100 table-hover size-xs"
              select-by="id"
              header-class="text-xs"
            >
              <template slot="header-row">
                <th>Prefix</th>
                <th>Content</th>
              </template>
              <template slot="row" slot-scope="dnsProps">
                <td>
                  {{ dnsProps.rowData.server.hostname }}
                </td>
                <td>
                  {{ dnsProps.rowData.server.serverIp }}
                </td>
              </template>
            </YTable>
          </td>
          <td v-if="viewableColumns.includes('createdAt')">
            {{ props.rowData.createdAt }}
          </td>
          <td v-if="viewableColumns.includes('updateYTadAt')">
            {{ props.rowData.updatedAt }}
          </td>
          <td style="padding: 0px;" v-if="viewableColumns.includes('records')">
            <YTable
              v-if="props.rowData.records && props.rowData.records.length > 0"
              class="w-100 w-min-100 table-hover size-xs"
              :scroll-x="false"
              header-class="text-xs"
              :data="props.rowData.records"
              :selectable="true"
              :select-all-disabled="true"
              select-by="id"
              :selected.sync="selectedRecordIds"
              @select="newRecordsSelected"
              @deselect="recordsDeselected"
            >
              <template slot="header-row">
                <th v-if="viewableColumns.includes('records:type')">Type</th>
                <th>Subdomain</th>
                <th v-if="viewableColumns.includes('records:provider')">
                  Provider
                </th>
                <th>Content</th>
                <th v-if="viewableColumns.includes('records:ttl')">TTL</th>
                <th v-if="viewableColumns.includes('records:priority')">
                  Priority
                </th>
              </template>
              <template slot="row" slot-scope="recordProps">
                <td
                  v-if="viewableColumns.includes('records:type')"
                  style="width:20px;"
                >
                  {{ recordProps.rowData.type }}
                </td>
                <td style="width: 75px;">
                  {{ recordProps.rowData.subdomain }}
                </td>
                <td
                  v-if="viewableColumns.includes('records:provider')"
                  style="width: 150px;"
                >
                  <template
                    v-if="
                      recordProps.rowData.ip &&
                        recordProps.rowData.ip.server &&
                        recordProps.rowData.ip.server.provider
                    "
                  >
                    {{ recordProps.rowData.ip.server.provider.name }} ({{
                      recordProps.rowData.ip.server.id
                    }})
                  </template>
                </td>
                <td style="width: 250px;">{{ recordProps.rowData.content }}</td>
                <td
                  v-if="viewableColumns.includes('records:ttl')"
                  style="width:20px;"
                >
                  {{ recordProps.rowData.ttl }}
                </td>
                <td
                  v-if="viewableColumns.includes('records:priority')"
                  style="width:20px;"
                >
                  {{ recordProps.rowData.priority }}
                </td>
              </template>
            </YTable>
            <div v-else>
              <button
                class="text-blue-600 hover:text-blue-800"
                @click="$refs.domainActions.openEditModal([props.rowData])"
              >
                Add Records
              </button>
            </div>
          </td>
        </template>
      </YTable>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import DomainsEdit from './DomainsEdit'
import DomainsActions from './DomainsActions'
import ySortColumn from '../ySortColumn'
import ClickAndReplace from '@/components/ui/ClickAndReplace'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DomainNameserverEdit from '@/views/Admin/DomainManager/DomainNameserverEdit.vue'
export default {
  mixins: [ValidationErrorsMixin],
  components: {
    DomainsActions,
    ySortColumn,
    ClickAndReplace
  },
  props: {
    data: {
      default: () => {
        ;[]
      },
      type: Array
    },
    publisherPermissions: {
      type: Array
    }
  },
  apollo: {},
  data() {
    return {
      sortBy: {
        label: 'Latest',
        column: 'created_at',
        order: 'DESC'
      },
      sortByOptions: [
        {
          label: 'Latest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ],
      showColumnsFilter: this.$store.getters['ydns/domainsShowColumnsFilter'],
      showColumnsFilterOptions: this.$store.getters[
        'ydns/domainsShowColumnsFilterOptions'
      ],
      selected: [],
      selectedRecordIds: [],
      filters: {},
      css: {
        tableClass: 'table table-sm dt-responsive nowrap disable-hover',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      },
      initialDomains: JSON.parse(JSON.stringify(this.data)),
      defaultVisibleColumns: [
        'id',
        'registrar',
        'type',
        'records',
        'records:provider'
      ]
    }
  },
  watch: {
    localSortOrder: {
      handler: function(newValue) {
        this.$emit('sort', [...newValue])
      },
      deep: true
    },
    showColumnsFilter: {
      handler: function(value) {
        this.$store.dispatch('ydns/updateDomainsShowColumnsFilter', value)
      },
      deep: true
    }
  },
  computed: {
    permissionsByDomainId() {
      if (!this.publisherPermissions) {
        return {}
      }
      let permissionsByDomainId = {}
      this.publisherPermissions.forEach(permission => {
        if (
          permission.modelId &&
          this.domainsByPermissionId[permission.modelId]
        ) {
          permissionsByDomainId[
            this.domainsByPermissionId[permission.modelId].id
          ] = permission
        }
      })
      return permissionsByDomainId
    },
    visibleColumns() {
      return this.showColumnsFilter.map(function(column) {
        return column.id
      })
    },
    domains() {
      return this.data
    },
    domainsByPermissionId() {
      let domainsByPermissionId = {}
      this.data
        .filter(domain => domain.permission)
        .forEach(domain => {
          if (domain.permission) {
            domainsByPermissionId[domain.permission.id] = domain
          }
        })
      return domainsByPermissionId
    },
    domainsByKey() {
      return this.domains.reduce(function(map, domain) {
        map[domain.id] = domain
        return map
      }, {})
    },
    recordsByKey() {
      return this.domains.reduce(function(map, domain) {
        domain.records.forEach(function(record) {
          map[record.id] = record
        })
        return map
      }, {})
    },
    recordsDomainMap() {
      return this.domains.reduce(function(map, domain) {
        domain.records.forEach(function(record) {
          map[record.id] = domain.id
        })
        return map
      }, {})
    },
    selectedDomainRecords() {
      return this.selected.map(domainKey => {
        let domain = JSON.parse(JSON.stringify(this.domainsByKey[domainKey]))
        domain.records = domain.records.filter(record =>
          this.selectedRecordIds.includes(record.id)
        )
        return domain
      })
    },
    viewableColumns() {
      if (this.visibleColumns.length > 0) {
        return this.visibleColumns
      } else {
        return this.defaultVisibleColumns
      }
    },
    fields() {
      return [
        {
          name: '__checkbox'
        },
        {
          name: 'domain',
          title: 'Domain',
          visible: this.viewableColumns.includes('id')
        },
        {
          name: 'registrar',
          title: 'Registrar',
          visible: this.viewableColumns.includes('registrar')
        },
        {
          name: 'type',
          title: 'Type',
          formatter: value => {
            return this._.startCase(value)
          },
          visible: this.viewableColumns.includes('type')
        },
        {
          name: 'records-slot',
          title: 'Records',
          visible: this.viewableColumns.includes('records')
        },
        {
          name: 'actions-slot',
          title: '',
          width: '1rem'
        }
      ]
    }
  },
  mounted() {
    this.$events.listen('domainsDeleted', () => {
      this.selected = []
    })
  },
  beforeDestroy() {
    this.$events.remove('domainsDeleted')
  },
  updated() {},
  methods: {
    openEditNameserverModal(domain) {
      this.$modal.show(
        DomainNameserverEdit,
        { domain: domain },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    edit(domains) {
      this.$modal.show(
        DomainsEdit,
        { domains: domains },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    transformExpiry(expiry) {
      return this.$luxon(expiry)
    },
    getExpiryClass(expiry) {
      expiry = this.$luxon(expiry)
      const expiryDateDaysfromToday = this.$dateTime
        .fromISO(expiry)
        .diffNow('days')
        .toObject().days
      if (expiryDateDaysfromToday < 30) {
        return 'text-red-600'
      } else if (expiryDateDaysfromToday < 60) {
        return 'text-yellow-600'
      }
    },
    hydrateWhoIsData(domainIds) {
      this.$refs.domainActions.hydrateWhoIsData(domainIds)
    },
    deleteDomains(domains) {
      this.$refs.domainActions.delete(domains)
    },
    selectRecord(recordId) {
      this.selectedRecordIds = [recordId, ...this.selectedRecordIds]
    },
    deselectRecord(recordId) {
      this.selectedRecordIds = this.selectedRecordIds.filter(
        record => record !== recordId
      )
    },
    newDomainsSelected(domains) {
      let recordSelections = []
      domains.forEach(domain => {
        recordSelections = [
          ...this.domainsByKey[domain].records.map(record => {
            return record.id
          }),
          ...recordSelections
        ]
      })
      this.selectedRecordIds = [...recordSelections, ...this.selectedRecordIds]
    },
    domainsDeselected(domains) {
      let recordSelections = []
      domains.forEach(domain => {
        recordSelections = [
          ...this.domainsByKey[domain].records.map(record => {
            return record.id
          }),
          ...recordSelections
        ]
      })
      this.selectedRecordIds = this.selectedRecordIds.filter(selectedRecord => {
        return !recordSelections.includes(selectedRecord)
      })
    },
    newRecordsSelected(records) {
      let domainSelections = []
      records.forEach(record => {
        domainSelections = [this.recordsDomainMap[record], ...domainSelections]
      })
      domainSelections = [...new Set([...domainSelections, ...this.selected])]
      this.selected = domainSelections
    },
    recordsDeselected() {},
    updateSortOrder(value) {
      this.$store.dispatch('ydns/updateDomainsTableSortOrder', value)
    },
    copyToClipboard: function(content, onSuccess) {
      this.$copyText(content).then(
        function() {
          if (onSuccess != undefined) {
            onSuccess()
          }
        },
        function() {}
      )
    }
  }
}
</script>
